import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './NavBar.css';
import logger from './logger';

const NavBar = ({ isLoggedIn, showLoginModal }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState('');

  useEffect(() => {
    const path = location.pathname;
    if (path.includes('/feed')) setActiveButton('feed');
    else if (path.includes('/dashboard')) setActiveButton('dashboard');
    else if (path.includes('/notifications')) setActiveButton('notifications');
    else if (path.includes('/profile')) setActiveButton('profile');
    else if (path.includes('/create-poll')) setActiveButton('create');
    else setActiveButton('');
  }, [location.pathname]);

  const handleNavigation = (buttonName, path) => {
    // Block navigation for logged-out users except for the 'feed' and 'create' buttons
    if (!isLoggedIn && buttonName !== 'feed' && buttonName !== 'create') {
      showLoginModal(); // Show login modal if not logged in and trying to access restricted pages
    } else {
      setActiveButton(buttonName);
      navigate(path);
    }
  };

  const handleCreateClick = () => {
    // Allow access to the create poll page even for non-logged-in users
    navigate('/create-poll');
  };

  return (
    <div className="nav-bar">
      <button
        className={`nav-icon ${activeButton === 'feed' ? 'active' : ''}`}
        onClick={() => handleNavigation('feed', '/feed')}
      >
        <img
          src={`/${activeButton === 'feed' ? 'feed-active.png' : 'feed.png'}`}
          alt="Feed"
        />
      </button>
      <button
        className={`nav-icon ${activeButton === 'dashboard' ? 'active' : ''}`}
        onClick={() => handleNavigation('dashboard', '/dashboard')}
      >
        <img
          src={`/${activeButton === 'dashboard' ? 'dashboard-active.png' : 'dashboard.png'}`}
          alt="Dashboard"
        />
      </button>
      <button
        className={`nav-icon ${activeButton === 'create' ? 'active' : ''} nav-icon-create`}
        onClick={handleCreateClick} // Use the create button handler directly
      >
        <img
          src={`/${activeButton === 'create' ? 'create-active.png' : 'create.png'}`}
          alt="Create"
        />
      </button>
      <button
        className={`nav-icon ${activeButton === 'notifications' ? 'active' : ''}`}
        onClick={() => handleNavigation('notifications', '/notifications')}
      >
        <img
          src={`/${activeButton === 'notifications' ? 'notifications-active.png' : 'notifications.png'}`}
          alt="Notifications"
        />
      </button>
      <button
        className={`nav-icon ${activeButton === 'profile' ? 'active' : ''}`}
        onClick={() => handleNavigation('profile', '/profile')}
      >
        <img
          src={`/${activeButton === 'profile' ? 'profile-active.png' : 'profile.png'}`}
          alt="Profile"
        />
      </button>
    </div>
  );
};

export default NavBar;
