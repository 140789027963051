import React, { useState, useEffect } from 'react';
import { Route, Routes, Link, useNavigate, useLocation } from 'react-router-dom';
import Home from './components/Home';
import Feed from './components/Feed';
import StandalonePoll from './components/StandalonePoll';
import EmbedPoll from './components/EmbedPoll';
import SignUpPage from './components/SignUpPage'; // Updated import
import LoginPage from './components/LoginPage'; // Updated import
import Profile from './components/Profile';
import PublicProfile from './components/PublicProfile';
import { jwtDecode } from 'jwt-decode'; // Fixed import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import './App.css';
import VerifyEmail from './components/VerifyEmail';
import Poll from './components/Poll';
import ResetPassword from './components/ResetPassword';
import InitiateResetPassword from './components/InitiateResetPassword';
import LiveDashboard from './components/LiveDashboard'; // Import LiveDashboard
import ConnectedPoll from './components/ConnectedPoll'; // Import ConnectedPoll component
import NavBar from './components/NavBar'; // Import NavBar component
import CreatePollModal from './components/CreatePollModal'; // Import CreatePollModal
import NotificationsPage from './components/NotificationsPage'; // Import NotificationsPage component
import Pricing from './components/Pricing';
import PaymentSuccess from './components/PaymentSuccess';
import CreatePoll from './components/CreatePoll';
import logger from './components/logger';
import Contact from './components/Contact';
import About from './components/About';
import Privacy from './components/Privacy';
import Terms from './components/Terms';
import FAQs from './components/FAQs';
import ProfileSettings from './components/ProfileSettings'; // Import ProfileSettings











function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userId, setUserId] = useState(null);
  const [username, setUsername] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [userEmail, setUserEmail] = useState('');
    // Determine if the current route is for login or signup
  const isHomePage = location.pathname === '/'; // Add this inside the App component
  const [subscriptionStatus, setSubscriptionStatus] = useState(''); // State for subscription status
  const isAuthRoute = location.pathname === '/signup' || location.pathname === '/login';
  const isEmbedRoute = location.pathname.startsWith('/embed/');
  const hideNavBarRoutes = ['/', '/about', '/faqs', '/contact'];

  const shouldHideNavBar = isEmbedRoute || isAuthRoute || hideNavBarRoutes.includes(location.pathname);
  





  // Function to fetch the token
  const fetchToken = async () => {
    try {
      const response = await fetch('/api/generate-token');
      const { accessToken } = await response.json();
      localStorage.setItem('accessToken', accessToken); // Store the access token in localStorage

      // Decode the token to get the expiration time
      const decodedToken = jwtDecode(accessToken);
      logger.log('Access token fetched and stored:', accessToken);
      logger.log('Token expires at:', new Date(decodedToken.exp * 1000).toISOString());
    } catch (error) {
      logger.error('Error fetching access token:', error);
    }
  };


  useEffect(() => {
    // If the user is not logged in, fetch a token when the app loads
    if (!isLoggedIn) {
      fetchToken();
    }
  }, [isLoggedIn]);


  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      if (!userId) return; // Only fetch if userId is available
  
      try {
        const response = await fetch(`/auth/subscription-status/${userId}`);
        if (response.ok) {
          const data = await response.json();
          setSubscriptionStatus(data.subscriptionStatus); // Update subscription status
          setUsername(data.username);
        } else {
          logger.error('Failed to fetch subscription status');
        }
      } catch (error) {
        logger.error('Error fetching subscription status:', error);
      }
    };
  
    fetchSubscriptionStatus();
  }, [userId]);
  
    // Scroll to top whenever the route changes
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);
  


  const getSubscriptionColor = () => {
    switch (subscriptionStatus.toLowerCase()) {
      case 'free':
        return '#02db2a'; // Custom hex for green
      case 'pro':
        return '#0967ff'; // Custom hex for blue
      case 'elite':
        return 'orange'; // Custom hex for orange
      case 'unlimited':
        return '#de00de'; // Custom hex for purple
      default:
        return '#808080'; // Custom hex for gray
    }
  };




  useEffect(() => {
    const trackPageView = (url) => {
      if (window.gtag) {
        // Track the general page view
        window.gtag('config', 'G-6CYJ9YMLWP', {
          page_path: url,
        });
  
        // Custom events for specific pages
        if (url === '/') {
          window.gtag('event', 'homepage_view', {
            event_category: 'Page Views',
            event_label: 'Home Page',
          });
        } else if (url === '/feed') {
          window.gtag('event', 'feedpage_view', {
            event_category: 'Page Views',
            event_label: 'Feed Page',
          });
        } else if (url.startsWith('/poll/')) {
          window.gtag('event', 'standalone_poll_view', {
            event_category: 'Page Views',
            event_label: 'Standalone Poll',
          });
        }
      }
    };
  
    // Track the initial page load
    trackPageView(window.location.pathname + window.location.search);
  
    // Track page changes
    const unlisten = () => {
      trackPageView(window.location.pathname + window.location.search);
    };
    return () => {
      unlisten();
    };
  }, [location]);
  


  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decoded = jwtDecode(token);
        if (decoded.exp * 1000 > Date.now()) {
          setIsLoggedIn(true);
          setUserId(decoded.userId);
          fetch(`/auth/user/${decoded.userId}`)
            .then(response => response.json())
            .then(data => {
              logger.log('User data fetched from backend:', data); // Log the fetched data
              setUsername(data.username);
              setUserEmail(data.email);
            })
            .catch(error => logger.error('Error fetching username:', error))
            .finally(() => setIsLoadingUser(false));
        } else {
          localStorage.removeItem('token');
          setIsLoadingUser(false);
        }
      } catch (error) {
        localStorage.removeItem('token');
        setIsLoadingUser(false);
      }
    } else {
      setIsLoadingUser(false);
    }
  }, []);

  const handleSignUp = (token, userId, username) => {
    localStorage.setItem('token', token);
    setIsLoggedIn(true);
    setUserId(userId);
    setUsername(username);
    navigate('/feed');
  };

  const handleLogin = (token, userId, username) => {
    localStorage.setItem('token', token);
    setIsLoggedIn(true);
    setUserId(userId);
    setUsername(username);
    navigate('/feed');
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    setUserId(null);
    setUsername('');
    setIsMenuOpen(false);
    navigate('/');
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  

  useEffect(() => {
    if (isEmbedRoute) {
      document.body.classList.add('embed-poll-body');
    } else {
      document.body.classList.remove('embed-poll-body');
    }
  }, [isEmbedRoute]);

  return (
    <div className="App">
      {isLoadingUser ? (
        <div>Loading...</div> // Or a spinner, etc.
      ) : (
        <>
          {/* Show header only if not on embed, login, or signup page */}
          {!isEmbedRoute && !isAuthRoute && (
            <header className="App-header">
            <div className="nav-wrapper">
              {/* Logo */}
              <Link to={isLoggedIn ? "/" : "/"}>
                <img src="/logo.png" className="App-logo" alt="logo" />
              </Link>
          
              {/* Main Nav (Always visible on Desktop, hidden on Mobile) */}
              <nav className="opinari-footer-nav">
                <Link to="/">Home</Link>
                <Link to="/about">About</Link>
                <Link to="/feed">Feed</Link>
                <Link to="/contact">Contact</Link>
                <Link to="/pricing">Pricing</Link>
                <Link to="/faqs">FAQ</Link>
              </nav>
              
              {/* Auth Buttons */}
              <nav className="auth-buttons">
                {isLoggedIn ? (
                  <>
                    {subscriptionStatus && (
                      <button
                        className="subscription-button"
                        style={{ backgroundColor: getSubscriptionColor() }}
                        onClick={() =>
                          navigate("/pricing", {
                            state: { selectedPlan: subscriptionStatus === "Free" ? "Basic" : subscriptionStatus },
                          })
                        }
                      >
                        {subscriptionStatus === "Free" ? "Basic" : subscriptionStatus}
                      </button>
                    )}
                    <span className="username-link">
                      <Link to="/profile" className="App-link-no-style">{username}</Link>
                      <span className="online-indicator"></span>
                    </span>
                  </>
                ) : (
                  <>
                    <Link to="/signup" className="app-opinari-btn app-opinari-btn-primary">
                      <span>Sign Up</span>
                    </Link>
                    <Link to="/login" className="app-opinari-btn app-opinari-btn-secondary">
                      <span>Log In</span>
                    </Link>
                  </>
                )}
              </nav>
          
              {/* Burger Menu (Only visible on Mobile) */}
              <button className="burger-menu" onClick={toggleMenu}>
                <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} />
              </button>
          
              {/* Mobile Menu (Only visible when burger is clicked on Mobile) */}
              <nav className={`mobile-nav ${isMenuOpen ? 'nav-open' : ''}`}>
                <Link to="/">Home</Link>
                <Link to="/about">About</Link>
                <Link to="/feed">Feed</Link>
                <Link to="/contact">Contact</Link>
                <Link to="/pricing">Pricing</Link>
                <Link to="/faqs">FAQ</Link>
              </nav>
            </div>
          </header>
          
          
          
          
          )}
  
  <main className={`App-main ${!shouldHideNavBar ? 'with-navbar' : 'without-navbar'}`}>
  <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/feed" element={<Feed isLoggedIn={isLoggedIn} userId={userId} username={username} isProfileView={false} />} />
              <Route path="/poll/:hash" element={<StandalonePoll isLoggedIn={isLoggedIn} userId={userId} username={username} />} />
              <Route path="/embed/:hash" element={<EmbedPoll isLoggedIn={isLoggedIn} userId={userId} username={username} />} />
              <Route path="/profile" element={<Profile userId={userId} username={username} userEmail={userEmail} isProfileView={true} />} />
              <Route path="/profile/:username" element={<PublicProfile />} />
              <Route path="/verify-email" element={<VerifyEmail />} />
              <Route path="/initiate-reset-password" element={<InitiateResetPassword />} />
              <Route path="/reset-password/:token" element={<ResetPassword />} />
              <Route path="/dashboard" element={<LiveDashboard userId={userId} username={username} userEmail={userEmail} />} />
              <Route path="/dashboard/:hash" element={<LiveDashboard userId={userId} username={username} userEmail={userEmail} />} />
              <Route path="/connectedpoll/:hash" element={<ConnectedPoll isLoggedIn={isLoggedIn} userId={userId} username={username} />} />
              <Route path="/notifications" element={<NotificationsPage isLoggedIn={isLoggedIn} userId={userId} username={username}/>} />
              <Route path="/pricing" element={<Pricing userId={userId} />} />
              <Route path="/payment-success" element={<PaymentSuccess />} />
              <Route path="/create-poll" element={<CreatePoll isLoggedIn={isLoggedIn} userId={userId} username={username} onCreate={(newPoll) => { logger.log('New Poll Created:', newPoll);}} />} />              <Route path="/signup" element={<SignUpPage onSignUp={handleSignUp} />} /> {/* New Sign Up Page route */}
              <Route path="/login" element={<LoginPage onLogin={handleLogin} />} /> {/* New Login Page route */}
              <Route path="/signup" element={<SignUpPage />} /> {/* Ensure this route exists */}
              <Route path="/contact" element={<Contact />} /> {/* Ensure this route exists */}
              <Route path="/about" element={<About />} /> {/* Ensure this route exists */}
              <Route path="/privacy" element={<Privacy />} /> {/* Ensure this route exists */}
              <Route path="/terms" element={<Terms />} /> {/* Ensure this route exists */}
              <Route path="/faqs" element={<FAQs />} /> {/* Ensure this route exists */}
              <Route path="/profile/settings" element={<ProfileSettings handleLogout={handleLogout} />} />







            </Routes>
          </main>
  
         
        {/* Show NavBar only if it's not an embed route, login, signup, or specific pages (Home, About, FAQs, Contact) */}
        {!shouldHideNavBar && (
          <NavBar 
            onCreate={() => navigate('/create-poll')} 
            isLoggedIn={isLoggedIn} 
            showLoginModal={() => navigate('/login')}
          />
          )}
        </>
      )}
    </div>
  );
  
  
}

window.Poll = Poll;

export default App;
