// PollFake.js

import React from 'react';
import { Link } from 'react-router-dom';
import { hsvaToHslaString } from '@uiw/color-convert';
import ColorBar from './ColorBar';
import '../App.css';
import logger from './logger';

const DEFAULT_LOGO_URL = '/logo-poll.png';

const PollFake = ({
  question,
  labels,
  votes = [],
  hash,
  logo,
  logoFill,
  roundedCorners,
}) => {
  // Define confidenceLabels here
  const confidenceLabels = [
    ['Very Low', 'Low', 'Medium', 'High', 'Very High'], // For labels[0]
    ['Very Low', 'Low', 'Medium', 'High', 'Very High'], // For labels[1]
    ['Very Low', 'Low', 'Medium', 'High', 'Very High'], // For labels[2]
  ];

  const classifyColor = (hue) => {
    if (hue >= 60 && hue <= 180) {
      return labels[0];
    } else if (hue > 300 || hue <= 60) {
      return labels[2];
    } else if (hue > 180 && hue <= 300) {
      return labels[1];
    } else {
      return 'Mixed Feelings';
    }
  };

  const mapColorToOpinion = (hsva) => {
    const { h, s } = hsva;
    let baseOpinion = 'No Opinion';

    if (s === 0) {
      return baseOpinion;
    }

    // Determine base opinion by hue range
    if (h >= 60 && h <= 180) {
      baseOpinion = labels[0];
    } else if (h > 300 || h <= 60) {
      baseOpinion = labels[2];
    } else if (h > 180 && h <= 300) {
      baseOpinion = labels[1];
    }

    const opinionIndex = labels.indexOf(baseOpinion);
    const opinionConfidenceLabels = confidenceLabels[opinionIndex];
    const numConfidenceLevels = opinionConfidenceLabels.length;

    const stepSize = 100 / numConfidenceLevels;
    const confidenceIndex = Math.min(
      Math.floor(s / stepSize),
      numConfidenceLevels - 1
    );
    const strength = opinionConfidenceLabels[confidenceIndex];

    return `${strength} ${baseOpinion}`;
  };

  const calculateColorPercentages = (votesArray) => {
    if (!Array.isArray(votesArray)) {
      return [];
    }

    const colorCounts = votesArray.reduce((acc, vote) => {
      const color = hsvaToHslaString({
        h: vote.choice,
        s: vote.saturation, // Use vote.saturation directly
        v: 100,
        a: 1,
      });
      const classification = classifyColor(vote.choice);
      const opinion = mapColorToOpinion({
        h: vote.choice,
        s: vote.saturation,
        v: 100,
        a: 1,
      });

      if (!acc[classification]) {
        acc[classification] = [];
      }

      acc[classification].push({
        color,
        confidence: vote.confidence,
        timestamp: vote.createdAt,
        opinion,
      });
      return acc;
    }, {});

    const totalVotes = votesArray.length;
    const sortedColors = [labels[2], labels[1], labels[0]]
      .map((classification) => {
        if (!colorCounts[classification]) return [];
        return colorCounts[classification]
          .sort((a, b) => a.confidence - b.confidence)
          .map((entry) => ({
            ...entry,
            percentage: (1 / totalVotes) * 100,
          }));
      })
      .flat();

    return sortedColors;
  };

  const colorPercentages = calculateColorPercentages(votes);

  const counts = {
    agree: votes.filter((vote) => classifyColor(vote.choice) === labels[0]).length,
    disagree: votes.filter((vote) => classifyColor(vote.choice) === labels[2]).length,
    neutral: votes.filter((vote) => classifyColor(vote.choice) === labels[1]).length,
  };

  return (
    <Link to={`/poll/${hash}`} className="PollFake-link">
      <div className="PollFake">
        <div className="PollFake-logo">
          <div className={`fake-logo-container ${logoFill ? 'filled' : ''}`}>
            <img
              src={logo || DEFAULT_LOGO_URL}
              alt="Logo"
              className="logo"
              style={{
                width: '150px',
                height: 'auto',
                maxWidth: '100%',
                borderRadius: roundedCorners ? '5px' : '0px',
              }}
            />
          </div>
        </div>
        <div className="PollFake-header">
          <h3 className="truncate-text">{question}</h3>
        </div>
        <div className="PollFake-body">
          <img src="/color-wheel.png" alt="Color Wheel" className="PollFake-colorWheel" />
          <ColorBar
            className="ColorBar-FakePoll"
            colorPercentages={colorPercentages}
            counts={counts}
            labels={labels}
          />
        </div>
      </div>
    </Link>
  );
};

export default PollFake;
