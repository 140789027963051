import React from 'react';
import './FAQs.css';
import DesktopBG from '../assets/desktop-bg-login.jpg';
import MobileBG from '../assets/mobile-bg-login.jpg';
import { useNavigate, Link } from 'react-router-dom';


// Import PDFs from assets
import Pdf1 from '../assets/Howtocreateapoll_PDF.pdf';
import Pdf2 from '../assets/Howtodeletepolls_PDF.pdf';
import Pdf3 from '../assets/Howtosharepolls_PDF.pdf';
import Pdf4 from '../assets/Howtodownloadpolldata_PDF.pdf';
import Pdf5 from '../assets/Howtogenerateadatareportonline_PDF.pdf';
import Pdf6 from '../assets/Howtoresetyourpassword_PDF.pdf';
import Pdf7 from '../assets/HowdoISubscribetootherOPINARIusers_PDF.pdf';
import Pdf8 from '../assets/HowdoIreportpolls_PDF.pdf';
import Pdf9 from '../assets/HowdoIreportpolls_PDF.pdf';
import Pdf10 from '../assets/Howtocreateconnectedpolls_PDF.pdf';

const FAQPage = () => {
  const isDesktop = window.innerWidth >= 768;

  const backgroundStyle = {
    backgroundImage: `url(${isDesktop ? DesktopBG : MobileBG})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1,
  };

  const openPdf = (pdfSrc) => {
    window.open(pdfSrc, '_blank');
  };

  const faqItems = [
    { pdf: Pdf1, question: "How do I make a poll?", description: "Navigate to the profile page by selecting your username in the top right hand corner. Once in the profile section simply click the plus button just below profile picture and bio." },
    { pdf: Pdf2, question: "How to delete polls?", description: "Select the poll you want to delete and then scroll to the bottom of the poll and hit the \"Delete\" poll button." },
    { pdf: Pdf3, question: "How to share polls?", description: "Click the share icon in the top right hand corner of the poll you want to share. The arrow icon." },
    { pdf: Pdf4, question: "How to download poll data?", description: "Click the \"Download Data\" button within the tools dropdown located in the top left hand corner of the poll. The gear icon." },
    { pdf: Pdf5, question: "How do I generate a report?", description: "Click the \"Data Report\" button within the tools dropdown located in the top left hand corner of the poll. The gear icon." },
    { pdf: Pdf6, question: "How do I reset my password?", description: "Click the \"Login Button\" in the top right hand corner of the home page. Click the \"Forgot Password\" button below login info. Enter in account email and hit \"Send Reset Link\". Follow the instructions within the password reset email." },
    { pdf: Pdf7, question: "How do I follow other OPINARI users?", description: "Navigate to the profile of the person you wish to follow and select the bell icon in the top left hand corner of their profile page. Once you hit the bell icon you will receive email notifications whenever that individual has created a new poll." },
    { pdf: Pdf8, question: "How do I report polls?", description: "Click the three dots in the top left hand corner of the poll you wish to report. Follow the instructions within the report poll window. Once submitted our team will review the poll and take further action." },
    { pdf: Pdf9, question: "How do I unsubscribe to emails?", description: "Click the three dots in the top left hand corner of the poll you wish to report. Follow the instructions within the report poll window. Once submitted our team will review the poll and take further action." },
    { pdf: Pdf10, question: "How do I create a connected poll?", description: "Navigate to your profile page. Click \"connected polls\" on the far right side below your bio. Hit \"Create new collection\" and follow the prompted instructions." },
  ];

  return (
    <div className="faq-page-wrapper">
      <div style={backgroundStyle}></div>
      <div className="faq-page">
        <section className="faq-section-description">
          <h1 className="faq-title">FAQ</h1>
          <p className="faq-intro">
            What can we help you find? For step-by-step guides, click on your desired question, and a PDF will open in a new tab!
          </p>
        </section>

        <div className="faq-grid">
          {faqItems.map((item, index) => (
            <div 
              key={index} 
              className="faq-item"
              onClick={() => openPdf(item.pdf)}
            >
              <div className="faq-item-content">
                <h3 className="faq-item-title">{`FAQ ${index + 1}: ${item.question}`}</h3>
                <p className="faq-item-description">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <footer
  className="opinari-footer"
  style={{ marginBottom: '-70px' }}
>
  <div className="opinari-footer-content">
    <nav className="opinari-footer-nav">
      <Link to="/">Home</Link>
      <Link to="/about">About</Link>
      <Link to="/feed">Feed</Link>
      <Link to="/contact">Contact</Link>
      <Link to="/pricing">Pricing</Link>
      <Link to="/faqs">FAQ</Link>
    </nav>
    <div className="opinari-footer-info">
      <p>Opinari Technologies Inc.</p>
      <p>
        <Link to="/privacy">Privacy Policy</Link> |{' '}
        <Link to="/terms">Terms and Services</Link>
      </p>
      <p>All rights reserved. Copyright ©2024</p>
      <p>Patent Pending</p>
    </div>
  </div>
</footer>
    </div>
  );
};

export default FAQPage;