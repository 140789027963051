import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation
import ColorBar from './ColorBar';
import './HomePagePoll.css'; // Import new CSS file
import { hsvaToHslaString } from '@uiw/color-convert';
import logger from './logger';
import ColorBarHomepage from './ColorBarHomepage';

const HomePagePoll = ({ pollId, question, labels, confidenceLabels, hash }) => {
  const [votes, setVotes] = useState([]);
  const [compressionFactor, setCompressionFactor] = useState(1);
  const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:8080';

  useEffect(() => {
    const fetchVotes = async () => {
      try {
        const compressedResponse = await fetch(`${apiUrl}/compressed-votes/${pollId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const compressedData = await compressedResponse.json();

        if (compressedData && compressedData.votes) {
          setVotes(compressedData.votes);
          setCompressionFactor(compressedData.compressionFactor || 1);
        } else {
          const response = await fetch(`${apiUrl}/polls/${pollId}/votes`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          });
          const data = await response.json();
          if (Array.isArray(data)) {
            setVotes(data);
          } else {
            logger.error('Fetched data is not an array:', data);
          }
        }
      } catch (error) {
        logger.error('Error fetching votes:', error);
      }
    };

    fetchVotes();
  }, [pollId, apiUrl]);

  const mapColorToOpinion = (hsva) => {
    const { h, s } = hsva;
    let baseOpinion = 'No Opinion';

    if (s === 0) return baseOpinion;

    if (h >= 60 && h <= 180) baseOpinion = labels[0];
    else if (h > 300 || h <= 60) baseOpinion = labels[2];
    else baseOpinion = labels[1];

    const strength = s < 20 ? confidenceLabels[0] :
                     s < 40 ? confidenceLabels[1] :
                     s < 60 ? confidenceLabels[2] :
                     s < 80 ? confidenceLabels[3] : confidenceLabels[4];

    return `${strength} ${baseOpinion}`;
  };

  const classifyColor = (hue) => {
    if (hue >= 60 && hue <= 180) return labels[0];
    else if (hue > 300 || hue <= 60) return labels[2];
    else return labels[1];
  };

  const calculateColorPercentages = (votesArray) => {
    if (!Array.isArray(votesArray)) return [];

    const colorCounts = votesArray.reduce((acc, vote) => {
      const color = hsvaToHslaString({ h: vote.choice, s: vote.saturation, v: 100, a: 1 });
      const classification = classifyColor(vote.choice);
      const opinion = mapColorToOpinion({ h: vote.choice, s: vote.saturation, v: 100, a: 1 });

      if (!acc[classification]) acc[classification] = [];

      acc[classification].push({ color, confidence: vote.confidence, opinion });
      return acc;
    }, {});

    const totalVotes = votesArray.length;
    const sortedColors = [labels[2], labels[1], labels[0]]
      .map(classification => {
        if (!colorCounts[classification]) return [];
        return colorCounts[classification]
          .map(entry => ({
            ...entry,
            percentage: (1 / totalVotes) * 100,
          }));
      })
      .flat();

    return sortedColors;
  };

  const colorPercentages = calculateColorPercentages(votes);

  const counts = {
    agree: votes.filter(vote => classifyColor(vote.choice) === labels[0]).length,
    disagree: votes.filter(vote => classifyColor(vote.choice) === labels[2]).length,
    neutral: votes.filter(vote => classifyColor(vote.choice) === labels[1]).length,
  };

  return (
    <Link to={`/poll/${hash}`} className="home-page-poll__container" style={{ textDecoration: 'none' }}>
      <div className="home-page-poll__question-text">
        <h3>{question.length > 30 ? `${question.substring(0, 30)}...` : question}</h3>
      </div>
      <div className="home-page-poll__total-votes"></div>
      <ColorBarHomepage colorPercentages={colorPercentages} counts={counts} labels={labels} compressionFactor={compressionFactor} />
    </Link>
  );
};

export default HomePagePoll;
