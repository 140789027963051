import React from 'react';
import './About.css';
import DesktopBG from '../assets/desktop-bg-login.jpg';
import MobileBG from '../assets/mobile-bg-login.jpg';
import ScrollingPollsVertical from './ScrollingPollsVertical';
import ScrollingPollsVerticalReverse from './ScrollingPollsVerticalReverse';
import { useNavigate, Link } from 'react-router-dom';

const About = () => {
    const isDesktop = window.innerWidth >= 768;

    const backgroundStyle = {
        backgroundImage: `url(${isDesktop ? DesktopBG : MobileBG})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: -1,
    };

    const navigate = useNavigate();


    const goToFeed = () => {
        navigate('/feed');
      };

    return (
        <div className="about-page-wrapper">
            <div style={backgroundStyle}></div>
            <div className="about-container">
                <div className="about-layout">
                <div className="combined-sections-container">
  <section className="about-para-section">
    <div className="text-content">
      <h2>About</h2>
      <p>
        OPINARI | <em>oh-pin-r-ee</em> | [verb] the Latin word meaning “to be of the opinion” refers to the act of forming or
        holding an opinion or belief about something. <br /><br />
        OPINARI lets anyone create a sophisticated poll easily and quickly by simply typing any question. <br /><br />
        OPINARI democratizes the process of conducting polls or surveys because it’s free and open to anyone
        who may not have the resources to run traditional polling campaigns. <br /><br />
        OPINARI gathers and displays real-time opinions generated by a simple, patent pending, finger-driven
        color wheel that encourages fast and inclusive answering. <br /><br />
        OPINARI polls can be shared universally via a single link or QR-Code making them accessible to anyone,
        anywhere.
      </p>
    </div>
  </section>

  

  <section className="about-para-section">
    <div className="text-content">
      <h2>Why?</h2>
      <p>
        Provides powerful sharing tools to poll any audience, like on Social. <br /><br />
        Anonymity for everyone facilitates open and fearless expression. <br /><br />
        Audiences instantly discern clear trends and see patterns within real-time polling action. <br /><br />
        New finger-driven color-wheel interface for answering is intuitive, engaging, and spikes interaction. <br /><br />
        Expands polling audiences, particularly engaging Gen Z and younger demographics who prefer quick and
        uncomplicated communication from their phones. <br /><br />
        Revolutionizes opinion gathering with rapid and very granular measurement of sentiment beyond
        traditional binary or multiple-choice polls. <br /><br />
        Empowers marketers to make quicker decisions based on instant data visibility, more inclusive
        demographics, higher completion rates, and detailed analytical reports. <br /><br />
        Provides everything needed to post, track, and see polling trends in real-time for free.
      </p>
    </div>
  </section>
  <div className="opinari-hero-buttons">
              <Link 
                to="/signup" 
                className="opinari-btn opinari-btn-primary"
                style={{ textDecoration: 'none !important' }}
              >
                <span>Start Free</span>
              </Link>
              <button 
                className="opinari-btn opinari-btn-secondary"
                onClick={goToFeed}
              >
                <span>Browse</span>
              </button>
            </div>
</div>




                    <div className="scrolling-polls-vertical-container">
                        <ScrollingPollsVertical />
                    </div>
                </div>
            </div>

            <footer className="opinari-footer" style={{ marginBottom: '-20px' }}>
                <div className="opinari-footer-content">
                    <nav className="opinari-footer-nav">
                        <Link to="/">Home</Link>
                        <Link to="/about">About</Link>
                        <Link to="/feed">Feed</Link>
                        <Link to="/contact">Contact</Link>
                        <Link to="/pricing">Pricing</Link>
                        <Link to="/faqs">FAQ</Link>
                    </nav>
                    <div className="opinari-footer-info">
                        <p>Opinari Technologies Inc.</p>
                        <p>
                            <Link to="/privacy">Privacy Policy</Link> |{' '}
                            <Link to="/terms">Terms and Services</Link>
                        </p>
                        <p>All rights reserved. Copyright ©2024</p>
                        <p>Patent Pending</p>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default About;