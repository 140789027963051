import React, { useState, useEffect, useRef, useCallback } from 'react';
import Wheel from '@uiw/react-color-wheel';
import { hsvaToHex, hsvaToHslaString } from '@uiw/color-convert';
import SubmitButton from './SubmitButton';
import ColorBar from './ColorBar';
import ConfidenceBar from './ConfidenceBar';
import { Player } from '@lottiefiles/react-lottie-player';
import animationData from '../animations/vote-animation.json';
import PollAreaChart from './PollAreaChart';
import '../App.css';
import ImageModal from './ImageModal';
import logger from './logger';


const PreviewPoll = ({ question, labels, logo, logoFill, confidenceLabels, imageAction, roundedCorners }) => {
  const [hsva, setHsva] = useState({ h: 0, s: 0, v: 100, a: 1 });
  const [size, setSize] = useState(400);
  const [opinion, setOpinion] = useState("What's your Opinion?");
  const [votes, setVotes] = useState([]);
  const [showSubmit, setShowSubmit] = useState(false);
  const [wheelActive, setWheelActive] = useState(false);
  const [animationVisible, setAnimationVisible] = useState(false);
  const [alreadyVoted, setAlreadyVoted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);

  const DEFAULT_LOGO_URL = '/logo-poll.png';
  const containerRef = useRef(null);
  const colorWheelRef = useRef(null);

  const handleChange = (color) => {
    if (wheelActive) {
      setHsva(color.hsva);
      setBodyBackgroundColor(hsvaToHex(color.hsva));
      setOpinion(mapColorToOpinion(color.hsva));
    }
  };

  const handleActivateWheel = (e) => {
    if (!wheelActive && colorWheelRef.current && colorWheelRef.current.contains(e.target) && !isSubmitting) {
      setWheelActive(true);
    }
  };

  const handleMouseUp = useCallback((event) => {
    if (wheelActive && colorWheelRef.current && colorWheelRef.current.contains(event.target)) {
      setShowSubmit(true);
    }
  }, [wheelActive]);
  
  const handleTouchEnd = useCallback((event) => {
    if (wheelActive && colorWheelRef.current && colorWheelRef.current.contains(event.target)) {
      setShowSubmit(true);
    }
  }, [wheelActive]);
  

  const handleSubmit = () => {
    setIsSubmitting(true);
    setWheelActive(false);
  
    // Determine base opinion and confidence index
    let baseOpinion = 'No Opinion';
    const h = hsva.h;
    const s = hsva.s;
  
    if (s === 0) {
      baseOpinion = 'No Opinion';
    } else {
      if (h >= 60 && h <= 180) {
        baseOpinion = labels[0];
      } else if (h > 300 || h <= 60) {
        baseOpinion = labels[2];
      } else if (h > 180 && h <= 300) {
        baseOpinion = labels[1];
      }
    }
  
    // Get the corresponding confidence labels array
    const opinionIndex = labels.indexOf(baseOpinion);
    const opinionConfidenceLabels =
      confidenceLabels[opinionIndex] || ['Very Low', 'Low', 'Medium', 'High', 'Very High'];
    const numConfidenceLevels = opinionConfidenceLabels.length;
  
    // Dynamically calculate the saturation step for each confidence level
    const stepSize = 100 / numConfidenceLevels;
  
    // Find the appropriate confidence level based on saturation
    const confidenceIndex = Math.min(Math.floor(s / stepSize), numConfidenceLevels - 1);
  
    const newVote = {
      choice: hsva.h,
      confidence: confidenceIndex,
      saturation: hsva.s,
      createdAt: new Date().toISOString(),
    };
  
    setVotes((prevVotes) => [...prevVotes, newVote]);
    setBodyBackgroundColorWithFade('', 0.5);
    setShowSubmit(false);
    setAnimationVisible(true);
  
    setTimeout(() => {
      setAnimationVisible(false);
      setIsSubmitting(false);
      // The wheel remains inactive until the user clicks on it again.
    }, 1600);
  };
  
  
  

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click was outside the color wheel and submit button
      if (wheelActive && colorWheelRef.current && !colorWheelRef.current.contains(event.target)) {
        setWheelActive(false); // Deactivate the color wheel
        setShowSubmit(false); // Hide the submit button
        setBodyBackgroundColorWithFade('', 0.5); // Fade the background back to grey
      }
    };
  
    const handleResize = () => {
      const pollItem = document.querySelector('.preview-container');
      if (pollItem) {
        const pollWidth = pollItem.offsetWidth;
        const newSize = Math.min(pollWidth, window.innerHeight) * 0.83;
        setSize(newSize); // Update color wheel size
      }
    };
  
    window.addEventListener('resize', handleResize);
    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('touchend', handleTouchEnd);
    document.addEventListener('mousedown', handleClickOutside); // Listen for clicks outside
  
    handleResize();
  
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('touchend', handleTouchEnd);
      document.removeEventListener('mousedown', handleClickOutside); // Cleanup listener
    };
  }, [wheelActive, handleMouseUp, handleTouchEnd]);

  
  

  const mapColorToOpinion = (hsva) => {
    const { h, s } = hsva;
    let baseOpinion = 'No Opinion';
  
    if (s === 0) {
      return baseOpinion;
    }
  
    // Determine base opinion by hue range
    if (h >= 60 && h <= 180) {
      baseOpinion = labels[0];
    } else if (h > 300 || h <= 60) {
      baseOpinion = labels[2];
    } else if (h > 180 && h <= 300) {
      baseOpinion = labels[1];
    }
  
    // Get the corresponding confidence labels array based on the determined opinion
    const opinionIndex = labels.indexOf(baseOpinion);
    const opinionConfidenceLabels =
      confidenceLabels[opinionIndex] || ['Very Low', 'Low', 'Medium', 'High', 'Very High'];
    const numConfidenceLevels = opinionConfidenceLabels.length;
  
    // Dynamically calculate the saturation step for each confidence level
    const stepSize = 100 / numConfidenceLevels;
  
    // Find the appropriate confidence level based on saturation
    const confidenceIndex = Math.min(Math.floor(s / stepSize), numConfidenceLevels - 1);
    const strength = opinionConfidenceLabels[confidenceIndex];
  
    return `${strength} ${baseOpinion}`;
  };

  const classifyColor = (hue) => {
    if (hue >= 60 && hue <= 180) {
      return labels[0];
    } else if (hue > 300 || hue <= 60) {
      return labels[2];
    } else if (hue > 180 && hue <= 300) {
      return labels[1];
    } else {
      return 'Mixed Feelings';
    }
  };

  const calculateColorPercentages = (votesArray) => {
    const colorCounts = votesArray.reduce((acc, vote) => {
      const color = hsvaToHslaString({ h: vote.choice, s: vote.saturation, v: 100, a: 1 });
      const classification = classifyColor(vote.choice);
      const opinion = mapColorToOpinion({ h: vote.choice, s: vote.saturation, v: 100, a: 1 });

      if (!acc[classification]) {
        acc[classification] = [];
      }

      acc[classification].push({ color, confidence: vote.confidence, timestamp: vote.createdAt, opinion });
      return acc;
    }, {});

    const totalVotes = votesArray.length;
    const sortedColors = [labels[2], labels[1], labels[0]]
      .map(classification => {
        if (!colorCounts[classification]) return [];
        return colorCounts[classification]
          .sort((a, b) => a.confidence - b.confidence)
          .map(entry => ({
            ...entry,
            percentage: (1 / totalVotes) * 100
          }));
      })
      .flat();

    return sortedColors;
  };

  const colorPercentages = calculateColorPercentages(votes);

  const counts = labels.reduce((acc, label) => {
    acc[label] = votes.filter((vote) => classifyColor(vote.choice) === label).length;
    return acc;
  }, {});

  const calculateConfidencePercentages = (votesArray, classification, baseColor) => {
    const filteredVotes = votesArray.filter(
      (vote) => classifyColor(vote.choice) === classification
    );
  
    const confidenceCounts = filteredVotes.reduce((acc, vote) => {
      if (!acc[vote.confidence]) {
        acc[vote.confidence] = 0;
      }
      acc[vote.confidence]++;
      return acc;
    }, {});
  
    const totalVotes = filteredVotes.length;
    const confidencePercentages = [];
  
    // Determine which confidence labels to use for the current classification
    const classificationIndex = labels.indexOf(classification);
    const classificationConfidenceLabels =
      confidenceLabels[classificationIndex] || ['Very Low', 'Low', 'Medium', 'High', 'Very High'];
    const numConfidenceLevels = classificationConfidenceLabels.length;
  
    for (let i = 0; i < numConfidenceLevels; i++) {
      const count = confidenceCounts[i] || 0;
      const percentage = totalVotes ? ((count / totalVotes) * 100).toFixed(1) : 0;
      if (percentage > 0) {
        const adjustedColor = computeColor(baseColor.h, i, numConfidenceLevels);
        confidencePercentages.push({
          count,
          percentage,
          color: adjustedColor,
          label: classificationConfidenceLabels[i],
        });
      }
    }
  
    return confidencePercentages;
  };

  const computeColor = (baseHue, confidenceIndex, totalConfidenceLevels) => {
    const saturation = ((confidenceIndex + 1) / totalConfidenceLevels) * 100;
    return hsvaToHslaString({ h: baseHue, s: saturation, v: 100, a: 1 });
  };

  const detectAndReplaceLinks = (text) => {
    const urlPattern = /(https?:\/\/[^\s]+)/g;
    const parts = text.split(urlPattern).map((part, index) =>
      urlPattern.test(part) ? (
        <a key={index} href={part} target="_blank" rel="noopener noreferrer" className="poll-link">
          view link
        </a>
      ) : (
        part
      )
    );
  
    return (
      <>
        &quot;
        {parts}
        &quot;
      </>
    );
  };
  

  const confidenceColors = {
    [labels[0]]: { h: 120, s: 100, v: 100, a: 1 }, // Green for first label
    [labels[1]]: { h: 240, s: 100, v: 100, a: 1 }, // Blue for second label
    [labels[2]]: { h: 0, s: 100, v: 100, a: 1 },   // Red for third label
  };

  const confidencePercentagesAgree = calculateConfidencePercentages(votes, labels[0], confidenceColors[labels[0]]);
  const confidencePercentagesNeutral = calculateConfidencePercentages(votes, labels[1], confidenceColors[labels[1]]);
  const confidencePercentagesDisagree = calculateConfidencePercentages(votes, labels[2], confidenceColors[labels[2]]);

  const setBodyBackgroundColor = (color) => {
    document.body.style.transition = ''; 
    document.body.style.backgroundColor = color;
  };

  const setBodyBackgroundColorWithFade = (color, duration) => {
    document.body.style.transition = `background-color ${duration}s`;
    document.body.style.backgroundColor = color;

    setTimeout(() => {
      document.body.style.transition = ''; 
    }, duration * 1000);
  };

  return (


    
    <div className="color-picker-container" style={{ position: 'relative' }} ref={containerRef} onClick={handleActivateWheel}>
      <div className="header">
        <div className={`logo-container ${logoFill ? 'filled' : ''}`}>
        <img
  src={logo || DEFAULT_LOGO_URL}
  alt="Logo"
  className="logo"
  style={{
    cursor: imageAction === 1 ? 'pointer' : 'auto',
    borderRadius: roundedCorners ? '10px' : '0px'
  }}
  onClick={() => {
    if (imageAction === 1) {
      setIsImageModalOpen(true);
    }
    // If imageAction is 0, do nothing (Link to Poll does nothing in preview)
  }}
/>

        </div>
      </div>
      

      <div className="question-text">
  <h3>{detectAndReplaceLinks(question)}</h3>
</div>


      <div className="opinion-text">
        <h2>{opinion}</h2>
      </div>

      <div className="color-wheel-wrapper" ref={colorWheelRef} style={{ position: 'relative' }}>
      <Wheel
  color={hsva}
  onChange={handleChange}
  width={size}
  height={size}
  style={{
    opacity: wheelActive ? 1 : alreadyVoted ? 0.5 : 0.5,
    pointerEvents: wheelActive && !isSubmitting ? 'auto' : 'none',
  }}
  onMouseUp={handleMouseUp}
  onTouchEnd={handleTouchEnd}
/>



        {showSubmit && wheelActive && !animationVisible && (
          <SubmitButton onClick={handleSubmit} />
        )}

        {animationVisible && (
          <Player
            autoplay
            loop={false}
            src={animationData}
            style={{
              height: '150px',
              width: '150px',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)'
            }}
          />
        )}
      </div>

      <div className="total-votes">
        <span>{votes.length} {votes.length === 1 ? 'Opinion' : 'Opinions'}</span>
      </div>

      <ColorBar colorPercentages={colorPercentages} counts={counts} labels={labels} />

      <div className="dropdown-toggle" onClick={() => setShowDropdown(!showDropdown)}>
        {showDropdown ? '▲ Hide stats' : '▼ Show more stats'}
      </div>

      {showDropdown && (
        <>
          <PollAreaChart key={votes.length} votes={votes} labels={labels} />
          <div className="confidence-bars">
  {labels.map((label, index) => {
    const confidencePercentages = calculateConfidencePercentages(
      votes,
      label,
      confidenceColors[label]
    );
    return (
      confidencePercentages.length > 0 && (
        <React.Fragment key={index}>
          <h1 className="confidence-label">{label}</h1>
          <ConfidenceBar
            confidencePercentages={confidencePercentages}
            className="confidence-bar"
            confidenceLabels={confidenceLabels[index]} // Pass the appropriate confidence labels
          />
        </React.Fragment>
      )
    );
  })}
</div>
        </>
      )}
      

<ImageModal
  isOpen={isImageModalOpen}
  onClose={() => setIsImageModalOpen(false)}
  imageUrl={logo || DEFAULT_LOGO_URL}
/>

   
    </div>
    
    
  );
};

export default PreviewPoll;
