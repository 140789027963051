import React, { useState, useEffect, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link } from 'react-router-dom';
import './SignUpPage.css'; // Import the SignUpPage CSS
import logo from '../assets/logo.png';
import MobileBg from '../assets/mobile-bg-login.jpg';
import DesktopBg from '../assets/desktop-bg-login.jpg';
import logger from './logger';

const ContactSupport = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState('');
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState('');
  const recaptchaRef = useRef(null);

  useEffect(() => {
    const updateBackgroundImage = () => {
      if (window.innerWidth < 768) {
        setBackgroundImage(MobileBg);
      } else {
        setBackgroundImage(DesktopBg);
      }
    };

    updateBackgroundImage();
    window.addEventListener('resize', updateBackgroundImage);

    return () => window.removeEventListener('resize', updateBackgroundImage);
  }, []);

  const handleInputChange = (setter) => (e) => {
    setError('');
    setter(e.target.value);
  };

  const handleCaptchaSuccess = async (token) => {
    try {
      const response = await fetch('/verify-captcha', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });

      const data = await response.json();
      if (data.success) {
        setCaptchaVerified(true);
        handleSubmit(); // Proceed with form submission after CAPTCHA verification
      } else {
        setError('CAPTCHA verification failed. Please try again.');
        setShowCaptcha(false);
      }
    } catch (error) {
      logger.error('Error verifying CAPTCHA:', error);
      setError('CAPTCHA verification failed. Please try again.');
      setShowCaptcha(false);
    }
  };

  const handleCaptchaExpire = () => {
    setCaptchaVerified(false);
    setShowCaptcha(false);
  };

  const handleSubmitButtonClick = (e) => {
    e.preventDefault();
    setShowCaptcha(true); // Show CAPTCHA when user clicks submit
  };

  const handleSubmit = async () => {
    try {
      const response = await fetch('/api/send-support-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email, message }),
      });

      if (response.ok) {
        setIsSubmitted(true);
      } else {
        const data = await response.json();
        setError(data.error || 'Failed to send message. Please try again.');
      }
    } catch (error) {
      logger.error('Error sending support email:', error);
      setError('An error occurred. Please try again later.');
    }
  };

  return (
    <div
      className="signup-page"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '-70px',
      }}
    >
      <div className="signup-content">
        <div className="signup-logo-container">
          <img src={logo} alt="Logo" className="signup-modal-logo" />
        </div>
        <h2 className="signup-h2">Contact Support</h2>
        {!isSubmitted ? (
          <form onSubmit={handleSubmitButtonClick} className="signup-form">
            <div className="signup-input-container">
              <input
                type="text"
                placeholder="Your Name"
                value={name}
                onChange={handleInputChange(setName)}
                required
                autoComplete="off"
                className="signup-input"
              />
              <div className="signup-input-underline"></div>
            </div>
            <div className="signup-input-container">
              <input
                type="email"
                placeholder="Your Email"
                value={email}
                onChange={handleInputChange(setEmail)}
                required
                autoComplete="off"
                className="signup-input"
              />
              <div className="signup-input-underline"></div>
            </div>
            <div className="signup-input-container">
            <textarea
  placeholder="Your Message"
  value={message}
  onChange={handleInputChange(setMessage)}
  required
  className="signup-input"
  style={{
    fontFamily: 'Poppins'
  }}
/>
              <div className="signup-input-underline"></div>
            </div>
            {error && <p className="signup-error">{error}</p>}
            <button type="submit" className="signup-button" style={{
    fontFamily: 'Poppins',
    fontWeight: '400'
  }} >Send Message </button>
          </form>
        ) : (
          <div className="signup-success-message">
            <h2>Thank you for your message!</h2>
            <p>We'll get back to you as soon as possible.</p>
          </div>
        )}
      </div>

      {showCaptcha && (
        <div className="captcha-overlay">
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            onChange={handleCaptchaSuccess}
            onExpired={handleCaptchaExpire}
            theme="dark"
          />
        </div>
      )}
    </div>
  );
};

export default ContactSupport;
