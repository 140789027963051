import React, { useState, useEffect } from 'react';
import FollowerChicklet from './FollowerChicklet';
import FilterTabs from './FilterTabs';
import logger from './logger';

const NotificationsPage = ({ isLoggedIn, userId, username }) => {
  const [followedUsers, setFollowedUsers] = useState([]);
  const [subscribers, setSubscribers] = useState([]);
  const [activeTab, setActiveTab] = useState('Subscribed'); // Track the active tab

  useEffect(() => {
    if (!isLoggedIn || !userId) return; // Check if the user is logged in and userId is available

    // Fetch followed users (subscribed)
    if (activeTab === 'Subscribed') {
      fetch(`/followed-users?followerId=${userId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.users) {
            const validUsers = data.users.filter((user) => user && user.username);
            setFollowedUsers(validUsers);
          } else {
            logger.error('No users data returned:', data);
            setFollowedUsers([]);
          }
        })
        .catch((error) => logger.error('Error fetching followed users:', error));
    }

    // Fetch subscribers (people following you)
    if (activeTab === 'Subscribers') {
      fetch(`/subscribers?userId=${userId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.users) {
            const validUsers = data.users.filter((user) => user && user.username);
            setSubscribers(validUsers);
          } else {
            logger.error('No users data returned:', data);
            setSubscribers([]);
          }
        })
        .catch((error) => logger.error('Error fetching subscribers:', error));
    }
  }, [isLoggedIn, userId, activeTab]); // Refetch when activeTab changes

  const toggleFollow = (username) => {
    // Logic to follow/unfollow users remains the same...
  };

  return (
    <div className="Feed">
    <div className="NotificationsPage">
      <div className="Feed-header">
      <FilterTabs
        options={['Subscribed', 'Subscribers']}
        onSelect={setActiveTab} // Set the active tab
      />
      </div>
      </div>
      
      {activeTab === 'Subscribed' &&
        followedUsers.map((user) => (
          <FollowerChicklet
            key={user.username}
            username={user.username}
            profileImageUrl={user.profileImageUrl}
            loggedInUsername={username}
            isFollowing={true}
            toggleFollow={toggleFollow}
          />
        ))
      }

      {activeTab === 'Subscribers' &&
        subscribers.map((user) => (
          <FollowerChicklet
            key={user.username}
            username={user.username}
            profileImageUrl={user.profileImageUrl}
            loggedInUsername={username}
            isFollowing={false} // Set isFollowing as false for subscribers by default
            toggleFollow={toggleFollow}
          />
        ))
      }
    </div>
  );
};

export default NotificationsPage;
