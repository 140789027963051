import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './LoginPage.css';
import logo from '../assets/logo.png'; // Import your logo
import MobileBg from '../assets/mobile-bg-login.jpg'; // Import mobile background
import DesktopBg from '../assets/desktop-bg-login.jpg'; // Import desktop background
import logger from './logger';


const LoginPage = ({ onLogin }) => {
  const [emailOrUsername, setEmailOrUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [backgroundImage, setBackgroundImage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Function to determine the current background based on window width
    const updateBackgroundImage = () => {
      if (window.innerWidth < 768) {
        setBackgroundImage(MobileBg); // Mobile background
      } else {
        setBackgroundImage(DesktopBg); // Desktop background
      }
    };

    // Initial check
    updateBackgroundImage();

    // Add event listener to update background on window resize
    window.addEventListener('resize', updateBackgroundImage);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', updateBackgroundImage);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    
    fetch('/auth/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ emailOrUsername, password })
    })
      .then(response => response.json())
      .then(data => {
        if (data.token) {
          onLogin(data.token, data.userId, data.username);
          navigate('/feed');
        } else {
          setError(data.error || 'Login failed');
        }
      })
      .catch(error => {
        logger.error('Error logging in:', error);
        setError('Login failed');
      });
  };

  return (
    <div 
      className="login-page" 
      style={{ 
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '-70px'
      }}
    >
      <div className="login-content">
        <div className="login-profile-logo-container">
          <img src={logo} alt="Logo" className="login-modal-logo" />
        </div>
        <h2 className="login-h2">Login</h2>
        <form onSubmit={handleSubmit} className="login-form">
          <div className="login-input-container">
            <input
              type="text"
              id="emailOrUsername"
              placeholder=" "
              value={emailOrUsername}
              onChange={(e) => setEmailOrUsername(e.target.value)}
              required
              className="login-input"
            />
            <label htmlFor="emailOrUsername">Email or Username</label>
            <div className="login-input-underline"></div>
          </div>
          <div className="login-input-container">
            <input
              type="password"
              id="password"
              placeholder=" "
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="login-input"
            />
            <label htmlFor="password">Password</label>
            <div className="login-input-underline"></div>
          </div>
          {error && <p className="login-error">{error}</p>}
          <button type="submit" className="login-button">Login</button>
        </form>
        <div className="login-forgot-password">
          <button onClick={() => navigate('/initiate-reset-password')}>
            Forgot Password?
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
