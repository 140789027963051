import React, { useState, useEffect, useRef } from 'react';
import { Link,} from 'react-router-dom';
import Poll from './Poll';
import ProfileIcon from './ProfileIcon';
import '../App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faEllipsis } from '@fortawesome/free-solid-svg-icons';
import imageCompression from 'browser-image-compression';
import { faShareSquare } from '@fortawesome/free-regular-svg-icons';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import DropdownMenu from './DropdownMenu'; // Import the new component
import { useLocation } from 'react-router-dom';
import PollSuccessModal from './PollSuccessModal';
import { useNavigate } from 'react-router-dom';
import ConnectedPollPlaceholder from './ConnectedPollPlaceholder';
import './Modal.css';
import PollPlaceholder from '../assets/poll-placeholder.png'; // Make sure to import your logo
import logger from './logger';








const Profile = ({ userId, username, isProfileView, userEmail  }) => {
  const [polls, setPolls] = useState([]);
  const [votedPolls, setVotedPolls] = useState([]); // State for voted polls
  const [bio, setBio] = useState('');
  const [firstName, setFirstName] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [newBio, setNewBio] = useState('');
  const [isUserIdLoaded, setIsUserIdLoaded] = useState(false);
  const [profileImageUrl, setProfileImageUrl] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [showCreatePoll, setShowCreatePoll] = useState(false);
  const [activeTab, setActiveTab] = useState('created'); // State for active tab
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const location = useLocation(); // Initialize useLocation
  // Add state for connected polls and selected poll IDs
const [connectedPolls, setConnectedPolls] = useState([]);
const [selectedPollIds, setSelectedPollIds] = useState([]);
const [subscriptionStatus, setSubscriptionStatus] = useState('Free'); // Add this line
const [pollRefreshKeys, setPollRefreshKeys] = useState({}); // Add this state
const [filterTab, setFilterTab] = useState('active'); // New state for active/scheduled filter
const [showCreateCollectionModal, setShowCreateCollectionModal] = useState(false);
const [collectionName, setCollectionName] = useState('');
const [selectedPolls, setSelectedPolls] = useState([]);
const [showEditCollectionModal, setShowEditCollectionModal] = useState(false);
const [currentCollection, setCurrentCollection] = useState(null);
const [isTemporary, setIsTemporary] = useState(false); // Add this line at the top











  // Add state for votes and impressions
  const [totalVotes, setTotalVotes] = useState(0);
  const [impressions, setImpressions] = useState(0); // Default value for impressions

  const dropdownRef = useRef(null);
  const navigate = useNavigate();


  const handleCreateConnectedPoll = () => {
    if (selectedPolls.length < 2) {
      alert('Please select at least 2 polls');
      return;
    }
  
    if (!collectionName) {
      alert('Please enter a name for the collection');
      return;
    }
  
    const selectedPollIds = selectedPolls.map(p => p.id); // Extract poll IDs
  
    fetch('/connected-polls', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ userId, pollIds: selectedPollIds, collectionName })
    })
      .then(response => response.json())
      .then(data => {
        fetchConnectedPolls(userId); // Fetch the connected polls again after creation
        setShowCreateCollectionModal(false);  // Close the modal
        setSelectedPolls([]);  // Reset selected polls
        setCollectionName('');  // Reset collection name
      })
      .catch(error => logger.error('Error creating connected poll:', error));
  };


  const handleEditConnectedPoll = (e, collection) => {
    e.stopPropagation(); // Prevent triggering the click event for navigating to the collection URL
  
    setCurrentCollection(collection);
    // Ensure the pollIds are properly mapped to the selectedPolls format
    const mappedPolls = collection.pollIds.map((pollId, index) => ({
        id: pollId._id || pollId, // Handle cases where pollId might be an object
        order: index + 1
    }));
    setSelectedPolls(mappedPolls);
    setShowEditCollectionModal(true);
};



  


const handleSaveEditedCollection = () => {
  if (selectedPolls.length < 2) {
    alert('Please select at least 2 polls');
    return;
  }

  const selectedPollIds = selectedPolls.map(p => p.id); // Extract poll IDs

  fetch(`/connected-polls/${currentCollection._id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ pollIds: selectedPollIds }) // Make sure to send only the required data
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Failed to update connected poll');
      }
      return response.json();
    })
    .then(data => {
      fetchConnectedPolls(userId); // Fetch the connected polls again after saving
      setShowEditCollectionModal(false);  // Close the modal
      setSelectedPolls([]);  // Reset selected polls
      setCurrentCollection(null);  // Reset current collection
    })
    .catch(error => logger.error('Error saving connected poll:', error));
};

  
  
  
  
  

  const handleShareConnectedPoll = async (e, hash) => {
    e.stopPropagation(); // Prevent triggering the click event for navigating to the collection URL
    
    const collectionUrl = `${window.location.origin}/connectedpoll/${hash}`;
    const shareData = {
      title: 'Connected Poll',
      text: 'Check out this Connected Poll!',
      url: collectionUrl,
    };
  
    try {
      if (navigator.share) {
        await navigator.share(shareData);
      } else {
        await navigator.clipboard.writeText(collectionUrl);
        alert('Connected Poll URL copied to clipboard!');
      }
    } catch (error) {
      logger.error('Error sharing Connected Poll URL:', error);
      alert('Failed to share Connected Poll URL');
    }
  };
  


  

  useEffect(() => {
    if (!userId) {
      logger.error('User ID is null or undefined');
      return;
    }
  
    setIsUserIdLoaded(true);
    logger.log('Fetching user data:', userId);
  
    fetch(`/auth/user/${userId}`)
      .then(response => response.json())
      .then(data => {
        logger.log('Fetched user data:', data);
        setBio(data.bio);
        setNewBio(data.bio);
        setSubscriptionStatus(data.subscriptionStatus || 'Free');
        
        if (data.firstName) {
          setFirstName(data.firstName);
        } else {
          logger.error('First name is missing in fetched user data:', data);
        }
  
        // Set the profile image without cache-busting parameter on initial load
        if (data.profileImageUrl) {
          setProfileImageUrl(data.profileImageUrl);
        }
        
        if (data.votedPolls && data.votedPolls.length > 0) {
          logger.log('User voted on polls:', data.votedPolls);
          fetch(`/api/polls/byIds?ids=${data.votedPolls.join(',')}`)
            .then(response => {
              if (!response.ok) {
                throw new Error('Poll not found');
              }
              return response.json();
            })
            .then(votedData => {
              logger.log('Fetched voted polls data:', votedData);
              const pollsMap = new Map(votedData.polls.map(poll => [poll._id, poll]));
              const orderedVotedPolls = data.votedPolls.map(id => pollsMap.get(id)).filter(Boolean);
              setVotedPolls(orderedVotedPolls);
            })
            .catch(error => logger.error('Error fetching voted polls:', error));
        } else {
          logger.log('No voted polls found for user');
        }
      })
      .catch(error => logger.error('Error fetching user profile:', error));
  
    if (activeTab === 'created') {
      fetchCreatedPolls(userId);
    }
  
    if (activeTab === 'linking') {
      fetchConnectedPolls(userId);
    }
  }, [userId, activeTab]);
  
  
  const fetchCreatedPolls = (creatorId) => {
    fetch(`/polls?creator=${creatorId}&limit=0&includePrivate=true`)
      .then(response => response.json())
      .then(data => {
        if (data.polls) {
          const sortedPolls = data.polls.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
          setPolls(sortedPolls);
          logger.log('Created polls fetched and sorted:', sortedPolls);
  
          const totalVotesCount = sortedPolls.reduce((acc, poll) => acc + poll.totalVotes, 0);
          setTotalVotes(totalVotesCount);
  
          let totalImpressions = 0;
          sortedPolls.forEach(poll => {
            fetch(`/api/get-impressions/${poll._id}`)
              .then(response => response.json())
              .then(data => {
                totalImpressions += data.impressions;
                setImpressions(totalImpressions);
              })
              .catch(error => logger.error('Error fetching impressions for poll:', poll._id, error));
          });
        } else {
          logger.error('No polls found:', data);
        }
      })
      .catch(error => logger.error('Error fetching created polls:', error));
  };
  
  const fetchConnectedPolls = (userId) => {
    fetch(`/connected-polls/user/${userId}`)
      .then((response) => response.json())
      .then(async (data) => {
        if (data.length > 0) {
          const connectedPollsWithVotes = await Promise.all(
            data.map(async (connectedPoll) => {
              const pollIdsWithVotes = await Promise.all(
                connectedPoll.pollIds.map(async (poll) => {
                  try {
                    // First, try to fetch compressed votes
                    const compressedResponse = await fetch(`/compressed-votes/${poll._id}`);
                    if (compressedResponse.ok) {
                      const compressedData = await compressedResponse.json();
                      if (compressedData && compressedData.votes) {
                        return { ...poll, votes: compressedData.votes, compressionFactor: compressedData.compressionFactor || 1 };
                      }
                    }
    
                    // Fallback to fetching all votes if no compressed votes are found
                    const votesResponse = await fetch(`/polls/${poll._id}/votes`);
                    if (!votesResponse.ok) {
                      throw new Error('Failed to fetch votes');
                    }
                    const votes = await votesResponse.json();
                    return { ...poll, votes };
                  } catch (error) {
                    logger.error(`Error fetching votes for poll ${poll._id}:`, error);
                    return { ...poll, votes: [] }; // Return empty votes array in case of error
                  }
                })
              );
              return { ...connectedPoll, pollIds: pollIdsWithVotes };
            })
          );
          setConnectedPolls(connectedPollsWithVotes);
        } else {
          setConnectedPolls([]);
        }
      })
      .catch((error) => logger.error('Error fetching connected polls:', error));
  };
  
  

  


  const handleCollectionClick = (hash) => {
    navigate(`/connectedpoll/${hash}`);
  };

  const handleDeleteConnectedPoll = (e, collectionId) => {
    e.stopPropagation(); // Prevent triggering the click event for navigating to the collection URL
  
    fetch(`/connected-polls/delete/${collectionId}`, {
      method: 'DELETE'
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to delete connected poll');
        }
        setConnectedPolls(connectedPolls.filter(cp => cp._id !== collectionId));
      })
      .catch(error => logger.error('Error deleting connected poll:', error));
  };
  

  useEffect(() => {
    console.log('Location state in Profile:', location.state);
    if (location.state && location.state.pollLink) {
      setShowSuccessModal(true);
      setIsTemporary(location.state.isTemporary || false);
    }
  }, [location.state]);
  

  const closeSuccessModal = () => {
    setShowSuccessModal(false);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    fetch('/auth/update', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ userId, bio: newBio })
    })
      .then(response => response.json())
      .then(data => {
        setBio(newBio);
        setIsEditing(false);
      })
      .catch(error => logger.error('Error updating profile:', error));
  };

  const handleShareClick = async () => {
    const profileUrl = `${window.location.origin}/profile/${username}`;

    try {
      if (navigator.share) {
        await navigator.share({
          title: 'Profile',
          text: `Check out ${username}'s profile!`,
          url: profileUrl,
        });
      } else {
        await navigator.clipboard.writeText(profileUrl);
        alert('Profile URL copied to clipboard!');
      }
    } catch (error) {
      logger.error('Error sharing profile URL:', error);
      alert('Failed to share profile URL');
    }
  };

  const handleDeletePoll = (pollId) => {
    setPolls(polls.filter(poll => poll._id !== pollId));
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
  
    setIsUploading(true);
  
    // Compress and resize the image
    const options = {
      maxSizeMB: 0.2,
      maxWidthOrHeight: 350,
      useWebWorker: true,
    };
  
    try {
      const compressedFile = await imageCompression(file, options);
      const formData = new FormData();
      formData.append('image', compressedFile);
      formData.append('userId', userId);
  
      const response = await fetch('/upload-profile-image', {
        method: 'POST',
        body: formData
      });
  
      if (!response.ok) {
        throw new Error('Failed to upload image');
      }
  
      const data = await response.json();
      // Apply cache-busting parameter after image upload
      setProfileImageUrl(`${data.profileImageUrl}?t=${new Date().getTime()}`);
      setIsUploading(false);
  
      // Force page refresh to ensure the new image is loaded
      window.location.reload();
    } catch (error) {
      logger.error('Error uploading profile image:', error);
      setIsUploading(false);
    }
  };
  
  

  const handleProfileShareClick = async () => {
    const profileUrl = `${window.location.origin}/profile/${username}`;

    try {
      if (navigator.share) {
        await navigator.share({
          title: 'Profile',
          text: `Check out ${username}'s profile!`,
          url: profileUrl,
        });
      } else {
        await navigator.clipboard.writeText(profileUrl);
        alert('Profile URL copied to clipboard!');
      }
    } catch (error) {
      logger.error('Error sharing profile URL:', error);
      alert('Failed to share profile URL');
    }
  };

  const filteredPolls = polls.filter(poll => {
    if (filterTab === 'active') {
      return poll.pollActive;
    } else if (filterTab === 'scheduled') {
      return !poll.pollActive && poll.scheduledPoll;
    }
    return true;
  });

  const filteredVotedPolls = votedPolls.filter(poll => {
    if (filterTab === 'active') {
      return poll.pollActive;
    } else if (filterTab === 'scheduled') {
      return !poll.pollActive && poll.scheduledPoll;
    }
    return true;
  });
  

  const handleFilterTabChange = (filter) => {
    setFilterTab(filter);
  };
  
  

  const formatNumber = (num) => {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + 'M';
    }
    if (num >= 1000) {
      return (num / 1000).toFixed(1) + 'k';
    }
    return num;
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (!isUserIdLoaded) {
    return <div>Loading...</div>;
  }

  const handlePollButtonClick = (pollId) => {
    setSelectedPolls((prevSelectedPolls) => {
      const pollIndex = prevSelectedPolls.findIndex(p => p.id === pollId);
      if (pollIndex !== -1) {
        // Deselect poll and adjust order
        const updatedSelectedPolls = prevSelectedPolls.filter(p => p.id !== pollId);
        return updatedSelectedPolls.map((poll, index) => ({ ...poll, order: index + 1 }));
      } else {
        // Select poll and add its order
        return [...prevSelectedPolls, { id: pollId, order: prevSelectedPolls.length + 1 }];
      }
    });
  };
  
  




  return (
    <div className="Profile">
      <div className="Profile-card">
        <div className="Profile-header">
          <div className="Profile-image-container">
            <label htmlFor="profile-image-upload">
              <ProfileIcon firstName={firstName} size={80} profileImageUrl={profileImageUrl} />
              {isUploading && (
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin
                  size="2x"
                  className="Profile-uploading-spinner"
                />
              )}
            </label>
            <input
              id="profile-image-upload"
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              style={{ display: 'none' }}
            />
            {showSuccessModal && (
  <PollSuccessModal
    pollLink={location.state.pollLink}
    onClose={closeSuccessModal}
    isTemporary={location.state.isTemporary}  // Add the isTemporary flag here
  />
)}
          </div>
          <div className="Profile-details">
            <h1 className="Profile-username">{username}</h1>
            <div className="Profile-stats">
              <div className="Profile-stat">
                <b>{formatNumber(polls.length)}</b>
                <span>Polls</span>
              </div>
              <div className="Profile-stat">
                <b>{formatNumber(totalVotes)}</b>
                <span>Votes</span>
              </div>
              <div className="Profile-stat">
                <b>{formatNumber(impressions)}</b>
                <span>Views</span>
              </div>
            </div>
          </div>
          <div className="dropdown" ref={dropdownRef}>
            <FontAwesomeIcon icon={faEllipsis} className="Profile-share-icon" onClick={handleDropdownToggle} />
            <DropdownMenu
  isOpen={isDropdownOpen}
  onClose={() => setIsDropdownOpen(false)}
  items={[
    { label: 'Profile Settings', onClick: () => navigate('/profile/settings', { state: { userId, currentBio: bio } }) },
    { label: 'Share Profile', onClick: handleProfileShareClick },
  ]}
  align="right"
/>

          </div>
        </div>
        <p className="Profile-bio">{bio}</p>

      </div>
  
      <div className="Profile-tab-buttons">
        <button
          className={`Profile-tab-button ${activeTab === 'created' ? 'active' : ''}`}
          onClick={() => setActiveTab('created')}
        >
          My Polls
        </button>
        <button
          className={`Profile-tab-button ${activeTab === 'voted' ? 'active' : ''}`}
          onClick={() => setActiveTab('voted')}
        >
          Voted On
        </button>
        <button
          className={`Profile-tab-button ${activeTab === 'linking' ? 'active' : ''}`}
          onClick={() => setActiveTab('linking')}
        >
          Connected Polls
        </button>
      </div>
  
      {activeTab === 'created' && (
        <div className="Profile-filter-tabs">
          <button
            className={`Profile-filter-tab-button ${filterTab === 'active' ? 'active active-tab' : ''}`}
            onClick={() => handleFilterTabChange('active')}
          >
            Active
          </button>
          <button
            className={`Profile-filter-tab-button ${filterTab === 'scheduled' ? 'active scheduled-tab' : ''}`}
            onClick={() => handleFilterTabChange('scheduled')}
          >
            Scheduled
          </button>
        </div>
      )}
  
      {activeTab === 'linking' && (
        <div>
          <button className="Collection-Add-button" onClick={() => setShowCreateCollectionModal(true)}>Create New Collection</button>
          <div className="Connected-poll-list">
            {connectedPolls.length > 0 ? (
              connectedPolls.map((cp) => (
                <div key={cp._id} className="ConnectedPoll-box" >
                  <FontAwesomeIcon
                    icon={faShareSquare}
                    className="ConnectedPoll-box-share-icon"
                    onClick={(e) => handleShareConnectedPoll(e, cp.hash)}
                  />
                  <FontAwesomeIcon
                    icon={faEye}
                    className="ConnectedPoll-box-view-icon"
                    onClick={(e) => handleCollectionClick(cp.hash)}
                  />
                  <h3 className="ConnectedPoll-box-title">{cp.collectionName}</h3>
                  {cp.pollIds.map((poll) => (
                    <div key={poll._id} className="Connected-Poll-placeholder-item">
                      <ConnectedPollPlaceholder
                        pollId={poll._id}
                        question={poll.question}
                        labels={poll.labels}
                        logo={poll.logo || '/logo-poll.png'}
                        logoFill={poll.logoFill}
                        votes={poll.votes}
                        hash={poll.hash}
                        creatorUsername={poll.creatorUsername}
                        creatorId={poll.creator}
                        isLoggedIn={true}
                        userId={userId}
                        username={username}
                        isProfileView={isProfileView}
                        voteAuthenticationMethod={poll.voteAuthenticationMethod}
                        confidenceLabels={poll.confidenceLabels}
                        imageAction={poll.imageAction}
                        roundedCorners={poll.roundedCorners}
                        startDate={poll.startDate}
                        endDate={poll.endDate}
                        scheduledPoll={poll.scheduledPoll}
                      />
                    </div>
                  ))}
                  <button className="connected-box-edit-button" onClick={(e) => handleEditConnectedPoll(e, cp)}>Edit</button>
                  <button className="connected-box-delete-poll" onClick={(e) => handleDeleteConnectedPoll(e, cp._id)}>Delete Collection</button>
                </div>
              ))
            ) : (
              <p>No connected polls found.</p>
            )}
          </div>
        </div>
      )}
  
  <div className="Profile-polls">
  {activeTab === 'created' && filteredPolls.length > 0 ? (
    filteredPolls.map(poll => (
      <div key={poll._id} className="Poll-item">
        <Poll
          pollId={poll._id}
          question={poll.question}
          labels={poll.labels}
          logo={poll.logo || "/logo-poll.png"}
          logoFill={poll.logoFill}
          votes={poll.votes}
          hash={poll.hash}
          creatorUsername={poll.creatorUsername}
          creatorId={poll.creator}
          isLoggedIn={true}
          userId={userId}
          username={username}
          onDelete={handleDeletePoll}
          isProfileView={isProfileView}
          voteAuthenticationMethod={poll.voteAuthenticationMethod}
          confidenceLabels={poll.confidenceLabels}
          imageAction={poll.imageAction}
          roundedCorners={poll.roundedCorners}
          startDate={poll.startDate}
          endDate={poll.endDate}
          scheduledPoll={poll.scheduledPoll}
          userEmail={userEmail}
          totalVotes={poll.totalVotes}
          isTemporary={poll.isTemporary}

        />
      </div>
    ))
  ) : activeTab === 'created' && (filterTab === 'active' || filterTab === 'scheduled') ? (
    <div className="Poll-placeholder-container">
      <Link to="/create-poll" class="profile-placeholder-add-button">+</Link>
      <img src={PollPlaceholder} alt="No Polls Found" className="Poll-placeholder-image" />
    </div>
  ) : activeTab === 'voted' && filteredVotedPolls.length > 0 ? (
    filteredVotedPolls.map(poll => (
      <div key={poll._id} className="Poll-item">
        <Poll
          pollId={poll._id}
          question={poll.question}
          labels={poll.labels}
          logo={poll.logo || "/logo-poll.png"}
          logoFill={poll.logoFill}
          votes={poll.votes}
          hash={poll.hash}
          creatorUsername={poll.creatorUsername}
          creatorId={poll.creator}
          isLoggedIn={true}
          userId={userId}
          username={username}
          isProfileView={isProfileView}
          voteAuthenticationMethod={poll.voteAuthenticationMethod}
          confidenceLabels={poll.confidenceLabels}
          imageAction={poll.imageAction}
          roundedCorners={poll.roundedCorners}
          startDate={poll.startDate}
          endDate={poll.endDate}
          scheduledPoll={poll.scheduledPoll}
          userEmail={userEmail}
          totalVotes={poll.totalVotes}
          isTemporary={poll.isTemporary}

        />
      </div>
    ))
  ) : activeTab === 'voted' && filterTab === 'active' ? (
    <p>You have not voted on any polls yet.</p>
  ) : activeTab === 'voted' && filterTab === 'scheduled' ? (
    <p>You have not voted on any polls yet.</p>
  ) : activeTab === 'linking' ? (
    <p></p>
  ) : (
    <p>No polls found in this category.</p>
  )}
</div>


  
      {showCreateCollectionModal && (
        <div className="profile-create-collection-modal">
          <div className="profile-create-collection-modal-content">
            <h2>Select polls you want in this collection.</h2>
            <input
              type="text"
              value={collectionName}
              onChange={(e) => setCollectionName(e.target.value)}
              placeholder="Enter collection name"
              className="collection-name-input profile-create-collection-modal-collection-name-input"
            />
            <div>
              {polls.map(poll => {
                const selectedPoll = selectedPolls.find(p => p.id === poll._id);
                return (
                  <button
                    key={poll._id}
                    className={`profile-create-collection-modal-poll-button ${selectedPoll ? 'profile-create-collection-modal-poll-button-selected' : 'profile-create-collection-modal-poll-button-unselected'}`}
                    onClick={() => handlePollButtonClick(poll._id)}
                  >
                    {selectedPoll ? `#${selectedPoll.order} - ` : ''}{poll.question}
                  </button>
                );
              })}
            </div>
            <button className="profile-create-collection-modal-action-button profile-create-collection-modal-create-button" onClick={handleCreateConnectedPoll}>Create</button>
            <button className="profile-create-collection-modal-action-button profile-create-collection-modal-cancel-button" onClick={() => setShowCreateCollectionModal(false)}>Cancel</button>
          </div>
        </div>
      )}
  
      {showEditCollectionModal && (
        <div className="profile-create-collection-modal">
          <div className="profile-create-collection-modal-content">
            <h2>Edit your collection.</h2>
            <div>
              {polls.map(poll => {
                const selectedPoll = selectedPolls.find(p => p.id === poll._id);
                return (
                  <button
                    key={poll._id}
                    className={`profile-create-collection-modal-poll-button ${selectedPoll ? 'profile-create-collection-modal-poll-button-selected' : 'profile-create-collection-modal-poll-button-unselected'}`}
                    onClick={() => handlePollButtonClick(poll._id)}
                  >
                    {selectedPoll ? `#${selectedPoll.order} - ` : ''}{poll.question}
                  </button>
                );
              })}
            </div>
            <button className="profile-create-collection-modal-action-button profile-create-collection-modal-create-button" onClick={handleSaveEditedCollection}>Save</button>
            <button className="profile-create-collection-modal-action-button profile-create-collection-modal-cancel-button" onClick={() => setShowEditCollectionModal(false)}>Cancel</button>
          </div>
        </div>
      )}
  
      
    </div>
  );
  
  
  
  
};

export default Profile;
