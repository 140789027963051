import React, { useEffect, useState } from 'react';
import HomePagePoll from './HomePagePoll';
import '../App.css';
import logger from './logger';

const ScrollingPolls = () => {
  const [polls, setPolls] = useState([]);
  const [votes, setVotes] = useState({});

  useEffect(() => {
    fetch('/polls?sort=trending&limit=8')  // Change sort to 'trending'
      .then((response) => response.json())
      .then((data) => {
        setPolls(data.polls);
        data.polls.forEach((poll) => {
          fetch(`/polls/${poll._id}/votes`)
            .then((response) => response.json())
            .then((votesData) => {
              setVotes((prevVotes) => ({ ...prevVotes, [poll._id]: votesData }));
            })
            .catch((error) => logger.error(`Error fetching votes for poll ${poll._id}:`, error));
        });
      })
      .catch((error) => logger.error('Error fetching trending polls:', error));
  }, []);
  

  return (
    <div className="wrapper">
      {polls.map((poll, index) => (
        <div key={index} className={`item item${index + 1}`}>
          <HomePagePoll
            pollId={poll._id}
            question={poll.question}
            labels={poll.labels}
            hash={poll.hash}
            confidenceLabels={[
              ['Very Low', 'Low', 'Medium', 'High', 'Very High'],
              ['Very Low', 'Low', 'Medium', 'High', 'Very High'],
              ['Very Low', 'Low', 'Medium', 'High', 'Very High'],
            ]}
          />
        </div>
      ))}
    </div>
  );
};

export default ScrollingPolls;
