import React, { useState, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import imageCompression from 'browser-image-compression';
import { useNavigate } from 'react-router-dom';
import './CreatePoll.css';
import UpgradeModal from './UpgradeModal';
import PreviewPoll from './PreviewPoll';
import './datepicker-dark-theme.css';  // Import your custom dark theme CSS
import captchaImage from '../assets/captcha.png';
import Info from './Info';
import logger from './logger';
import AiIconActive from '../assets/ai-active.png';
import AiIcon from '../assets/ai.png';
import LoadingIcon from '../assets/loading-small.png';
import ImageCropperModal from './ImageCropperModal';










const CreatePoll = ({ isLoggedIn, userId, username, onCreate }) => {
  const [newPollQuestion, setNewPollQuestion] = useState('');
  const [newPollLabels, setNewPollLabels] = useState(["Agree", "Neutral", "Disagree"]);
  const [newPollCategory, setNewPollCategory] = useState('Technology');
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [redirectLink, setRedirectLink] = useState('');
  const [redirectMessage, setRedirectMessage] = useState('');
  const [redirectMessageError, setRedirectMessageError] = useState('');
  const [voteAuthenticationMethod, setVoteAuthenticationMethod] = useState(1);
  const [pollQuestionError, setPollQuestionError] = useState('');
  const [useCustomLabels, setUseCustomLabels] = useState(false);
  const [customLabels, setCustomLabels] = useState(["Agree", "Neutral", "Disagree"]);
  const [customConfidenceLabels, setCustomConfidenceLabels] = useState([
    ["", "", ""],
    ["", "", ""],
    ["", "", ""]
  ]);  const [visibility, setVisibility] = useState('Public');
  const [logo, setLogo] = useState(null);
  const [tempLogoUrl, setTempLogoUrl] = useState('/logo-poll.png');
  const textareaRef = useRef(null);
  const [fillBackground, setFillBackground] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [subscriptionStatus, setSubscriptionStatus] = useState('Free');
  const [imageAction, setImageAction] = useState(0);
  const [roundCorners, setRoundCorners] = useState(false);
  const [isPreviewing, setIsPreviewing] = useState(false);
  const [pollConfig, setPollConfig] = useState(null);
  const [useSchedule, setUseSchedule] = useState(false);
  const [startDate, setStartDate] = useState(new Date()); // Default to "Now"
  const [endDate, setEndDate] = useState(new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)); // Default to 7 days from now
  const [useCaptcha, setUseCaptcha] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [upgradeFeature, setUpgradeFeature] = useState('');
  const [currentPlan, setCurrentPlan] = useState(subscriptionStatus);
  const [isTyping, setIsTyping] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const linkConfidenceLevelsRef = useRef(true);
  const [mainOptionAnimations, setMainOptionAnimations] = useState([false, false, false]); // For three main options
  const [confidenceLevelAnimations, setConfidenceLevelAnimations] = useState(
    customConfidenceLabels.map(() => [false, false, false, false, false]) // Initialize animation states for confidence levels
  );
  const [forceUpdate, setForceUpdate] = useState(false); // A state to force re-render
  const [isCropping, setIsCropping] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);



  const generatePollAnswers = async () => {

    

    if (!newPollQuestion.trim()) {
      setPollQuestionError('Please enter a poll question first.');
      return;
    }
  
    try {
      setIsGenerating(true); // Start loading
  
      const response = await fetch('/api/generate-random-poll-answers', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          question: newPollQuestion, // Send the current question
        }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch poll answers');
      }
  
      const { answers } = await response.json();
      const parsedAnswers = JSON.parse(answers); // Parse the JSON response
  
      const mainAnswers = parsedAnswers.mainAnswers;
      const confidenceLevels = parsedAnswers.confidenceLevels;
  
      // Simulate typing effect for each answer and confidence level
      await simulateTypingEffectForAnswers(mainAnswers, confidenceLevels);
  
    } catch (error) {
      console.error('Error generating poll answers:', error);
      alert('Failed to generate answers. Please try again.');
    } finally {
      setIsGenerating(false); // End loading after response
    }
  };
  
  const simulateTypingEffectForAnswers = async (mainAnswers, confidenceLevels) => {
    setIsTyping(true); // Start typing animation
  
    // Use typing effect for each main answer
    for (let i = 0; i < mainAnswers.length; i++) {
      handleCustomLabelChange(i, mainAnswers[i]); // Update with functional state
  
      // Trigger animation for the current main option
      setMainOptionAnimations((prev) => {
        const newAnimations = [...prev];
        newAnimations[i] = true;
        return newAnimations;
      });
  
      await new Promise((resolve) => setTimeout(resolve, 200)); // Adjust delay as needed
  
      // Reset animation after delay
      setMainOptionAnimations((prev) => {
        const newAnimations = [...prev];
        newAnimations[i] = false;
        return newAnimations;
      });
    }
  
    // Use typing effect for each confidence level
    for (let i = 0; i < confidenceLevels.length; i++) {
      for (let j = 0; j < confidenceLevels[i].length; j++) {
        handleCustomConfidenceLabelChange(i, j, confidenceLevels[i][j]);
  
        // Trigger animation for the current confidence level
        setConfidenceLevelAnimations((prev) => {
          const newAnimations = prev.map((arr, index) => {
            if (index === i) {
              const newConfAnimations = [...arr];
              newConfAnimations[j] = true;
              return newConfAnimations;
            }
            return arr;
          });
          return newAnimations;
        });
  
        await new Promise((resolve) => setTimeout(resolve, 200)); // Adjust delay as needed
  
        // Reset animation after delay
        setConfidenceLevelAnimations((prev) => {
          const newAnimations = prev.map((arr, index) => {
            if (index === i) {
              const newConfAnimations = [...arr];
              newConfAnimations[j] = false;
              return newConfAnimations;
            }
            return arr;
          });
          return newAnimations;
        });
      }
    }
  
    setIsTyping(false); // End typing animation
  };



//CustomInput component
const CustomInput = React.forwardRef(({ value, onClick, label }, ref) => (
  <div className="custom-date-input" onClick={onClick} ref={ref}>
    {label}: {value || `Select ${label.toLowerCase()}`}
  </div>
));


const handlePreviewPoll = () => {
  if (!newPollQuestion.trim()) {
    setPollQuestionError('Poll question is required.');
    return;
  } else {
    setPollQuestionError('');
  }

  let finalLabels = useCustomLabels ? customLabels.map(label => label.trim()) : newPollLabels;
  if (useCustomLabels && finalLabels.every(label => label === "")) {
    finalLabels = ["Green", "Blue", "Red"];
  }

  // Ensure all labels in finalConfidenceLabels are strings and trim them
  let finalConfidenceLabels = customConfidenceLabels.map(confidenceArray =>
    confidenceArray.map(confLabel => (typeof confLabel === 'string' ? confLabel.trim() : ''))
  );

  // Provide default values if necessary
  if (finalConfidenceLabels.every(arr => arr.every(label => label === ""))) {
    finalConfidenceLabels = [
      ["Very Low", "Low", "Medium", "High", "Very High"],
      ["Very Low", "Low", "Medium", "High", "Very High"],
      ["Very Low", "Low", "Medium", "High", "Very High"]
    ];
  }

  const previewConfig = {
    question: newPollQuestion,
    labels: finalLabels,
    confidenceLabels: finalConfidenceLabels,
    category: newPollCategory,
    creator: userId,
    creatorUsername: username,
    redirectLink,
    redirectMessage,
    voteAuthenticationMethod,
    visibility,
    logo: tempLogoUrl,  // Use the temporary logo URL
    logoFill: fillBackground,
    imageAction,
    roundedCorners: roundCorners,
  };

  setPollConfig(previewConfig);
  setIsPreviewing(true);
  // Scroll to the top of the page
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

  useEffect(() => {
    logger.log("userId in CreatePoll:", userId);
  }, [userId]);

  const pollOptions = [
    ["Yes", "Maybe", "No"],
    ["Agree", "Neutral", "Disagree"],
    ["Positive", "Neutral", "Negative"],
    ["Approve", "Indifferent", "Disapprove"],
    ["Good", "Average", "Bad"],
    ["Support", "Neutral", "Oppose"],
    ["True", "Unsure", "False"],
    ["High", "Medium", "Low"],
    ["Like", "Neutral", "Dislike"],
    ["Happy", "Neutral", "Unhappy"],
    ["Satisfied", "Neutral", "Unsatisfied"]
  ];

  const confidenceMapping = {
    "Yes, Maybe, No": [
      ["Weak", "Unsure", "Moderate", "Strong", "Confident"],
      ["Weak", "Unsure", "Moderate", "Strong", "Confident"],
      ["Weak", "Unsure", "Moderate", "Strong", "Confident"],
    ],
    "Agree, Neutral, Disagree": [
      ["Marginally", "Slightly", "Somewhat", "Moderately", "Strongly"],
      ["Marginally", "Slightly", "Somewhat", "Moderately", "Strongly"],
      ["Marginally", "Slightly", "Somewhat", "Moderately", "Strongly"],
    ],
    "Positive, Neutral, Negative": [
      ["Marginally", "Slightly", "Moderately", "Strongly", "Extremely"],
      ["Marginally", "Slightly", "Moderately", "Strongly", "Extremely"],
      ["Marginally", "Slightly", "Moderately", "Strongly", "Extremely"],
    ],
    "Approve, Indifferent, Disapprove": [
      ["Minimally", "Slightly", "Somewhat", "Moderately", "Strongly"],
      ["Minimally", "Slightly", "Somewhat", "Moderately", "Strongly"],
      ["Minimally", "Slightly", "Somewhat", "Moderately", "Strongly"],
    ],
    "Good, Average, Bad": [
      ["Marginally", "Slightly", "Somewhat", "Kinda", "Very"],
      ["Marginally", "Slightly", "Somewhat", "Kinda", "Very"],
      ["Marginally", "Slightly", "Somewhat", "Kinda", "Very"],
    ],
    "Support, Neutral, Oppose": [
      ["Marginally", "Slightly", "Somewhat", "Moderately", "Strongly"],
      ["Marginally", "Slightly", "Somewhat", "Moderately", "Strongly"],
      ["Marginally", "Slightly", "Somewhat", "Moderately", "Strongly"],
    ],
    "High, Medium, Low": [
      ["Marginally", "Slightly", "Somewhat", "Moderately", "Extremely"],
      ["Marginally", "Slightly", "Somewhat", "Moderately", "Extremely"],
      ["Marginally", "Slightly", "Somewhat", "Moderately", "Extremely"],
    ],
    "Like, Neutral, Dislike": [
      ["Marginally", "Slightly", "Somewhat", "Moderately", "Strongly"],
      ["Marginally", "Slightly", "Somewhat", "Moderately", "Strongly"],
      ["Marginally", "Slightly", "Somewhat", "Moderately", "Strongly"],
    ],
    "Happy, Neutral, Unhappy": [
      ["Marginally", "Slightly", "Somewhat", "Moderately", "Extremely"],
      ["Marginally", "Slightly", "Somewhat", "Moderately", "Extremely"],
      ["Marginally", "Slightly", "Somewhat", "Moderately", "Extremely"],
    ],
    "Satisfied, Neutral, Unsatisfied": [
      ["Marginally", "Slightly", "Somewhat", "Kinda", "Very"],
      ["Marginally", "Slightly", "Somewhat", "Kinda", "Very"],
      ["Marginally", "Slightly", "Somewhat", "Kinda", "Very"],
    ],
    "True, Unsure, False": [
      ["Marginally", "Slightly", "Somewhat", "Kinda", "Very"],
      ["Marginally", "Slightly", "Somewhat", "Kinda", "Very"],
      ["Marginally", "Slightly", "Somewhat", "Kinda", "Very"],
    ],
  };
  

  const categories = [
    'Technology',
    'Health',
    'Education',
    'Entertainment',
    'Politics',
    'Sports',
    'Lifestyle',
    'Environment',
    'Fashion',
    'Services',
    'Products',
    'Travel',
    'Food',
    'Business'
  ];

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [newPollQuestion]);

  useEffect(() => {
    const checkSubscriptionStatus = async () => {
      try {
        if (!isLoggedIn) {
          logger.log('User is not logged in, skipping subscription status check.');
          return; // Skip subscription status check for non-logged-in users
        }
        const response = await fetch(`/auth/subscription-status/${userId}`);
        const data = await response.json();
        setSubscriptionStatus(data.subscriptionStatus || 'Free'); // Default to 'Free' if undefined
      } catch (error) {
        logger.error('Error checking subscription status:', error);
      }
    };
  
    checkSubscriptionStatus();
  }, [userId]);

  useEffect(() => {
    if (!useCustomLabels) {
      const selectedLabels = newPollLabels.join(', ');
      const correspondingConfidenceLabels = confidenceMapping[selectedLabels];
  
      if (correspondingConfidenceLabels) {
        setCustomConfidenceLabels(correspondingConfidenceLabels.map(conf => [...conf])); // Copy arrays to ensure immutability
      } else {
        setCustomConfidenceLabels([
          ["Very Low", "Low", "Medium", "High", "Very High"],
          ["Very Low", "Low", "Medium", "High", "Very High"],
          ["Very Low", "Low", "Medium", "High", "Very High"],
        ]);
      }
    }
  }, [newPollLabels, useCustomLabels]);

  const canCreatePoll = async () => {
    try {
      if (!isLoggedIn) {
        logger.log('User is not logged in, skipping poll count check.');
        return true; // Allow poll creation for non-logged-in users
      }
      const response = await fetch(`/auth/poll-count/${userId}`);
      const data = await response.json();
      const limits = { Free: 2, Pro: 10, Elite: 100, Unlimited: 1000 };
      if (data.pollCount >= limits[subscriptionStatus]) {
        setShowUpgradeModal(true);
        setUpgradeFeature('pollLimit');  // This is the feature that triggered the modal
        setCurrentPlan(subscriptionStatus || 'Free');  // Pass the current subscription status
        return false;
      }
      return true;
    } catch (error) {
      logger.error('Error fetching poll count:', error);
      return false; // Fallback to disallow poll creation if something goes wrong
    }
  };
  
  

  const handleConfirmCreatePoll = async () => {
    logger.log('Starting poll creation process');
    setIsLoading(true);
  
    // Check if the poll question is provided
    if (!newPollQuestion.trim()) {
      setPollQuestionError('Poll question is required.');
      setIsLoading(false);
      return;
    } else {
      setPollQuestionError('');
    }
  
    // Set default creator and creatorUsername based on the login status
    let finalCreator = userId;
    let finalCreatorUsername = username;
    let isTemporary = false; // Track if the poll is temporary
  
    // If the user is not logged in, assign anonymous values
    if (!isLoggedIn) {
      finalCreator = "66f2fc9406a25b4676dcc980"; // Anonymous creator ID
      finalCreatorUsername = "Anonymous";
      isTemporary = true; // Set temporary poll to true
    }
  
    // Handle upgrade check and poll limit enforcement
    const allowedToCreate = await canCreatePoll();
    if (!allowedToCreate) {
      setShowUpgradeModal(true);
      setIsLoading(false);
      return;
    }
  
    // Validate redirect message length
    if (redirectMessage.length > 70) {
      setRedirectMessageError('Redirect message cannot exceed 70 characters.');
      setIsLoading(false);
      return;
    }
  
    // Format redirect link
    let formattedRedirectLink = redirectLink;
    if (redirectLink && !redirectLink.startsWith('http://') && !redirectLink.startsWith('https://')) {
      formattedRedirectLink = `https://${redirectLink}`;
    }
  
    // Prepare final poll labels and confidence levels
    let finalLabels = useCustomLabels ? customLabels.map(label => label.trim()) : newPollLabels;
    if (useCustomLabels && finalLabels.every(label => label === "")) {
      finalLabels = ["Green", "Blue", "Red"];
    }
  
    let finalConfidenceLabels = customConfidenceLabels.map(confidenceArray =>
      confidenceArray.map(confLabel => (typeof confLabel === 'string' ? confLabel.trim() : ''))
    );
    if (finalConfidenceLabels.every(arr => arr.every(label => label === ""))) {
      finalConfidenceLabels = [
        ["Very Low", "Low", "Medium", "High", "Very High"],
        ["Very Low", "Low", "Medium", "High", "Very High"],
        ["Very Low", "Low", "Medium", "High", "Very High"]
      ];
    }
  
    // Prepare form data for poll creation
    const formData = new FormData();
    formData.append('question', newPollQuestion);
    formData.append('labels', JSON.stringify(finalLabels));
    formData.append('confidenceLabels', JSON.stringify(finalConfidenceLabels));
    formData.append('category', newPollCategory);
    formData.append('creator', finalCreator); // Use finalCreator
    formData.append('creatorUsername', finalCreatorUsername); // Use finalCreatorUsername
    formData.append('redirectLink', formattedRedirectLink);
    formData.append('redirectMessage', redirectMessage);
    formData.append('voteAuthenticationMethod', voteAuthenticationMethod);
    formData.append('visibility', visibility);
    formData.append('logoFill', fillBackground);
    formData.append('imageAction', imageAction);
    formData.append('roundedCorners', roundCorners);
    formData.append('useCaptcha', useCaptcha);
    formData.append('isTemporary', isTemporary); // Include isTemporary field
  
    // Handle logo upload
    if (logo) {
      formData.append('logo', logo);
    }
  
    // Handle poll scheduling data
    formData.append('scheduledPoll', useSchedule);
    if (useSchedule) {
      formData.append('startDate', startDate.toISOString());
      formData.append('endDate', endDate.toISOString());
  
      const now = new Date();
      const pollActive = startDate <= now && (!endDate || endDate >= now);
      formData.append('pollActive', pollActive);
    } else {
      formData.append('pollActive', true);
    }
  
    // Ensure start and end date are valid
    if (startDate && endDate && startDate > endDate) {
      alert('End date cannot be before the start date.');
      setIsLoading(false);
      return;
    }
  
    // Make the API call to create the poll
    fetch('/polls', {
      method: 'POST',
      headers: {
        'Authorization': isLoggedIn ? `Bearer ${localStorage.getItem('token')}` : '', // Only use token if logged in
      },
      body: formData,
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        onCreate(data);
        setNewPollQuestion('');
        setNewPollLabels(["Agree", "Neutral", "Disagree"]);
        setNewPollCategory('Technology');
        setRedirectLink('');
        setRedirectMessage('');
        setTempLogoUrl('/logo-poll.png');
        setLogo(null);
  
 // If the poll is temporary, store the poll ID and URL with hash in localStorage
 if (isTemporary) {
  let tempPolls = JSON.parse(localStorage.getItem('temporaryCreations')) || [];
  tempPolls.push({ pollId: data._id, pollUrl: `/poll/${data.hash}` });
  localStorage.setItem('temporaryCreations', JSON.stringify(tempPolls));
}

// Corrected navigation logic
if (isTemporary) {
  navigate(`/poll/${data.hash}?created=true`);
} else {
  navigate('/profile', { state: { pollLink: data.pollUrl, showModal: true, isTemporary } });
}

      })
      .catch(error => {
        logger.error('Error adding poll:', error);
        alert('An error occurred while creating the poll. Please try again.');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  
  
  

  
  

  
  

  const handleCustomLabelChange = (index, value) => {
    if (value.length <= 15) {
      setCustomLabels((prevLabels) => {
        const updatedLabels = [...prevLabels];
        updatedLabels[index] = value;
        return updatedLabels;
      });
    }
  };
  

  const handleCustomConfidenceLabelChange = (optionIndex, labelIndex, value) => {
    if (value.length <= 15) {
      const updatedLabels = [...customConfidenceLabels];
  
      if (linkConfidenceLevelsRef.current) {
        updatedLabels.forEach((_, i) => {
          updatedLabels[i][labelIndex] = value;
        });
      } else {
        updatedLabels[optionIndex][labelIndex] = value;
      }
  
      setCustomConfidenceLabels(updatedLabels);
    }
  };
  

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
  
    const reader = new FileReader();
    reader.onloadend = () => {
      setSelectedImage(reader.result); // Show image in the cropping modal
      setIsCropping(true); // Show cropping modal
      setRoundCorners(true); // Turn on rounded corners by default after image upload

    };
    reader.readAsDataURL(file);
  };
  
  const handleCropComplete = (croppedImage) => {
    setLogo(croppedImage); // Use the cropped image
    setTempLogoUrl(URL.createObjectURL(croppedImage)); // Preview the cropped image
    setIsCropping(false); // Hide cropping modal
  };


  const handleDeleteConfidenceLevel = (optionIndex, labelIndex) => {
    // Always turn off linking when deleting a confidence level
    linkConfidenceLevelsRef.current = false;
  
    const updatedConfidenceLabels = [...customConfidenceLabels];
  
    // Remove the confidence level at the specified index
    if (updatedConfidenceLabels[optionIndex].length > 1) {
      updatedConfidenceLabels[optionIndex].splice(labelIndex, 1);
    }
  
    // Ensure there is at least one confidence level
    if (updatedConfidenceLabels[optionIndex].length === 0) {
      updatedConfidenceLabels[optionIndex].push(""); // Add an empty confidence level if the last one is deleted
    }
  
    setCustomConfidenceLabels(updatedConfidenceLabels);
  };
  
  

  const handleAddConfidenceLevel = (optionIndex) => {
    linkConfidenceLevelsRef.current = false;
  
    setCustomConfidenceLabels((prevConfidenceLabels) => {
      const updatedConfidenceLabels = [...prevConfidenceLabels];
      
      // Add a new confidence level if there are less than 5
      if (updatedConfidenceLabels[optionIndex].length < 5) {
        updatedConfidenceLabels[optionIndex].push("");
      }
      
      return updatedConfidenceLabels;
    });
  };


const calculateDynamicColors = (confidenceLevels, baseColor) => {
  const minOpacity = 0.08;
  const maxOpacity = 0.8;
  const step = (maxOpacity - minOpacity) / (confidenceLevels.length - 1); // Calculate step size for the gradient

  return confidenceLevels.map((level, index) => {
    const opacity = minOpacity + step * index; // Calculate opacity for each level
    return `rgba(${baseColor}, ${opacity})`; // Apply base color with dynamic opacity
  });
};


useEffect(() => {
  if (useCustomLabels) {
    setCustomConfidenceLabels([
      ["Marginally", "Somewhat", "Strongly"],
      ["Marginally", "Somewhat", "Strongly"],
      ["Marginally", "Somewhat", "Strongly"]
    ]);
  }
}, [useCustomLabels]);




  
  





  
  


return (
  <div className="create-poll-page">
    {isLoading && (
      <div className="loading-overlay">
        <div className="loading-content">
          <div className="loading-text">Creating Poll</div>
          <div className="loading-spinner"></div>
        </div>
      </div>
    )}

{showUpgradeModal && (
  <UpgradeModal
    onClose={() => setShowUpgradeModal(false)}
    feature={upgradeFeature}
    currentPlan={currentPlan}
    isLoggedIn={isLoggedIn}
  />
)}

    {isPreviewing ? (
      <div className="preview-container">
        <div className="preview-header">
  <h1 className="preview-title">Preview</h1>
  <p className="preview-description">Test your poll! Any votes made here won't be counted as real votes.</p>
</div>
        <PreviewPoll
          pollId={null}  // Since this is a preview, we won't have an ID yet
          question={pollConfig.question}
          labels={pollConfig.labels}
          logo={pollConfig.logo}
          logoFill={pollConfig.logoFill}
          hash={"preview-hash"}  // Use a placeholder hash
          isLoggedIn={isLoggedIn}
          userId={userId}
          creatorId={userId}
          creatorUsername={username}
          voteAuthenticationMethod={pollConfig.voteAuthenticationMethod}
          confidenceLabels={pollConfig.confidenceLabels}
          imageAction={pollConfig.imageAction}
          roundedCorners={pollConfig.roundedCorners}
        />
        <div className="preview-actions">
        <button className="edit-button" onClick={() => setIsPreviewing(false)}>Back to Editing</button>
<button className="create-button" onClick={handleConfirmCreatePoll}>Create Poll!</button>

        </div>
      </div>
    ) : (
      <div className="create-poll-content">
        <h2 className="page-title">Create Poll</h2>

        {/* Image Upload Section */}
        <div className="image-upload-container">
  <div className={`logo-background ${fillBackground ? 'filled' : ''}`}>
    <img
      src={logo ? tempLogoUrl : '/logo.png'}  // Default to /logo.png if no logo is uploaded
      alt="Poll Logo"
      className="poll-logo"
      onClick={() => document.getElementById('logo-upload').click()}
      style={{
        borderRadius: roundCorners ? '10px' : '0px',
      }}
    />
  </div>
  <button
    type="button"
    className="upload-logo-button"
    onClick={() => document.getElementById('logo-upload').click()}
  >
    Upload Image
  </button>
  <div className="image-options-small-text">
    Optimum Size - 16:9
  </div>
  <input
    type="file"
    accept="image/*"
    id="logo-upload"
    style={{ display: 'none' }}
    onChange={handleImageUpload}
  />

  {isCropping && selectedImage && (
    <ImageCropperModal
      imageSrc={selectedImage}
      onCropComplete={handleCropComplete}
      onCancel={() => setIsCropping(false)}
    />
  )}
</div>

        {logo && (
          <div className="image-options">

            <h3 className="image-options-title">Image Options</h3>
            <div className="custom-checkbox">
              <input
                type="checkbox"
                id="roundCorners"
                checked={roundCorners}
                onChange={(e) => {
                  if (e.target.checked) {
                    setRoundCorners(true);
                    setFillBackground(false);
                  } else {
                    setRoundCorners(false);
                  }
                }}
              />
              <label htmlFor="roundCorners">Round Corners</label>
            </div>

            <div className="custom-checkbox">
              <input
                type="checkbox"
                id="fillBackground"
                checked={fillBackground}
                onChange={(e) => {
                  if (e.target.checked) {
                    setRoundCorners(false);
                  }
                  setFillBackground(e.target.checked);
                }}
              />
              <label htmlFor="fillBackground">Fill Transparent Background</label>
            </div>
            <h4 className="image-action-title">Image Action</h4>
            <div className="radio-group">
              <label className="radio-label">
                <input
                  type="radio"
                  name="imageAction"
                  value={0}
                  checked={imageAction === 0}
                  onChange={() => setImageAction(0)}
                />
                Link to Poll
              </label>
              <label className="radio-label">
                <input
                  type="radio"
                  name="imageAction"
                  value={1}
                  checked={imageAction === 1}
                  onChange={() => setImageAction(1)}
                />
                Expand Image
              </label>
            </div>
          </div>
        )}

<div className="textarea-container">
  <textarea
    ref={textareaRef}
    placeholder="Enter poll question..."
    value={newPollQuestion}
    onChange={(e) => {
      setNewPollQuestion(e.target.value);
      if (e.target.value.trim()) {
        setPollQuestionError('');  // Clear the error if the question is filled
      }
    }}
    className={`poll-question-input ${pollQuestionError ? 'input-error' : ''}`} // Add error class if there's an error
    rows={1}
    onInput={(e) => {
      e.target.style.height = 'auto';
      e.target.style.height = `${e.target.scrollHeight}px`;
    }}
    readOnly={isTyping}
  />
  {pollQuestionError && <p className="error-message" style={{ color: 'red' }}>{pollQuestionError}</p>} {/* Display error */}
</div>


{/* Poll Options */}
<div className="poll-options">
  <div className="custom-checkbox">
  <input
  type="checkbox"
  id="useCustomLabels"
  checked={useCustomLabels}
  onChange={(e) => setUseCustomLabels(e.target.checked)}
/>
<label htmlFor="useCustomLabels">Use custom answers</label>
  </div>

  {/* Display preset options if not using custom labels */}
  {!useCustomLabels &&
    pollOptions.slice(0, 3).map((option, index) => (
      <label key={index} className="radio-label">
        <input
          type="radio"
          name="pollLabels"
          value={option}
          checked={newPollLabels.join(',') === option.join(',')}
          onChange={() => setNewPollLabels(option)}
          className="radio-input"
        />
        {option.join(', ')}
      </label>
    ))}

  {/* Show more options if enabled */}
  {!useCustomLabels && showMoreOptions &&
    pollOptions.slice(3).map((option, index) => (
      <label key={index + 3} className="radio-label">
        <input
          type="radio"
          name="pollLabels"
          value={option}
          checked={newPollLabels.join(',') === option.join(',')}
          onChange={() => setNewPollLabels(option)}
          className="radio-input"
        />
        {option.join(', ')}
      </label>
    ))}

  {/* Toggle to show or hide more options */}
  {!useCustomLabels && (
    <div
      className="show-more-toggle"
      onClick={() => setShowMoreOptions(!showMoreOptions)}
    >
      {showMoreOptions ? "▲ Hide answers" : "▼ Show more answers"}
    </div>
  )}

  {/* Custom labels and confidence levels input */}
  {useCustomLabels && (
  <div className="custom-labels-inputs">
    <div className="ai-autofill-container">
    <button
  className="ai-generate-button"
  onClick={() => {
    linkConfidenceLevelsRef.current = false; // Directly set ref to false
    generatePollAnswers();
  }}
>
  {isGenerating ? (
    <>
      <span>Generating</span>
      <img src={LoadingIcon} alt="Loading" className="loading-icon" />
    </>
  ) : (
    <>
      <span>Autofill</span>
      <img src={AiIcon} alt="Autofill Answers with AI" />
    </>
  )}
</button>
</div>
{customLabels.map((label, optionIndex) => {
  const baseColors = ["0, 255, 0", "0, 0, 255", "255, 0, 0"]; // Green, Blue, Red
  const confidenceColors = calculateDynamicColors(customConfidenceLabels[optionIndex], baseColors[optionIndex]);

  return (
    <div key={optionIndex} className={`option-group option-${optionIndex + 1}`}>
      <div className="main-option-container">
        {/* Main Option Input */}
        <input
          type="text"
          value={label}
          onChange={(e) => handleCustomLabelChange(optionIndex, e.target.value)}
          placeholder={`Option ${optionIndex + 1}`}
          className={`main-option-input ${mainOptionAnimations[optionIndex] ? 'animated' : ''}`} // Animate main option
        />
        
        {/* "+" Button to Add Confidence Levels */}
        <button
          className="add-confidence-level-button"
          onClick={() => handleAddConfidenceLevel(optionIndex)}
          disabled={customConfidenceLabels[optionIndex].length >= 5} // Disable if there are already 5 confidence levels
        >
          +
        </button>
      </div>

      {/* Confidence Levels for This Option */}
      <div className="confidence-levels-inputs">
        {customConfidenceLabels[optionIndex].map((confLabel, labelIndex) => (
          <div key={labelIndex} className="confidence-input-container">
            <input
              type="text"
              value={confLabel}
              onChange={(e) =>
                handleCustomConfidenceLabelChange(optionIndex, labelIndex, e.target.value)
              }
              placeholder={`Confidence Level ${labelIndex + 1}`}
              className={`confidence-input ${confidenceLevelAnimations[optionIndex][labelIndex] ? 'animated' : ''}`} // Animate confidence input
              style={{
                backgroundColor: confidenceColors[labelIndex], // Apply dynamic background color
                borderColor: confidenceColors[labelIndex].replace(/[^,]+(?=\))/, '1'), // Apply dynamic border color
              }}
            />
            {/* Delete Button */}
            <button
              className="delete-confidence-button"
              onClick={() => handleDeleteConfidenceLevel(optionIndex, labelIndex)}
            >
              &times; {/* "X" symbol */}
            </button>
          </div>
        ))}

        {/* Link Confidence Levels Button */}
        <div className="link-button-container">
          <button
            className={`link-confidence-button ${linkConfidenceLevelsRef.current ? 'linked' : 'unlinked'}`}
            onClick={() => {
              linkConfidenceLevelsRef.current = !linkConfidenceLevelsRef.current;
              setForceUpdate(prev => !prev); // Toggle forceUpdate to trigger re-render
            }}
          >
            <i className={`fa ${linkConfidenceLevelsRef.current ? 'fa-link' : 'fa-link'}`} aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>
  );
})}

  </div>
)}



</div>



        {/* Poll Category */}
        <div className="poll-category">
          <label>
            Category
            <Info infoCase="Category" />
            <select
              value={newPollCategory}
              onChange={(e) => setNewPollCategory(e.target.value)}
              className="category-select"
            >
              {categories.map((category, index) => (
                <option key={index} value={category}>{category}</option>
              ))}
            </select>
          </label>
        </div>

        {/* Voting Rules */}
        <div className="poll-auth-method">
  <label>
    Voting Rules
    <Info infoCase="Voting Rules" />

    <select
      value={voteAuthenticationMethod}
      onChange={(e) => setVoteAuthenticationMethod(parseInt(e.target.value))}
      className="auth-method-select"
    >
      <option value={1}>One vote per person</option>
      <option value={0}>Allow multiple votes per person</option>
    </select>
  </label>
</div>

        {/* Poll Visibility */}
        <div className="poll-visibility">
  <label>
    Visibility
    <Info infoCase="Visibility" />

    <select
      value={visibility}
      onChange={(e) => {
        setVisibility(e.target.value);
        if (e.target.value !== 'Private') {
          setUseCaptcha(false); // Disable CAPTCHA if visibility is not private
        }
      }}
      className="visibility-select"
    >
      <option value="Public">Public</option>
      <option value="Private">Private</option>
    </select>
  </label>
</div>

{/* CAPTCHA Checkbox */}
{visibility === 'Private' && (
  <div className="custom-checkbox">
    <input
      type="checkbox"
      id="useCaptcha"
      checked={useCaptcha}
      onChange={(e) => {
        if (subscriptionStatus === 'Free') {
          setShowUpgradeModal(true);
          setUpgradeFeature('reCaptcha');  // This is the feature that triggered the modal
          setCurrentPlan(subscriptionStatus || 'Free');  // Pass the current subscription status
        } else {
          setUseCaptcha(e.target.checked);
        }
      }}
    />
    <label htmlFor="useCaptcha" style={{ display: 'flex', alignItems: 'center' }}>
      Enable reCAPTCHA
      <img 
      src={captchaImage} 
      alt="Info"
        style={{ marginLeft: '8px', width: '20px', height: '20px' }} 
      />
    </label>
  </div>
)}



        {/* Extra Options */}
<div className="extra-options">
  <div className="poll-create">
    <label>
      Redirect After Vote
      <Info infoCase="Redirect" />

      <input
  type="url"
  value={redirectLink}
  onFocus={(e) => {
    if (subscriptionStatus === 'Free') {
      e.target.blur();
      setShowUpgradeModal(true);
      setUpgradeFeature('linkRedirection');  // This is the feature that triggered the modal
      setCurrentPlan(subscriptionStatus || 'Free');  // Pass the current subscription status
    }
  }}
  onChange={(e) => {
    if (subscriptionStatus !== 'Free') {
      setRedirectLink(e.target.value);
    }
  }}
  placeholder="https://example.com"
  className="redirect-input"
  style={{ height: '25px', marginTop: '5px' }}
/>

    </label>
  </div>
  <div className="poll-create">
    <label>
      Message
      <textarea
        value={redirectMessage}
        onFocus={(e) => {
          if (subscriptionStatus === 'Free') {
            e.target.blur();
            setShowUpgradeModal(true);
          }
        }}
        onChange={(e) => {
          if (subscriptionStatus !== 'Free') {
            if (e.target.value.length <= 70) {
              setRedirectMessage(e.target.value);
              setRedirectMessageError('');
            }
          }
        }}
        placeholder="Check out our website!"
        className="redirect-message"
        style={{ marginTop: '5px' }}  // Set the height here
      />
      {redirectMessageError && <p className="error-message">{redirectMessageError}</p>}
    </label>
  </div>
  <div className="custom-checkbox">
  <input
    type="checkbox"
    id="useSchedule"
    checked={useSchedule}
    onChange={(e) => {
      if (subscriptionStatus === 'Free') {
        setShowUpgradeModal(true);
        setUpgradeFeature('schedule');  // This is the feature that triggered the modal
        setCurrentPlan(subscriptionStatus || 'Free');  // Pass the current subscription status
      } else {
        setUseSchedule(e.target.checked);
      }
    }}
  />
  <label htmlFor="useSchedule">Set Poll Schedule
  <Info infoCase="Schedule" />

  </label>
</div>


  {useSchedule && (
    <div className="schedule-options">
      <div className="date-picker">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <DatePicker
            selected={startDate}
            onChange={(date) => {
              const now = new Date();
              const selectedStartDate = date < now ? now : date;

              // If start and end date are the same day, ensure end date is at least 1 hour later
              if (endDate && selectedStartDate.toDateString() === endDate.toDateString()) {
                const adjustedEndDate = new Date(endDate);
                if (selectedStartDate >= endDate) {
                  adjustedEndDate.setHours(selectedStartDate.getHours() + 1);
                  setEndDate(adjustedEndDate);
                }
              }

              setStartDate(selectedStartDate);
            }}
            showTimeSelect
            timeIntervals={1} // Set time intervals to 1 minute for more precise time selection
            dateFormat="Pp"
            placeholderText="Select start date"
            className="date-picker-input"
            customInput={<CustomInput label="Start" />}
            maxDate={endDate}
          />
          <button
            type="button"
            onClick={() => setStartDate(new Date())}
            className="now-button"
            style={{ marginLeft: '10px', backgroundColor: '#1a73e8', color: 'white', border: 'none', padding: '8px 10px', borderRadius: '5px', cursor: 'pointer' }}
          >
            Now
          </button>
        </div>
      </div>

      <div className="date-picker">
        <DatePicker
          selected={endDate}
          onChange={(date) => {
            const selectedEndDate = new Date(date);

            // Ensure end date is not before start date
            if (startDate && selectedEndDate.toDateString() === startDate.toDateString()) {
              if (selectedEndDate <= startDate) {
                selectedEndDate.setHours(startDate.getHours() + 1); // Add 1 hour if the selected end time is earlier than start time
              }
            }

            setEndDate(selectedEndDate);
          }}
          showTimeSelect
          timeIntervals={1} // Set time intervals to 1 minute for more precise time selection
          dateFormat="Pp"
          placeholderText="Select end date"
          className="date-picker-input"
          customInput={<CustomInput label="End" />}
          minDate={startDate}
        />
      </div>
    </div>
  )}
</div>





      

        {/* Action Buttons */}
        <div className="page-buttons">
          <button className="preview-button" onClick={handlePreviewPoll}>Preview Poll</button>
          <button className="create-button" onClick={handleConfirmCreatePoll}>Create Poll!</button>
        </div>
      </div>
    )}
  </div>
);

  
};

export default CreatePoll;
