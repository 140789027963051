import React, { useState, useEffect, useRef } from 'react';
import { hsvaToHex, hsvaToHslaString } from '@uiw/color-convert';
import ColorBar from './ColorBar';
import '../App.css';
import logger from './logger';


const ConnectedPollPlaceholder = ({ pollId, question, labels, isLoggedIn, userId, confidenceLabels }) => {
  const [votes, setVotes] = useState([]);
  const [compressionFactor, setCompressionFactor] = useState(1); // State for the compression factor
  const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:8080';
  const containerRef = useRef(null);

  useEffect(() => {
    const fetchVotes = async () => {
      try {
        // Try to fetch compressed votes first
        const compressedResponse = await fetch(`${apiUrl}/compressed-votes/${pollId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
        const compressedData = await compressedResponse.json();

        // If compressed votes exist, use them; otherwise, fallback to regular votes
        if (compressedData && compressedData.votes) {
          setVotes(compressedData.votes);
          setCompressionFactor(compressedData.compressionFactor || 1); // Set the compression factor
        } else {
          // Fetch all votes if no compressed votes are available
          const response = await fetch(`${apiUrl}/polls/${pollId}/votes`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
          });
          const data = await response.json();
          if (Array.isArray(data)) {
            setVotes(data);
          } else {
            logger.error('Fetched data is not an array:', data);
          }
        }
      } catch (error) {
        logger.error('Error fetching votes:', error);
      }
    };

    fetchVotes();

    // Fetch voted polls for logged-in users
    if (isLoggedIn) {
      fetch(`${apiUrl}/users/${userId}/voted-polls`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => response.json())
        .then(data => {
        })
        .catch(error => logger.error('Error fetching votedPolls:', error));
    }
  }, [pollId, apiUrl, userId, isLoggedIn]);

  const mapColorToOpinion = (hsva) => {
    const { h, s } = hsva;
    let baseOpinion = 'No Opinion';

    if (s === 0) {
      return baseOpinion;
    }

    if (h >= 60 && h <= 180) {
      baseOpinion = labels[0];
    } else if (h > 300 || h <= 60) {
      baseOpinion = labels[2];
    } else if (h > 180 && h <= 300) {
      baseOpinion = labels[1];
    }

    const strength = s < 20 ? confidenceLabels[0] :
                     s < 40 ? confidenceLabels[1] :
                     s < 60 ? confidenceLabels[2] :
                     s < 80 ? confidenceLabels[3] : confidenceLabels[4];

    return `${strength} ${baseOpinion}`;
  };

  const detectAndReplaceLinks = (text) => {
    const urlPattern = /(https?:\/\/[^\s]+)/g;
    const parts = text.split(urlPattern).map((part, index) =>
      urlPattern.test(part) ? (
        <a key={index} href={part} target="_blank" rel="noopener noreferrer" className="poll-link">
          view link
        </a>
      ) : (
        part
      )
    );

    return (
      <>
        &quot;
        {parts}
        &quot;
      </>
    );
  };

  const classifyColor = (hue) => {
    if (hue >= 60 && hue <= 180) {
      return labels[0];
    } else if (hue > 300 || hue <= 60) {
      return labels[2];
    } else if (hue > 180 && hue <= 300) {
      return labels[1];
    } else {
      return 'Mixed Feelings';
    }
  };

  const calculateColorPercentages = (votesArray) => {
    if (!Array.isArray(votesArray)) {
      return [];
    }

    const colorCounts = votesArray.reduce((acc, vote) => {
      const color = hsvaToHslaString({ h: vote.choice, s: vote.saturation, v: 100, a: 1 });
      const classification = classifyColor(vote.choice);
      const opinion = mapColorToOpinion({ h: vote.choice, s: vote.saturation, v: 100, a: 1 });

      if (!acc[classification]) {
        acc[classification] = [];
      }

      acc[classification].push({ color, confidence: vote.confidence, timestamp: vote.createdAt, opinion });
      return acc;
    }, {});

    const totalVotes = votesArray.length;
    const sortedColors = [labels[2], labels[1], labels[0]]
      .map(classification => {
        if (!colorCounts[classification]) return [];
        return colorCounts[classification]
          .sort((a, b) => a.confidence - b.confidence)
          .map(entry => ({
            ...entry,
            percentage: (1 / totalVotes) * 100,
          }));
      })
      .flat();

    return sortedColors;
  };

  const colorPercentages = calculateColorPercentages(votes);

  const counts = {
    agree: votes.filter(vote => classifyColor(vote.choice) === labels[0]).length,
    disagree: votes.filter(vote => classifyColor(vote.choice) === labels[2]).length,
    neutral: votes.filter(vote => classifyColor(vote.choice) === labels[1]).length,
  };

  return (
    <>
      <div
        className="placeholder-color-picker-container"
        style={{
          position: 'relative',
        }}
        ref={containerRef}
      >
        <div className="placeholder-question-text">
          <h3>{detectAndReplaceLinks(question)}</h3>
        </div>
        <div className="total-votes">
          {/* <span>{votes.length} {votes.length === 1 ? 'Opinion' : 'Opinions'}</span> */}
        </div>
        <ColorBar colorPercentages={colorPercentages} counts={counts} labels={labels} compressionFactor={compressionFactor} />
      </div>
    </>
  );
};

export default ConnectedPollPlaceholder;
