import React, { useEffect, useState } from 'react';
import PollFake from './PollFake';
import '../App.css';
import logger from './logger';

const ScrollingPollsVertical = () => {
  const [polls, setPolls] = useState([]);
  const [votes, setVotes] = useState({});

  useEffect(() => {
    // Fetch the most popular polls
    fetch('/popular-polls?limit=8')
      .then(response => response.json())
      .then(data => {
        setPolls(data.polls);
        // Fetch votes for each poll
        data.polls.forEach(poll => {
          fetch(`/polls/${poll._id}/votes`)
            .then(response => response.json())
            .then(votesData => {
              setVotes(prevVotes => ({ ...prevVotes, [poll._id]: votesData }));
            })
            .catch(error => logger.error(`Error fetching votes for poll ${poll._id}:`, error));
        });
      })
      .catch(error => logger.error('Error fetching popular polls:', error));
  }, []);

  return (
    <div className="wrapper-vertical">
      {polls.map((poll, index) => (
        <div key={index} className={`item-vertical item-vertical${index + 1}`}>
          <PollFake 
            question={poll.question} 
            labels={poll.labels} 
            votes={votes[poll._id] || []} 
            hash={poll.hash} 
          />
        </div>
      ))}
    </div>
  );
};

export default ScrollingPollsVertical;
