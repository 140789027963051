import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // Add useLocation to access passed state
import ReCAPTCHA from 'react-google-recaptcha'; // Import ReCAPTCHA
import './SignUpPage.css';
import logo from '../assets/logo.png'; // Import your logo
import MobileBg from '../assets/mobile-bg-login.jpg'; // Import mobile background
import DesktopBg from '../assets/desktop-bg-login.jpg'; // Import desktop background
import logger from './logger';


const SignUpPage = ({ onSignUp }) => {
  const [username, setUsername] = useState('');
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [gender, setGender] = useState('unknown'); // Default to 'unknown' if not specified
  const [age, setAge] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showCaptcha, setShowCaptcha] = useState(false); // State to control CAPTCHA visibility
  const [captchaVerified, setCaptchaVerified] = useState(false); // State to check CAPTCHA verification
  const [backgroundImage, setBackgroundImage] = useState('');
  const recaptchaRef = useRef(null); // Reference for ReCAPTCHA
  const navigate = useNavigate();
  const [honeypot, setHoneypot] = useState('');
  const location = useLocation();  // Access passed state from previous page
  const pollId = location.state?.pollId;  // Get pollId from state if passed
  const [pollIdCleared, setPollIdCleared] = useState(false);



  useEffect(() => {
    // Check if there's a poll being claimed
    if (pollId) {
      console.log("User is claiming a poll after signing up.");
    }
  }, [pollId]);


  useEffect(() => {
    // Function to determine the current background based on window width
    const updateBackgroundImage = () => {
      if (window.innerWidth < 768) {
        setBackgroundImage(MobileBg); // Mobile background
      } else {
        setBackgroundImage(DesktopBg); // Desktop background
      }
    };

    // Initial check
    updateBackgroundImage();

    // Add event listener to update background on window resize
    window.addEventListener('resize', updateBackgroundImage);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', updateBackgroundImage);
  }, []);

  const handleUsernameChange = (e) => {
    const newUsername = e.target.value;
    const usernameRegex = /^[a-zA-Z0-9_-]+$/; // Allowed characters
  
    // Check if the username matches the allowed pattern
    if (!usernameRegex.test(newUsername)) {
      setError('Username can only contain letters, numbers, underscores, and hyphens');
    } else if (newUsername.length < 3) {
      setError('Username must be at least 3 characters long');
    } else {
      setError(''); // Clear error if the username is valid and long enough
    }
  
    setUsername(newUsername);
  };
  

  const handleInputChange = (setter) => (e) => {
    setError(''); // Clear error on input change
    setter(e.target.value);
  };

  const handleCaptchaSuccess = async (token) => {
    // Verify CAPTCHA token with the backend
    try {
      const response = await fetch('/verify-captcha', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });

      const data = await response.json();
      if (data.success) {
        setCaptchaVerified(true); // Set CAPTCHA verified to true
        handleSignUp(); // Proceed with signup after CAPTCHA verification
      } else {
        setError('CAPTCHA verification failed. Please try again.');
        setShowCaptcha(false); // Hide CAPTCHA if verification fails
      }
    } catch (error) {
      logger.error('Error verifying CAPTCHA:', error);
      setError('CAPTCHA verification failed. Please try again.');
      setShowCaptcha(false); // Hide CAPTCHA if verification fails
    }
  };

  const handleCaptchaExpire = () => {
    setCaptchaVerified(false); // Reset CAPTCHA verified state on expiration
    setShowCaptcha(false); // Hide CAPTCHA if it expires
  };

  const handleSignUpButtonClick = (e) => {
    e.preventDefault();
  
    const usernameRegex = /^[a-zA-Z0-9_-]+$/; // Allowed characters
  
    // Check if the username matches the allowed pattern
    if (!usernameRegex.test(username)) {
      setError('Username can only contain letters, numbers, underscores, and hyphens');
      return; // Prevent form submission if the username is invalid
    }
  
    // Check if the username is at least 3 characters long
    if (username.length < 3) {
      setError('Username must be at least 3 characters long');
      return; // Prevent form submission if username is too short
    }
  
    // Check if the honeypot field is filled (bot detection)
    if (honeypot) {
      setError('Bot detected. Sign up failed.');
      return; // Prevent form submission if honeypot field is filled
    }
  
    setError(''); // Clear error before further validation
  
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
  
    setShowCaptcha(true); // Show CAPTCHA when user clicks Sign Up
  };
  
  

  const handleSignUp = () => {
    const payload = { username, firstName, email, password, gender, age };
  
    // If there's a pollId from the location, pass it as the isClaiming field to the backend
    if (pollId) {
      payload.isClaiming = pollId; // Pass the poll ID to the backend if the user is claiming a poll
    }

     // Clear the poll message after clicking sign up
    setPollIdCleared(true);
  
    fetch('/auth/signup', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message) {
          setSuccessMessage('Thanks for signing up! A verification link has been sent to your email. 👍');
          // Clear fields after successful sign-up
          setUsername('');
          setFirstName('');
          setEmail('');
          setPassword('');
          setConfirmPassword('');
          setGender('unknown');
          setAge('');
          setError('');
          setShowCaptcha(false);
        } else {
          setError(data.error || 'Sign up failed');
        }
      })
      .catch((error) => {
        logger.error('Error signing up:', error);
        setError('Sign up failed');
      });
  };
  
  
  

  return (
    <div
      className="signup-page"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '-70px',
      }}
    >
      <div className="signup-content">
        <div className="signup-logo-container">
          <img src={logo} alt="Logo" className="signup-modal-logo" />
        </div>
        <h2 className="signup-h2">Sign Up</h2>
        {pollId && !pollIdCleared && (
  <p className="claim-message">Your poll will automatically be added to your account.</p>
)}
        {successMessage ? (
          <p className="signup-success">{successMessage}</p>
        ) : (
          <form onSubmit={handleSignUpButtonClick} className="signup-form">
            <div className="signup-input-container">
              <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={handleUsernameChange}
                required
                autoComplete="off"
                className="signup-input"
              />
              <div className="signup-input-underline"></div>
            </div>
            <div className="signup-input-container">
              <input
                type="text"
                placeholder="First Name"
                value={firstName}
                onChange={handleInputChange(setFirstName)}
                required
                autoComplete="off"
                className="signup-input"
              />
              <div className="signup-input-underline"></div>
              <div className="signup-input-container honeypot-field">
              <input
                type="text"
                name="lastname"
                placeholder="Leave this field empty"
                value={honeypot}
                onChange={handleInputChange(setHoneypot)}
                style={{ display: 'none' }} // Hide it using inline style or CSS
                autoComplete="off"
                className="signup-input"
              />
            </div>
            </div>
            <div className="signup-input-container">
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={handleInputChange(setEmail)}
                required
                autoComplete="off"
                className="signup-input"
              />
              <div className="signup-input-underline"></div>
            </div>
            <div className="signup-input-container">
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={handleInputChange(setPassword)}
                required
                autoComplete="new-password"
                className="signup-input"
              />
              <div className="signup-input-underline"></div>
            </div>
            <div className="signup-input-container">
              <input
                type="password"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={handleInputChange(setConfirmPassword)}
                required
                autoComplete="new-password"
                className="signup-input"
              />
              <div className="signup-input-underline"></div>
            </div>
            <div className="signup-gender-select-container">
              <select
                className="signup-gender-select"
                value={gender}
                onChange={handleInputChange(setGender)}
              >
                <option value="unknown">Select Gender (optional)</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="non-binary">Non-Binary</option>
                <option value="other">Other</option>
              </select>
            </div>
            <div className="signup-input-container">
              <input
                type="number"
                placeholder="Age (optional)"
                value={age}
                onChange={handleInputChange(setAge)}
                maxLength="3"
                className="signup-input"
              />
              <div className="signup-input-underline"></div>
            </div>
            {error && <p className="signup-error">{error}</p>}
            <button type="submit" className="signup-button">Sign Up</button>
          </form>
        )}
      </div>

      {showCaptcha && (
        <div className="captcha-overlay">
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            onChange={handleCaptchaSuccess}
            onExpired={handleCaptchaExpire}
            theme="dark"
          />
        </div>
      )}
    </div>
  );
};

export default SignUpPage;
