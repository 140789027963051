import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useLocation } from 'react-router-dom';
import './Pricing.css'; // Import the CSS file
import DesktopBG from '../assets/desktop-bg-login.jpg';
import MobileBG from '../assets/mobile-bg-login.jpg';
import logger from './logger';
import { useNavigate, Link } from 'react-router-dom';


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Pricing = ({ userId }) => {
    const location = useLocation();
    const initialPlan = location.state?.selectedPlan || 'Basic';
    const [selectedPlan, setSelectedPlan] = useState(initialPlan);
    const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);
    const [currentSubscription, setCurrentSubscription] = useState('');
    const [username, setUsername] = useState('');

    useEffect(() => {
        const fetchSubscriptionStatus = async () => {
            try {
                const response = await fetch(`/auth/subscription-status/${userId}`);
                if (response.ok) {
                    const data = await response.json();
                    setCurrentSubscription(data.subscriptionStatus);
                    setUsername(data.username);
                } else {
                    logger.error('Failed to fetch subscription status');
                }
            } catch (error) {
                logger.error('Error fetching subscription status:', error);
            }
        };
    
        fetchSubscriptionStatus();
    }, [userId]);

    useEffect(() => {
        logger.log('Selected Plan:', selectedPlan);
    }, [selectedPlan]);

    useEffect(() => {
        const handleResize = () => {
            setIsDesktop(window.innerWidth >= 768);
        };
        
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleUpgrade = async (priceId, newSubscriptionStatus) => {
        try {
            const userResponse = await fetch(`/auth/subscription-status/${userId}`);
            
            if (!userResponse.ok) {
                throw new Error('Failed to fetch user subscription status');
            }

            const userData = await userResponse.json();
            const currentSubscriptionId = userData.subscriptionId;

            if (newSubscriptionStatus === 'Basic') {
                const confirmDowngrade = window.confirm('Are you sure you want to downgrade to the Free plan? Your current subscription will be canceled at the end of the next billing period.');
                if (!confirmDowngrade) {
                    return;
                }

                if (currentSubscriptionId) {
                    const cancelResponse = await fetch('/api/payments/cancel-subscription', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ subscriptionId: currentSubscriptionId }),
                    });

                    if (!cancelResponse.ok) {
                        throw new Error('Failed to cancel the subscription');
                    }
                }

                const updateResponse = await fetch('/auth/update-subscription-status', {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ userId, subscriptionStatus: 'Free' }),
                });

                if (!updateResponse.ok) {
                    throw new Error('Failed to update subscription status in MongoDB');
                }

                await fetch('/auth/send-slack-alert', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ type: 'new_upgrade', data: { username: userData.username, tier: 'Free', action: 'downgraded' } })
                });

                alert('Successfully downgraded to the Free plan.');
                setSelectedPlan('Basic');
                return;
            }

            if (userData.subscriptionStatus !== 'Free') {
                const confirmUpgrade = window.confirm(`Are you sure you want to upgrade to the ${newSubscriptionStatus} plan?`);
                if (!confirmUpgrade) {
                    return;
                }
            }

            const response = await fetch('/api/payments/create-or-update-subscription', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ priceId, userId, currentSubscriptionId }),
            });

            if (!response.ok) {
                throw new Error('Failed to create or update Stripe subscription');
            }

            const data = await response.json();

            if (data.sessionId) {
                const stripe = await stripePromise;
                const { error } = await stripe.redirectToCheckout({
                    sessionId: data.sessionId,
                });

                if (error) {
                    logger.error('Stripe Checkout Error:', error);
                }
            } else {
                alert(`Successfully upgraded to the ${newSubscriptionStatus} plan.`);
                const updateResponse = await fetch('/auth/update-subscription-status', {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ userId, subscriptionStatus: newSubscriptionStatus }),
                });

                if (!updateResponse.ok) {
                    throw new Error('Failed to update subscription status in MongoDB');
                }

                setSelectedPlan(newSubscriptionStatus);

                await fetch('/auth/send-slack-alert', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ type: 'new_upgrade', data: { username: userData.username, tier: newSubscriptionStatus, action: 'upgraded' } })
                });
            }
        } catch (error) {
            logger.error('Error during upgrade:', error);
            alert('There was an issue with the upgrade. Please try again.');
        }
    };

    const renderPlanCard = (plan) => {
        let priceId, planName, content, buttonText, isDisabled, features;
        
        const basicFeatures = [
            'Create up to 2 Polls',
            'Use custom answers',
            'Voting Status Bar',
            'Voting Timeline Graph',
            'Voting Confidence Bars',
            'Live Dashboard',
            'Share Tools',
            'Live Feed Access',
            'One vote per browser session',
            'One vote per IP',
            'Unique Poll URLs',
            'QR Code',
            'Embed',
            'Link Redirection',
        ];
    
        switch (plan) {
            case 'Pro':
                priceId = 'price_1PqJxsLizOwtt0oftomYhKBN';
                planName = 'Pro';
                buttonText = currentSubscription === 'Pro' ? 'Current Plan' : (currentSubscription === 'Elite' || currentSubscription === 'Unlimited') ? 'Downgrade to Pro' : 'Upgrade to Pro';
                isDisabled = currentSubscription === 'Pro';
                features = [
                    'Everything in Basic',
                    'Create up to 10 Polls',
                    'Website Widget',
                    'Data Download CSV',
                    '20 PDF Reports per month',
                    'Remove Ads',
                    'reCaptcha',
                    'Scheduled Polls'
                ];
                break;
            case 'Elite':
                priceId = 'price_1PqJjXLizOwtt0ofZOACVC0s';
                planName = 'Elite';
                buttonText = currentSubscription === 'Elite' ? 'Current Plan' : currentSubscription === 'Unlimited' ? 'Downgrade to Elite' : 'Upgrade to Elite';
                isDisabled = currentSubscription === 'Elite';
                features = [
                    'Everything in Pro',
                    'Create up to 100 Polls',
                    '60 PDF Reports per month',
                    'Priority Support'
                ];
                break;
            case 'Unlimited':
                priceId = 'price_1PqJkfLizOwtt0ofYKn2eoT8';
                planName = 'Unlimited';
                buttonText = currentSubscription === 'Unlimited' ? 'Current Plan' : 'Upgrade to Unlimited';
                isDisabled = currentSubscription === 'Unlimited';
                features = [
                    'Everything in Elite',
                    'Unlimited Polls',
                    'Unlimited CSV downloads',
                    '120 PDF reports per month'
                ];
                break;
            case 'Basic':
            default:
                priceId = '';
                planName = 'Basic';
                buttonText = currentSubscription === 'Free' ? 'Current Plan' : 'Downgrade to Free';
                isDisabled = currentSubscription === 'Free';
                features = basicFeatures;
                break;
        }
    
        content = features.map((feature, index) => (
            <React.Fragment key={index}>
                <div className="pricing-divider"></div>
                <div className="pricing-feature">{feature}</div>
            </React.Fragment>
        ));
    
        return (
            <div className="pricing-card" key={plan}>
                <h2 className="plan-name">{planName}</h2>
                <p className="price-label">
                    {plan === 'Basic' ? 'Free' : `USD $${plan === 'Pro' ? '4.99' : plan === 'Elite' ? '14.99' : '24.99'}/month`}
                </p>

                <div>{content}</div>
                <button
                    className={`upgrade-button ${plan.toLowerCase()}`}
                    onClick={() => handleUpgrade(priceId, planName)}
                    disabled={isDisabled}
                >
                    {buttonText}
                </button>
            </div>
        );
    };

    const backgroundStyle = {
        backgroundImage: `url(${isDesktop ? DesktopBG : MobileBG})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        minHeight: '150vh',
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: -1,
    };

    return (
        <div>
            <div style={backgroundStyle}></div>
            <div className="pricing-tabs-container">
                <h1 className="pricing-title">Pricing</h1>
                <div className="pricing-tabs">
                    <button
                        className={`tab-button basic ${selectedPlan === 'Basic' ? 'active' : ''}`}
                        onClick={() => setSelectedPlan('Basic')}
                    >
                        Basic
                    </button>
                    <button
                        className={`tab-button pro ${selectedPlan === 'Pro' ? 'active' : ''}`}
                        onClick={() => setSelectedPlan('Pro')}
                    >
                        Pro
                    </button>
                    <button
                        className={`tab-button elite ${selectedPlan === 'Elite' ? 'active' : ''}`}
                        onClick={() => setSelectedPlan('Elite')}
                    >
                        Elite
                    </button>
                    <button
                        className={`tab-button unlimited ${selectedPlan === 'Unlimited' ? 'active' : ''}`}
                        onClick={() => setSelectedPlan('Unlimited')}
                    >
                        Unlimited
                    </button>
                </div>
                <div className="pricing-container">
                    {isDesktop
                        ? ['Basic', 'Pro', 'Elite', 'Unlimited'].map(plan => renderPlanCard(plan))
                        : renderPlanCard(selectedPlan)
                    }
                </div>
            </div>

           
        </div>
    );
};

export default Pricing;