// src/components/FollowerChicklet.js
import React from 'react';
import ProfileIcon from './ProfileIcon';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons'; // Using only filled bell icon
import './FollowerChicklet.css';
import logger from './logger';


const FollowerChicklet = ({ username, profileImageUrl, loggedInUsername, isFollowing, toggleFollow }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (username === loggedInUsername) {
      navigate('/profile');
    } else {
      navigate(`/profile/${username}`);
    }
  };

  const handleToggleFollow = (e) => {
    e.stopPropagation(); // Prevent navigating to profile when clicking the follow/unfollow button
    toggleFollow(username); // Call the toggleFollow function passed as a prop
  };

  return (
    <div className="FollowerChicklet" onClick={handleClick}>
      <div className="FollowerChicklet-image-container">
        <ProfileIcon firstName={username[0]} size={65} profileImageUrl={profileImageUrl} />
      </div>
      <h2 className="FollowerChicklet-username">{username}</h2>
      <button onClick={handleToggleFollow} className="follow-button">
        <FontAwesomeIcon icon={faBell} className={isFollowing ? 'icon-active' : 'icon-inactive'} />
      </button>
    </div>
  );
};

export default FollowerChicklet;
