import React, { useState } from 'react';
import './FilterTabs.css';

const FilterTabs = ({ options, onSelect }) => {
  const [selectedTab, setSelectedTab] = useState(0); // Initial selected tab

  const handleTabClick = (index) => {
    setSelectedTab(index);
    onSelect(options[index]); // Pass the selected option to the parent
  };

  return (
    <div className="modern-tabs-container">
      <div className="modern-tabs">
        {options.map((tab, index) => (
          <div
            key={index}
            className={`modern-tab ${selectedTab === index ? 'selected' : ''}`}
            onClick={() => handleTabClick(index)}
          >
            {tab}
          </div>
        ))}
        <div
          className="modern-tab-indicator"
          style={{
            width: `${100 / options.length}%`,
            transform: `translateX(${selectedTab * 100}%)`,
          }}
        />
      </div>
    </div>
  );
};

export default FilterTabs;
