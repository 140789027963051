import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom';
import './LiveDashboard.css';
import ColorBar from './ColorBar';
import ConfidenceBar from './ConfidenceBar';
import { hsvaToHslaString } from '@uiw/color-convert';
import { Chart } from 'react-google-charts';
import PollAreaChart from './PollAreaChart';  // Adjust the path as necessary
import QRCodeGeneratorDashboard from './QRCodeGeneratorDashboard';
import DashboardListItem from './DashboardListItem'; // Adjust the path as necessary
import logger from './logger';
import UpgradeModal from './UpgradeModal'; // Import the UpgradeModal component if needed
import Placeholder from '../assets/live-dashboard.png';





const LiveDashboard = ({ userId, username, userEmail }) => {
  const { hash } = useParams();
  const navigate = useNavigate();
  const [polls, setPolls] = useState([]);
  const [selectedPoll, setSelectedPoll] = useState(null);
  const [liveVotes, setLiveVotes] = useState([]);
  const [colorPercentages, setColorPercentages] = useState([]);
  const [counts, setCounts] = useState({});
  const [labels, setLabels] = useState([]);
  const [confidenceLabels, setConfidenceLabels] = useState([]);
  const [geoChartData, setGeoChartData] = useState([['Country', 'Votes']]);
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);
  const location = useLocation();
  const [isGeneratingReport, setIsGeneratingReport] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [loadingMessage, setLoadingMessage] = useState('Aggregating data...');
  const [showUpgradeModal, setShowUpgradeModal] = useState(false); // For showing upgrade modal


  const checkSubscriptionStatus = async (userId) => {
    try {
      const response = await fetch(`/auth/subscription-status/${userId}`);
      if (!response.ok) throw new Error('Failed to fetch subscription status');
      const data = await response.json();
      return data.subscriptionStatus;
    } catch (error) {
      logger.error('Error fetching subscription status:', error);
      return null;
    }
  };


  useEffect(() => {
    fetch(`/polls?creator=${userId}&limit=0&includePrivate=true`)
      .then((response) => response.json())
      .then((data) => {
        setPolls(data.polls || []);
        setLoading(false);

        if (hash) {
          const poll = data.polls?.find((poll) => poll.hash === hash);
          if (poll) {
            setSelectedPoll(poll._id);
          } else {
            logger.error('Poll not found');
          }
        }
      })
      .catch((error) => {
        logger.error('Error fetching user polls:', error);
        setLoading(false);
        setNoData(true);
      });
  }, [userId, hash]);

  useEffect(() => {
    if (selectedPoll === null) {
      // Fetch the list of polls to show when no poll is selected
      fetch(`/polls?creator=${userId}&limit=0&includePrivate=true`)
        .then(response => response.json())
        .then(data => {
          setPolls(data.polls || []);
          setLoading(false);
        })
        .catch(error => {
          logger.error('Error fetching user polls:', error);
          setLoading(false);
          setNoData(true);
        });
    } else {
      // Fetch the details for the selected poll
      const fetchLiveVotes = async () => {
        try {
          const response = await fetch(`/api/polls/${selectedPoll}/details`);
          if (response.ok) {
            const data = await response.json();
            setLiveVotes(data.votes || []);
            setLabels(data.poll.labels || []);
            setConfidenceLabels(data.poll.confidenceLabels || []);
            setColorPercentages(calculateColorPercentages(data.votes || [], data.poll.labels || []));
            setCounts(calculateCounts(data.votes || [], data.poll.labels || []));
            setGeoChartData([['Country', 'Votes'], ...getVotesByCountry(data.votes)]);
          } else {
            logger.error('Error fetching live votes:', response.statusText);
          }
        } catch (error) {
          logger.error('Error fetching live votes:', error);
        }
      };
      fetchLiveVotes();
    }
  }, [userId, selectedPoll]); // Depend on userId and selectedPoll
  

  useEffect(() => {
    let ws;
    let reconnectAttempts = 0;
    let isComponentMounted = true;

    const connectWebSocket = () => {
      const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
      ws = new WebSocket(`${protocol}://${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`);

      ws.onopen = () => {
        logger.log('Connected to WebSocket');
        reconnectAttempts = 0;
        if (selectedPoll) {
          ws.send(JSON.stringify({ type: 'subscribe', pollId: selectedPoll }));
        }
      };

      ws.onmessage = (event) => {
        if (!isComponentMounted) return;

        const message = JSON.parse(event.data);
        logger.log('WebSocket message received:', message);

        if (message.type === 'voteUpdate' && message.pollId === selectedPoll) {
          logger.log('Vote count update received:', message.voteCount);
        } else if (message.type === 'vote' && message.pollId === selectedPoll) {
          const newVote = message.vote;
          if (newVote && newVote.choice !== undefined && newVote.saturation !== undefined) {
            setLiveVotes((prevVotes) => {
              const updatedVotes = [...prevVotes, newVote];
              logger.log('Updated live votes:', updatedVotes); // Log for debugging
              setColorPercentages(calculateColorPercentages(updatedVotes, labels));
              setCounts(calculateCounts(updatedVotes, labels));
              setGeoChartData([['Country', 'Votes'], ...getVotesByCountry(updatedVotes)]);
              return updatedVotes;
            });
          } else {
            logger.error('Invalid vote data received from WebSocket:', newVote);
          }
        }
      };

      ws.onclose = () => {
        logger.log('Disconnected from WebSocket.');
        if (isComponentMounted) {
          const timeout = Math.min(1000 * Math.pow(2, reconnectAttempts), 30000); // Cap at 30 seconds
          logger.log(`Reconnecting to WebSocket in ${timeout / 1000} seconds...`);
          setTimeout(connectWebSocket, timeout);
          reconnectAttempts++;
        }
      };

      ws.onerror = (error) => {
        logger.error('WebSocket error:', error);
        ws.close();
      };
    };

    connectWebSocket();

    return () => {
      isComponentMounted = false;
      if (ws) {
        ws.close();
      }
    };
  }, [selectedPoll, labels]);

  const handlePollClick = (poll) => {
    setSelectedPoll(poll._id);
    navigate(`/dashboard/${poll.hash}`);
  };

  const handleBackClick = () => {
    // Default back URL
    let backURL = '/dashboard';  // Assume this is the default for the list of polls
  
    // Check if there's a navigation state and adapt accordingly
    if (location.state?.from === '/profile') {
      backURL = '/profile';
    } else {
      // Reset the selectedPoll to ensure the list is shown
      setSelectedPoll(null);
    }
  
    navigate(backURL, { replace: true });
  };
  
  

  const calculateColorPercentages = (votes, labels) => {
    if (!votes || !labels) return [];
    const totalVotes = votes.length;
    if (totalVotes === 0) return [];

    const colorCounts = votes.reduce((acc, vote) => {
      if (!vote.choice || !vote.saturation) {
        logger.error('Invalid vote data:', vote);
        return acc;
      }

      const color = hsvaToHslaString({ h: vote.choice, s: vote.saturation, v: 100, a: 1 });
      const classification = classifyColor(vote.choice, labels);

      if (!acc[classification]) {
        acc[classification] = [];
      }

      acc[classification].push({ color, confidence: vote.confidence, timestamp: vote.createdAt, opinion: vote.opinion });
      return acc;
    }, {});

    return [labels[2], labels[1], labels[0]].flatMap((label) => {
      const segments = colorCounts[label] || [];
      return segments.map((segment) => ({
        ...segment,
        percentage: (1 / totalVotes) * 100,
      }));
    });
  };

  const calculateCounts = (votes, labels) => {
    if (!votes || !labels) return { agree: 0, disagree: 0, neutral: 0 };
    return {
      agree: votes.filter((vote) => classifyColor(vote.choice, labels) === labels[0]).length,
      disagree: votes.filter((vote) => classifyColor(vote.choice, labels) === labels[2]).length,
      neutral: votes.filter((vote) => classifyColor(vote.choice, labels) === labels[1]).length,
    };
  };


  const handleCreateReportClick = async () => {
    // Check the subscription status
    const subscriptionStatus = await checkSubscriptionStatus(userId); // Pass userId to the function
  
    // Show the upgrade modal if the subscription is not sufficient
    if (!['Pro', 'Elite', 'Unlimited'].includes(subscriptionStatus)) {
      setShowUpgradeModal(true); // Show the upgrade modal
      return;
    }
  
    // Initialize the report generation process
    setIsGeneratingReport(true);
    setLoadingProgress(0);
    setLoadingMessage('Aggregating data...');
  
    // Define the steps for the progress bar and messages
    const loadingSteps = [
      { progress: 25, duration: 13000, message: 'Aggregating data...' },
      { progress: 50, duration: 28000, message: 'Generating AI Insights...' },
      { progress: 90, duration: 22000, message: 'Rendering Charts...' },
    ];
  
    // Function to animate the progress bar
    const animateProgressBar = async (steps, index = 0) => {
      if (index >= steps.length) {
        setLoadingMessage('Finishing up...');
        return;
      }
  
      const { progress, duration, message } = steps[index];
      setLoadingMessage(message);
  
      const startProgress = loadingProgress;
      const increment = (progress - startProgress) / (duration / 100);
  
      const interval = setInterval(() => {
        setLoadingProgress((prev) => {
          const newProgress = prev + increment;
          if (newProgress >= progress) {
            clearInterval(interval);
            setLoadingProgress(progress);
            setTimeout(() => animateProgressBar(steps, index + 1), 1000);
            return progress;
          }
          return newProgress;
        });
      }, 100);
    };
  
    await animateProgressBar(loadingSteps);
  
    // Prepare data for the report
    const totalVotes = liveVotes.length;
    const colorPercentages = calculateColorPercentages(liveVotes, labels);
    const confidencePercentages = [
      { label: labels[0], percentages: calculateConfidencePercentages(liveVotes, labels[0], confidenceColors[labels[0]]) },
      { label: labels[1], percentages: calculateConfidencePercentages(liveVotes, labels[1], confidenceColors[labels[1]]) },
      { label: labels[2], percentages: calculateConfidencePercentages(liveVotes, labels[2], confidenceColors[labels[2]]) }
    ];
  
    const data = {
      pollId: selectedPoll,
      question: polls.find(poll => poll._id === selectedPoll)?.question,
      date: new Date().toISOString().slice(0, 10),
      username: username,
      userEmail: userEmail,  // Include the user's email here
      choices: labels.map(label => ({
        name: label,
        votes: liveVotes.filter(vote => classifyColor(vote.choice, labels) === label).length,
        percentage: totalVotes ? ((liveVotes.filter(vote => classifyColor(vote.choice, labels) === label).length / totalVotes) * 100).toFixed(2) : "0.00"
      })),
      colorPercentages: colorPercentages,
      counts: {
        agree: liveVotes.filter(vote => classifyColor(vote.choice, labels) === labels[0]).length,
        disagree: liveVotes.filter(vote => classifyColor(vote.choice, labels) === labels[2]).length,
        neutral: liveVotes.filter(vote => classifyColor(vote.choice, labels) === labels[1]).length
      },
      labels: labels,
      confidencePercentages: confidencePercentages,
      votes: liveVotes, // Ensure votes are included here
      confidenceLabels: confidenceLabels // Add confidenceLabels here
    };
  
    // Log the data before sending the request
    logger.log('Data sent to PDF generation server:', data);
  
    try {
      const response = await fetch(`${process.env.REACT_APP_PDF_GENERATION_URL}/generate-pdf`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      });
  
      if (!response.ok) throw new Error('Failed to generate report');
      
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = 'Poll_Report.pdf';
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      logger.error('Error generating report:', error);
    } finally {
      setLoadingProgress(100);
      setLoadingMessage('Finishing up...');
      setTimeout(() => {
        setIsGeneratingReport(false);
        setLoadingMessage('Aggregating data...');
        setLoadingProgress(0);
      }, 500);
    }
  };
  

  const classifyColor = (hue, labels) => {
    if (hue >= 60 && hue <= 180) {
      return labels[0];
    } else if (hue > 300 || hue <= 60) {
      return labels[2];
    } else if (hue > 180 && hue <= 300) {
      return labels[1];
    } else {
      return 'Mixed Feelings';
    }
  };

  const getVotesByCountry = (votes) => {
    const countryCounts = {};
    for (const vote of votes) {
      const countryCode = vote.country;
      if (countryCode) {
        countryCounts[countryCode] = (countryCounts[countryCode] || 0) + 1;
      }
    }
    return Object.entries(countryCounts).map(([country, count]) => [country, count]);
  };

  const calculateConfidencePercentages = (votesArray, classification, baseColor) => {
    const filteredVotes = votesArray.filter(
      (vote) => classifyColor(vote.choice, labels) === classification
    );
  
    const confidenceCounts = filteredVotes.reduce((acc, vote) => {
      if (!acc[vote.confidence]) {
        acc[vote.confidence] = 0;
      }
      acc[vote.confidence]++;
      return acc;
    }, {});
  
    const totalVotes = filteredVotes.length;
    const confidencePercentages = [];
  
    // Determine which confidence labels to use for the current classification
    const classificationIndex = labels.indexOf(classification);
    const classificationConfidenceLabels =
      confidenceLabels[classificationIndex] || ['Very Low', 'Low', 'Medium', 'High', 'Very High'];
    const numConfidenceLevels = classificationConfidenceLabels.length;
  
    for (let i = 0; i < numConfidenceLevels; i++) {
      const count = confidenceCounts[i] || 0;
      const percentage = totalVotes ? ((count / totalVotes) * 100).toFixed(1) : 0;
      if (percentage > 0) {
        const adjustedColor = computeColor(baseColor.h, i, numConfidenceLevels);
        confidencePercentages.push({
          count,
          percentage,
          color: adjustedColor,
          label: classificationConfidenceLabels[i],
        });
      }
    }
  
    return confidencePercentages;
  };

  const computeColor = (baseHue, confidenceIndex, totalConfidenceLevels) => {
    const saturation = ((confidenceIndex + 1) / totalConfidenceLevels) * 100;
    return hsvaToHslaString({ h: baseHue, s: saturation, v: 100, a: 1 });
  };
  
  const adjustGreenSaturation = (confidenceLevel) => {
    switch (confidenceLevel) {
      case 4:
        return 'rgb(0, 255, 0)'; // Very High
      case 3:
        return 'rgb(90, 255, 90)'; // High
      case 2:
        return 'rgb(140, 255, 140)'; // Medium
      case 1:
        return 'rgb(190, 255, 190)'; // Low
      case 0:
        return 'rgb(220, 255, 220)'; // Very Low
      default:
        return 'rgb(0, 255, 0)'; // Default green if unknown level
    }
  };
  

  const confidenceColors = {
    [labels[0]]: { h: 120, s: 100, v: 100, a: 1 }, // Green for first label
    [labels[1]]: { h: 240, s: 100, v: 100, a: 1 }, // Blue for second label
    [labels[2]]: { h: 0, s: 100, v: 100, a: 1 },   // Red for third label
  };

  

  return (
    <div>
      <div className="dashboard-header">
        {selectedPoll && (
          <button className="back-button" onClick={handleBackClick}>
            ←
          </button>
        )}
      </div>
      <div className="live-dashboard">
      {loading ? (
  <div className="dashboard-list-card">
    <p>Loading...</p>
  </div>
) : !selectedPoll ? (
  <div className="dashboard-list-card poll-list-card">
    <h1 className="dashboard-title">Live Dashboard</h1>
    {polls.length === 0 ? (
      <>
        <p className="dashboard-subtitle">Create a Poll to view Live Stats</p>
        <Link to="/create-poll" class="profile-placeholder-add-button">+</Link>

        {/* Placeholder image */}
        <img src={Placeholder} alt="Create a poll placeholder" className="placeholder-image" />
      </>
    ) : (
      <>
        <p className="dashboard-subtitle">Select a poll to view live stats</p>
        <div className="dashboard-poll-list">
          {polls.map((poll) => (
            <DashboardListItem
              key={poll._id}
              pollId={poll._id}
              question={poll.question}
              labels={poll.labels}
              logo={poll.logo}
              logoFill={poll.logoFill}
              hash={poll.hash}
              isLoggedIn={!!userId}
              userId={userId}
              creatorId={poll.creatorId}
              creatorUsername={poll.creatorUsername}
              username={username}
              isProfileView={false}
              voteAuthenticationMethod={poll.voteAuthenticationMethod}
              confidenceLabels={poll.confidenceLabels}
              onPollClick={() => handlePollClick(poll)}
              imageAction={poll.imageAction}
              roundedCorners={poll.roundedCorners}
              startDate={poll.startDate}
              endDate={poll.endDate}
              scheduledPoll={poll.scheduledPoll}
              pollActive={poll.pollActive}
            />
          ))}
        </div>
      </>
    )}
  </div>
) : (
          <>
            <div className="top-row-container">
              <div className="stats-cards">
                <div className="dashboard-card stats-card">
                  <div className="pulse-dot"></div>
                  <h2 className="dashboard-poll-question">{polls.find(poll => poll._id === selectedPoll)?.question}</h2>
                  <div className="vote-summary">
                    <h2>Total Votes: {liveVotes.length}</h2>
                    <p>
                      {counts.agree} {labels[0]}, {counts.neutral} {labels[1]}, {counts.disagree} {labels[2]}
                    </p>
                  </div>
                  <ColorBar colorPercentages={colorPercentages} counts={counts} labels={labels} showConfidenceLabels={true} />
                </div>
                <div className="dashboard-card stats-card">
                <div className="confidence-bars">
  {labels.map((label, index) => {
    const confidencePercentages = calculateConfidencePercentages(
      liveVotes,
      label,
      confidenceColors[label]
    );
    return (
      confidencePercentages.length > 0 && (
        <React.Fragment key={index}>
          <h1 className="confidence-label">{label}</h1>
          <ConfidenceBar
            confidencePercentages={confidencePercentages}
            className="confidence-bar"
            confidenceLabels={confidenceLabels[index]} // Pass the appropriate confidence labels
          />
        </React.Fragment>
      )
    );
  })}
</div>
                </div>
              </div>
              <div className="qr-card-container">
                <div className="qr-card">
                  <div className="qr-code-card">
                    <QRCodeGeneratorDashboard pollId={selectedPoll} hash={polls.find(poll => poll._id === selectedPoll)?.hash} />
                  </div>
                </div>

                <div className="qr-card">
                  <div className="report-card">
                    <button
                      onClick={handleCreateReportClick}
                      className="dashboard-tools-button"
                      disabled={isGeneratingReport}
                    >
                      {isGeneratingReport ? loadingMessage : 'Create Data Report'}
                    </button>
                    {isGeneratingReport && (
          <div>
          <div className="loading-bar-container">
            <div className="loading-bar" style={{ width: `${loadingProgress}%` }}></div>
          
          </div>
          <p>   You will recieve an email when your report is ready. </p>
          </div>
        )}
                  </div>
                </div>

                


              </div>
            </div>
            
            <div className="dashboard-card full">
              <PollAreaChart votes={liveVotes} labels={labels} />
            </div>
            <div className="dashboard-card full">
              <Chart
                width={'100%'}
                height={'100%'}
                chartType="GeoChart"
                data={geoChartData}
                options={{
                  colorAxis: { colors: ['#daffda', '#00FF00'] },
                  backgroundColor: '#212121',
                  datalessRegionColor: '#555',
                  defaultColor: '#212121',
                  legend: 'none',
                  fill: '#212121'
                }}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
  





};

export default LiveDashboard;
