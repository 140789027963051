import React, { useState, useEffect } from 'react';
import './ProfileSettings.css';
import logger from './logger';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faChevronDown, faChevronUp, faTrash } from '@fortawesome/free-solid-svg-icons';

const ProfileSettings = ({ handleLogout }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userId, currentBio } = location.state || {};

  const [bio, setBio] = useState(currentBio);
  const [subscriptionStatus, setSubscriptionStatus] = useState('Free');
  const [error, setError] = useState('');
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [receivePollEmails, setReceivePollEmails] = useState(true);
  const [receiveSubscriberEmails, setReceiveSubscriberEmails] = useState(true);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showFinalDeleteConfirm, setShowFinalDeleteConfirm] = useState(false);

  useEffect(() => {
    if (userId) {
      fetch(`/auth/user-preferences/${userId}`)
        .then(response => response.json())
        .then(data => {
          if (data.subscriptionStatus) setSubscriptionStatus(data.subscriptionStatus);
          if (typeof data.sendPollEmails === 'boolean') setReceivePollEmails(data.sendPollEmails);
          if (typeof data.sendSubscriberEmails === 'boolean') setReceiveSubscriberEmails(data.sendSubscriberEmails);
        })
        .catch(error => logger.error('Error fetching user preferences:', error));
    }
  }, [userId]);

  const handleSave = (e) => {
    e.preventDefault();
    fetch('/auth/update', {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        userId,
        bio,
        sendPollEmails: receivePollEmails,
        sendSubscriberEmails: receiveSubscriberEmails
      })
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          navigate('/profile');
        } else {
          setError(data.error || 'Failed to update profile settings');
        }
      })
      .catch(error => {
        logger.error('Error updating profile settings:', error);
        setError('Failed to update profile settings');
      });
  };

  const handleDeleteAccount = () => {
    // First confirmation prompt
    const confirmDelete = window.confirm('Are you sure you want to delete your profile?');
    
    if (confirmDelete) {
      // Second final confirmation prompt
      const finalConfirmDelete = window.confirm('This action is not undoable. All your polls and data will be deleted. Are you sure?');
      
      if (finalConfirmDelete) {
        handleFinalDelete();
      }
    }
  };
  
  const handleFinalDelete = () => {
    // Call the backend to delete the profile
    fetch(`/auth/delete-profile/${userId}`, { method: 'DELETE' })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          handleLogout();  // Log out user after deletion
          navigate('/');    // Navigate to homepage
        } else {
          setError(data.error || 'Failed to delete profile');
        }
      })
      .catch(error => {
        logger.error('Error deleting profile:', error);
        setError('Failed to delete profile');
      });
  };
  

  return (
    <div className="profile-settings">
      <div className="profile-settings__header">
        <button className="profile-settings__back-button" onClick={() => navigate('/profile')}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
        <h2>Profile Settings</h2>
      </div>
      
      <form onSubmit={handleSave} className="profile-settings__form">
        <div className="profile-settings__section">
          <label htmlFor="bio">Bio</label>
          <textarea
            id="bio"
            placeholder="Tell us about yourself"
            value={bio}
            onChange={(e) => setBio(e.target.value)}
            rows={4}
            required
          />
        </div>
        
        <div className="profile-settings__section">
          <label htmlFor="subscription">Subscription Plan</label>
          <div id="subscription" className="profile-settings__subscription">
            <span>Current Plan: {subscriptionStatus}</span>
            <button type="button" onClick={() => window.location.href = '/pricing'}>Change Plan</button>
          </div>
        </div>

        {error && <p className="profile-settings__error">{error}</p>}

        <div className="profile-settings__section">
          <button type="button" className="profile-settings__advanced-toggle" onClick={() => setShowAdvanced(!showAdvanced)}>
            Advanced Settings
            <FontAwesomeIcon icon={showAdvanced ? faChevronUp : faChevronDown} />
          </button>

          {showAdvanced && (
            <div className="profile-settings__advanced-options">
              <label>
                <input
                  type="checkbox"
                  checked={receivePollEmails}
                  onChange={() => setReceivePollEmails(!receivePollEmails)}
                />
                Receive Emails for Relevant Polls
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={receiveSubscriberEmails}
                  onChange={() => setReceiveSubscriberEmails(!receiveSubscriberEmails)}
                />
                Receive Subscriber Emails
              </label>
              <button
  type="button"
  className="profile-settings__delete-account"
  onClick={handleDeleteAccount} // Update this to use the handleDeleteAccount function
>
  Delete Account
</button>
            </div>
          )}
        </div>
        
        <button type="submit" className="profile-settings__save-button">Save</button>
      </form>

      <button className="profile-settings__logout-button" onClick={handleLogout}>Logout</button>

      
    </div>
  );
};

export default ProfileSettings;
