import React, { useState, useEffect, useRef } from 'react';
import imageCompression from 'browser-image-compression';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './CreatePollModal.css';
import UpgradeModal from './UpgradeModal'; // Import the UpgradeModal component
import logger from './logger';


const CreatePollModal = ({ isLoggedIn, userId, username, onClose, onCreate }) => {
  const [newPollQuestion, setNewPollQuestion] = useState('');
  const [newPollLabels, setNewPollLabels] = useState(["Agree", "Neutral", "Disagree"]); // Default selection
  const [newPollCategory, setNewPollCategory] = useState('Technology');
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [redirectLink, setRedirectLink] = useState('');
  const [redirectMessage, setRedirectMessage] = useState('');
  const [redirectMessageError, setRedirectMessageError] = useState('');
  const [showExtraOptions, setShowExtraOptions] = useState(false);
  const [voteAuthenticationMethod, setVoteAuthenticationMethod] = useState(1); // Default to one vote per browser session
  const [pollQuestionError, setPollQuestionError] = useState('');
  const [useCustomLabels, setUseCustomLabels] = useState(false); // State for custom labels checkbox
  const [customLabels, setCustomLabels] = useState(["", "", ""]); // State for custom labels inputs
  const [customConfidenceLabels, setCustomConfidenceLabels] = useState(["", "", "", "", ""]); // State for custom confidence labels inputs
  const [visibility, setVisibility] = useState('Public'); // New state for visibility
  const [logo, setLogo] = useState(null); // State for the uploaded logo
  const [tempLogoUrl, setTempLogoUrl] = useState('/logo.png'); // Default logo path
  const textareaRef = useRef(null);
  const [fillBackground, setFillBackground] = useState(false); // State for background fill
  const [isLoading, setIsLoading] = useState(false); // State for loading screen
  const navigate = useNavigate(); // Initialize useNavigate
  const [subscriptionStatus, setSubscriptionStatus] = useState('Free'); // State for subscription status
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [imageAction, setImageAction] = useState(0); // 0 for "Link to Poll", 1 for "Expand Image"
  const [roundCorners, setRoundCorners] = useState(false); // State for round corners


  const pollOptions = [
    ["Yes", "Maybe", "No"],
    ["Agree", "Neutral", "Disagree"],
    ["Positive", "Neutral", "Negative"],
    ["Approve", "Indifferent", "Disapprove"],
    ["Good", "Average", "Bad"],
    ["Support", "Neutral", "Oppose"],
    ["True", "Unsure", "False"],
    ["High", "Medium", "Low"],
    ["Like", "Neutral", "Dislike"],
    ["Happy", "Neutral", "Unhappy"],
    ["Satisfied", "Neutral", "Unsatisfied"]
  ];

  // Mapping of opinion labels to corresponding confidence labels
  const confidenceMapping = {
    "Yes, Maybe, No": ["Weak", "Unsure", "Moderate", "Strong", "Confident"],
    "Agree, Neutral, Disagree": ["Marginally", "Slightly", "Somewhat", "Moderately", "Strongly"],
    "Positive, Neutral, Negative": ["Marginally", "Slightly", "Moderately", "Strongly", "Extremely"],
    "Approve, Indifferent, Disapprove": ["Minimally", "Slightly", "Somewhat", "Moderately", "Strongly"],
    "Good, Average, Bad": ["Marginally", "Slightly", "Somewhat", "Kinda", "Very"],
    "Support, Neutral, Oppose": ["Marginally", "Slightly", "Somewhat", "Moderately", "Strongly"],
    "High, Medium, Low": ["Marginally", "Slightly", "Somewhat", "Moderately", "Extremely"],
    "Like, Neutral, Dislike": ["Marginally", "Slightly", "Somewhat", "Moderately", "Strongly"],
    "Happy, Neutral, Unhappy": ["Marginally", "Slightly", "Somewhat", "Moderately", "Extremely"],
    "Satisfied, Neutral, Unsatisfied": ["Marginally", "Slightly", "Somewhat", "Kinda", "Very"],
    "True, Unsure, False": ["Marginally", "Slightly", "Somewhat", "Kinda", "Very"]
  };



  const categories = [
    'Technology',
    'Health',
    'Education',
    'Entertainment',
    'Politics',
    'Sports',
    'Lifestyle',
    'Environment',
    'Fashion',
    'Services',
    'Products',
    'Travel',
    'Food',
    'Business'
  ];

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [newPollQuestion]);

  useEffect(() => {
    const checkSubscriptionStatus = async () => {
      try {
        const response = await fetch(`/auth/subscription-status/${userId}`);
        const data = await response.json();
        setSubscriptionStatus(data.subscriptionStatus);
      } catch (error) {
        logger.error('Error checking subscription status:', error);
      }
    };
  
    checkSubscriptionStatus();
  }, [userId]);

  useEffect(() => {
    if (!useCustomLabels) {
      const selectedLabels = newPollLabels.join(', ');
      const correspondingConfidenceLabels = confidenceMapping[selectedLabels];
      if (correspondingConfidenceLabels) {
        setCustomConfidenceLabels(correspondingConfidenceLabels);
      } else {
        // Fallback to default confidence labels if mapping is not found
        setCustomConfidenceLabels(["Very Low", "Low", "Medium", "High", "Very High"]);
      }
    }
  }, [newPollLabels, useCustomLabels]);

  // Function to fetch poll count and check against subscription limits
  const canCreatePoll = async () => {
    const response = await fetch(`/auth/poll-count/${userId}`);
    const data = await response.json();
    if (!response.ok) {
      throw new Error('Failed to fetch poll count');
    }
    
    const limits = { Free: 2, Pro: 10, Elite: 100, Unlimited: 1000 };
    return data.pollCount < limits[subscriptionStatus];
  };

  const handleCreatePoll = async () => {
    if (!isLoggedIn) {
      alert('Please sign up or log in to create a poll.');
      return;
    }
  
    if (!newPollQuestion.trim()) {
      setPollQuestionError('Poll question is required.');
      return;
    } else {
      setPollQuestionError('');
    }
  
    const allowedToCreate = await canCreatePoll();
    if (!allowedToCreate) {
      setShowUpgradeModal(true);
      return;
    }
  
    if (redirectMessage.length > 70) {
      setRedirectMessageError('Redirect message cannot exceed 70 characters.');
      return;
    }
  
    let formattedRedirectLink = redirectLink;
    if (redirectLink && !redirectLink.startsWith('http://') && !redirectLink.startsWith('https://')) {
      formattedRedirectLink = `https://${redirectLink}`;
    }
  
    let finalLabels = useCustomLabels ? customLabels.map(label => label.trim()) : newPollLabels;
    if (useCustomLabels && finalLabels.every(label => label === "")) {
      finalLabels = ["Green", "Blue", "Red"];
    }
  
    // Use the automatically set custom confidence labels
    let finalConfidenceLabels = customConfidenceLabels.map(label => label.trim());
    if (finalConfidenceLabels.every(label => label === "")) {
      finalConfidenceLabels = ["Very Low", "Low", "Medium", "High", "Very High"];
    }
  
    const formData = new FormData();
    formData.append('question', newPollQuestion);
    formData.append('labels', JSON.stringify(finalLabels));
    formData.append('confidenceLabels', JSON.stringify(finalConfidenceLabels));
    formData.append('category', newPollCategory);
    formData.append('creator', userId);
    formData.append('creatorUsername', username);
    formData.append('redirectLink', formattedRedirectLink);
    formData.append('redirectMessage', redirectMessage);
    formData.append('voteAuthenticationMethod', voteAuthenticationMethod);
    formData.append('visibility', visibility);
    formData.append('logoFill', fillBackground);
    formData.append('imageAction', imageAction);
    formData.append('roundedCorners', roundCorners);
    if (logo) {
      formData.append('logo', logo);
    }
  
    setIsLoading(true);
  
    fetch('/polls', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: formData
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        onCreate(data);
        setNewPollQuestion('');
        setNewPollLabels(["Agree", "Neutral", "Disagree"]);
        setNewPollCategory('Technology');
        setRedirectLink('');
        setRedirectMessage('');
        setTempLogoUrl('/logo.png');
        setLogo(null);
        onClose();
        document.body.style.overflow = 'auto';
        navigate('/profile', { state: { pollLink: data.pollUrl } });
      })
      .catch(error => logger.error('Error adding poll:', error))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCustomLabelChange = (index, value) => {
    const updatedLabels = [...customLabels];
    updatedLabels[index] = value;
    setCustomLabels(updatedLabels);
  };

  const handleCustomConfidenceLabelChange = (index, value) => {
    const updatedLabels = [...customConfidenceLabels];
    updatedLabels[index] = value;
    setCustomConfidenceLabels(updatedLabels);
  };

  const handleImageUpload = async (e) => {
    if (subscriptionStatus === 'Free') {
      setShowUpgradeModal(true);
      return;
    }
  
    const file = e.target.files[0];
    if (!file) {
      return;
    }
  
    // Compress and resize the image
    const options = {
      maxSizeMB: 0.4,
      maxWidthOrHeight: 1000,
      useWebWorker: true,
    };
  
    try {
      const compressedFile = await imageCompression(file, options);
      setLogo(compressedFile);
  
      const reader = new FileReader();
      reader.onloadend = () => {
        setTempLogoUrl(reader.result);
      };
      reader.readAsDataURL(compressedFile);
    } catch (error) {
      logger.error('Error compressing image:', error);
    }
  };

  return (
    <div className="modal-overlay">
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-content">
            <div className="loading-text">Creating Poll</div>
            <div className="loading-spinner"></div>
          </div>
        </div>
      )}
  
      {showUpgradeModal && (
        <UpgradeModal onClose={() => setShowUpgradeModal(false)} />
      )}
      <div className="modal-content">
        <h2 className="modal-title">Create Poll</h2>
        <div className="image-upload-container">
          <div className={`logo-background ${fillBackground ? 'filled' : ''}`}>
            <img
              src={tempLogoUrl}
              alt="Poll Logo"
              className="poll-logo"
              onClick={() => document.getElementById('logo-upload').click()}
              style={{
                borderRadius: roundCorners ? '10px' : '0px', // Apply border-radius if roundCorners is checked
              }}
            />
          </div>
          <button
            type="button"
            className="upload-logo-button"
            onClick={() => document.getElementById('logo-upload').click()}
          >
            Upload Custom Logo
          </button>
          <input
            type="file"
            id="logo-upload"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={handleImageUpload}
          />
        </div>

  
        {logo && ( // Conditionally render the section if a logo is uploaded
          <div className="image-options">
            <h3 className="image-options-title">Image Options</h3> {/* Unique class name */}
            <div className="custom-checkbox">
            <input
              type="checkbox"
              id="roundCorners"
              checked={roundCorners}
              onChange={(e) => {
                if (e.target.checked) {
                  setRoundCorners(true);
                  setFillBackground(false);
                } else {
                  setRoundCorners(false);
                }
              }}
            />
            <label htmlFor="roundCorners">Round Corners</label>
          </div>

            <div className="custom-checkbox">
            <input
              type="checkbox"
              id="fillBackground"
              checked={fillBackground}
              onChange={(e) => {
                if (e.target.checked) {
                  setRoundCorners(false); // Uncheck Round Corners when Fill Background is checked
                }
                setFillBackground(e.target.checked);
              }}
            />
            <label htmlFor="fillBackground">Fill Background</label>
          </div>
            <h4 className="image-action-title">Image Action</h4> {/* Unique class name */}
            <div className="radio-group">
            <label className="radio-label">
              <input
                type="radio"
                name="imageAction"
                value={0}
                checked={imageAction === 0}
                onChange={() => setImageAction(0)}
              />
              Link to Poll
            </label>
            <label className="radio-label">
              <input
                type="radio"
                name="imageAction"
                value={1}
                checked={imageAction === 1}
                onChange={() => setImageAction(1)}
              />
              Expand Image
            </label>
          </div>
          </div>
        )}
  
        <textarea
          placeholder="Enter poll question..."
          value={newPollQuestion}
          onChange={(e) => setNewPollQuestion(e.target.value)}
          className="poll-question-input"
          rows={newPollQuestion.split('\n').length || 1} // Adjust the number of rows based on content
        />
        {pollQuestionError && <p className="error-message">{pollQuestionError}</p>}
        <div className="poll-options">
          <div className="custom-checkbox">
            <input
              type="checkbox"
              id="useCustomLabels"
              checked={useCustomLabels}
              onChange={(e) => {
                if (subscriptionStatus === 'Free') {
                  setShowUpgradeModal(true);
                } else {
                  setUseCustomLabels(e.target.checked);
                }
              }}
            />
            <label htmlFor="useCustomLabels">Use custom answers</label>
          </div>
          {useCustomLabels && (
            <h3 className="opinion-labels-title">Primary Answers</h3>
          )}
          {!useCustomLabels && pollOptions.slice(0, 3).map((option, index) => (
            <label key={index} className="radio-label">
              <input
                type="radio"
                name="pollLabels"
                value={option}
                checked={newPollLabels.join(',') === option.join(',')}
                onChange={() => setNewPollLabels(option)}
                className="radio-input"
              />
              {option.join(', ')}
            </label>
          ))}
          {!useCustomLabels && showMoreOptions && pollOptions.slice(3).map((option, index) => (
            <label key={index + 3} className="radio-label">
              <input
                type="radio"
                name="pollLabels"
                value={option}
                checked={newPollLabels.join(',') === option.join(',')}
                onChange={() => setNewPollLabels(option)}
                className="radio-input"
              />
              {option.join(', ')}
            </label>
          ))}
          {!useCustomLabels && (
            <div
              className="show-more-toggle"
              onClick={() => setShowMoreOptions(!showMoreOptions)}
            >
              {showMoreOptions ? "▲ Hide answers" : "▼ Show more answers"}
            </div>
          )}
          {useCustomLabels && (
            <div className="custom-labels-inputs">
              <input
                type="text"
                value={customLabels[0]}
                onChange={(e) => handleCustomLabelChange(0, e.target.value)}
                placeholder="Green"
              />
              <input
                type="text"
                value={customLabels[1]}
                onChange={(e) => handleCustomLabelChange(1, e.target.value)}
                placeholder="Blue"
              />
              <input
                type="text"
                value={customLabels[2]}
                onChange={(e) => handleCustomLabelChange(2, e.target.value)}
                placeholder="Red"
              />
            </div>
          )}
        </div>
        {useCustomLabels && (
          <div className="confidence-labels">
            <h3 className="confidence-labels-title">Confidence Levels</h3>
            <div className="custom-confidence-labels-inputs">
              <input
                type="text"
                value={customConfidenceLabels[4]}
                onChange={(e) => handleCustomConfidenceLabelChange(4, e.target.value)}
                placeholder="Very High"
              />
              <input
                type="text"
                value={customConfidenceLabels[3]}
                onChange={(e) => handleCustomConfidenceLabelChange(3, e.target.value)}
                placeholder="High"
              />
              <input
                type="text"
                value={customConfidenceLabels[2]}
                onChange={(e) => handleCustomConfidenceLabelChange(2, e.target.value)}
                placeholder="Medium"
              />
              <input
                type="text"
                value={customConfidenceLabels[1]}
                onChange={(e) => handleCustomConfidenceLabelChange(1, e.target.value)}
                placeholder="Low"
              />
              <input
                type="text"
                value={customConfidenceLabels[0]}
                onChange={(e) => handleCustomConfidenceLabelChange(0, e.target.value)}
                placeholder="Very Low"
              />
            </div>
          </div>
        )}
        <div className="poll-category">
          <label>
            Category
            <select
              value={newPollCategory}
              onChange={(e) => setNewPollCategory(e.target.value)}
              className="category-select"
            >
              {categories.map((category, index) => (
                <option key={index} value={category}>{category}</option>
              ))}
            </select>
          </label>
        </div>
        <div className="poll-auth-method">
          <label>
            Voting Rules
            <select
              value={voteAuthenticationMethod}
              onChange={(e) => setVoteAuthenticationMethod(parseInt(e.target.value))}
              className="auth-method-select"
            >
              <option value={1}>One vote per browser session</option>
              <option value={2} disabled>One vote per IP address</option> {/* Disable this option */}
              <option value={0}>Allow multiple votes per person</option>
            </select>
          </label>
        </div>
        <div className="poll-visibility">
          <label>
            Visibility
            <select
              value={visibility}
              onChange={(e) => setVisibility(e.target.value)}
              className="visibility-select"
            >
              <option value="Public">Public</option>
              <option value="Private">Private</option>
            </select>
          </label>
        </div>
        <div
          className="extra-options-toggle"
          onClick={() => setShowExtraOptions(!showExtraOptions)}
        >
          {showExtraOptions ? '▲ Hide extra options' : '▼ Show extra options'}
        </div>
        {showExtraOptions && (
          <div className="extra-options">
            <div className="poll-create">
              <label>
                Redirect After Vote
                <input
                  type="url"
                  value={redirectLink}
                  onFocus={(e) => {
                    if (subscriptionStatus === 'Free') {
                      e.target.blur(); // Prevent focusing on the input
                      setShowUpgradeModal(true);
                    }
                  }}
                  onChange={(e) => {
                    if (subscriptionStatus !== 'Free') {
                      setRedirectLink(e.target.value);
                    }
                  }}
                  placeholder="https://example.com"
                  className="redirect-input"
                />
              </label>
            </div>
            <div className="poll-create">
              <label>
                Message
                <textarea
                  value={redirectMessage}
                  onFocus={(e) => {
                    if (subscriptionStatus === 'Free') {
                      e.target.blur(); // Prevent focusing on the textarea
                      setShowUpgradeModal(true);
                    }
                  }}
                  onChange={(e) => {
                    if (subscriptionStatus !== 'Free') {
                      if (e.target.value.length <= 70) {
                        setRedirectMessage(e.target.value);
                        setRedirectMessageError('');
                      }
                    }
                  }}
                  placeholder="Check out our website!"
                  className="redirect-message"
                />
                {redirectMessageError && <p className="error-message">{redirectMessageError}</p>}
              </label>
            </div>
          </div>
        )}
  
        <div className="modal-buttons modal-buttons-stacked">
          <button className="create-button" onClick={handleCreatePoll}>Create Poll!</button>
          <button className="cancel-button" onClick={onClose}>Cancel</button>
        </div>
      </div>
    </div>
  );
  
};

export default CreatePollModal;
