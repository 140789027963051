import React from 'react';
import ProfileIcon from './ProfileIcon';
import { useNavigate } from 'react-router-dom';
import './ProfileChicklet.css';
import '../App.css';
import logger from './logger';


const ProfileChicklet = ({ username, firstName, profileImageUrl, bio, pollCount, loggedInUsername }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (username === loggedInUsername) {
      // Navigate to the logged-in user's profile page
      navigate('/profile');
    } else {
      // Navigate to the public profile page of the clicked user
      navigate(`/profile/${username}`);
    }
  };

  return (
    <div className="ProfileChicklet" onClick={handleClick}>
      <div className="ProfileChicklet-image-container">
        <ProfileIcon firstName={firstName} size={65} profileImageUrl={profileImageUrl} />
      </div>
      <div className="ProfileChicklet-details">
        <h2 className="ProfileChicklet-username">{username}</h2>
        <p className="ProfileChicklet-pollCount">{pollCount} {pollCount === 1 ? 'Poll' : 'Polls'}</p>
        <p className="ProfileChicklet-bio">{bio}</p>
      </div>
    </div>
  );
};

export default ProfileChicklet;
