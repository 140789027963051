import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Poll from './Poll';
import ProfileIcon from './ProfileIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { faBell as faBellRegular } from '@fortawesome/free-regular-svg-icons';
import { faShareSquare } from '@fortawesome/free-regular-svg-icons';
import '../App.css';
import logger from './logger';


const PublicProfile = () => {
  const { username } = useParams();
  const [polls, setPolls] = useState([]);
  const [bio, setBio] = useState('');
  const [firstName, setFirstName] = useState('');
  const [profileImageUrl, setProfileImageUrl] = useState('');
  const [totalVotes, setTotalVotes] = useState(0);
  const [impressions, setImpressions] = useState(0);
  const [profileUserId, setProfileUserId] = useState(null); // State to hold the profile user ID
  const [isFollowing, setIsFollowing] = useState(false); // State to check if following
  const [loggedInUser, setLoggedInUser] = useState(null); // State to hold the logged-in user info
  const [filterTab, setFilterTab] = useState('active'); // New state for active/scheduled filter

const handleFilterTabChange = (filter) => {
  setFilterTab(filter);
};

const filteredPolls = polls.filter(poll => {
  if (filterTab === 'active') {
    return poll.pollActive;
  } else if (filterTab === 'scheduled') {
    return !poll.pollActive && poll.scheduledPoll;
  }
  return true;
});



  useEffect(() => {
    // Fetch logged-in user info
    fetch('/auth/me', {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(response => response.json())
      .then(data => {
        logger.log('Logged-in user data:', data);
        setLoggedInUser(data);
      })
      .catch(error => logger.error('Error fetching logged-in user info:', error));

    logger.log('Fetching public profile for username:', username);
    fetch(`/auth/public/${username}`)
      .then(response => response.json())
      .then(data => {
        logger.log('Fetched public profile data:', data);
        setBio(data.bio);
        setFirstName(data.firstName);
        setProfileImageUrl(data.profileImageUrl);
        setProfileUserId(data.userId); // Set userId from the profile data
        fetch(`/polls?creator=${data.userId}&limit=0&includePrivate=false&sort=createdAtDesc`)
          .then(response => response.json())
          .then(data => {
            logger.log('Fetched user polls:', data.polls);
            setPolls(data.polls);
            const totalVotesCount = data.polls.reduce((acc, poll) => acc + poll.totalVotes, 0);
            setTotalVotes(totalVotesCount);

            let totalImpressions = 0;
            data.polls.forEach(poll => {
              fetch(`/api/get-impressions/${poll._id}`)
                .then(response => response.json())
                .then(impressionData => {
                  totalImpressions += impressionData.impressions;
                  setImpressions(totalImpressions);
                })
                .catch(error => logger.error('Error fetching impressions for poll:', poll._id, error));
            });
          })
          .catch(error => logger.error('Error fetching user polls:', error));
      })
      .catch(error => logger.error('Error fetching user profile:', error));
  }, [username]);

  useEffect(() => {
    if (loggedInUser && profileUserId && loggedInUser._id !== profileUserId) {
      logger.log('Checking follow status for profileUserId:', profileUserId, 'loggedInUser:', loggedInUser._id);
      checkFollowStatus(loggedInUser._id, profileUserId);
    }
  }, [loggedInUser, profileUserId]);

  const checkFollowStatus = (loggedInUserId, profileUserId) => {
    fetch(`/follow/status?followerId=${loggedInUserId}&followedId=${profileUserId}`)
      .then(response => response.json())
      .then(data => {
        logger.log('Follow status data:', data);
        setIsFollowing(data.isFollowing);
      })
      .catch(error => logger.error('Error checking follow status:', error));
  };

  const formatNumber = (num) => {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + 'M';
    }
    if (num >= 1000) {
      return (num / 1000).toFixed(1) + 'k';
    }
    return num;
  };

  const handleFollow = () => {
    if (!loggedInUser) {
      logger.error('User not logged in');
      return;
    }
  
    // Optimistically update the UI before making the API request
    setIsFollowing(true);
  
    fetch('/follow', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify({ followerId: loggedInUser._id, followedId: profileUserId, followerEmail: loggedInUser.email })
    })
      .then(response => response.json())
      .then(data => {
        logger.log('Followed user:', data);
        // No need to update state here, as it is already updated optimistically
      })
      .catch(error => {
        logger.error('Error following user:', error);
        // Revert the optimistic update in case of an error
        setIsFollowing(false);
      });
  };

  const handlePublicProfileShareClick = async () => {
    const profileUrl = `${window.location.origin}/profile/${username}`;

    try {
      if (navigator.share) {
        await navigator.share({
          title: 'Profile',
          text: `Check out ${username}'s profile!`,
          url: profileUrl,
        });
      } else {
        await navigator.clipboard.writeText(profileUrl);
        alert('Profile URL copied to clipboard!');
      }
    } catch (error) {
      logger.error('Error sharing profile URL:', error);
      alert('Failed to share profile URL');
    }
  };

  const handleUnfollow = () => {
    if (!loggedInUser) {
      logger.error('User not logged in');
      return;
    }
  
    // Optimistically update the UI before making the API request
    setIsFollowing(false);
  
    fetch('/unfollow', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify({ followerId: loggedInUser._id, followedId: profileUserId })
    })
      .then(response => response.json())
      .then(data => {
        logger.log('Unfollowed user:', data);
        // No need to update state here, as it is already updated optimistically
      })
      .catch(error => {
        logger.error('Error unfollowing user:', error);
        // Revert the optimistic update in case of an error
        setIsFollowing(true);
      });
  };
  

  if (!firstName) {
    return <div>Loading...</div>;
  }

  return (
    <div className="Profile">
      <div className="Profile-card">
        <FontAwesomeIcon
          icon={isFollowing ? faBell : faBellRegular}
          className="Profile-follow-icon"
          onClick={isFollowing ? handleUnfollow : handleFollow}
        />
        <FontAwesomeIcon icon={faShareSquare} className="Public-profile-share-icon" onClick={handlePublicProfileShareClick} />
        <div className="Profile-header">
          <div className="Profile-image-container">
            <ProfileIcon firstName={firstName} size={80} profileImageUrl={profileImageUrl} />
          </div>
          <div className="Profile-details">
            <h1 className="Profile-username">{username}</h1>
            <div className="Profile-stats">
              <div className="Profile-stat">
                <b>{formatNumber(polls.length)}</b>
                <span>Polls</span>
              </div>
              <div className="Profile-stat">
                <b>{formatNumber(totalVotes)}</b>
                <span>Votes</span>
              </div>
              <div className="Profile-stat">
                <b>{formatNumber(impressions)}</b>
                <span>Views</span>
              </div>
            </div>
          </div>
        </div>
        <p className="Profile-bio">
          {bio}
        </p>
      </div>

      <div className="Profile-filter-tabs">
  <button
    className={`Profile-filter-tab-button ${filterTab === 'active' ? 'active active-tab' : ''}`}
    onClick={() => handleFilterTabChange('active')}
  >
    Active
  </button>
  <button
    className={`Profile-filter-tab-button ${filterTab === 'scheduled' ? 'active scheduled-tab' : ''}`}
    onClick={() => handleFilterTabChange('scheduled')}
  >
    Scheduled
  </button>
</div>


<div className="Profile-polls">
  {filteredPolls.length > 0 ? (
    filteredPolls
      .filter(poll => poll.visibility !== 'Private') // Ensure only public polls are shown
      .map(poll => (
        <div key={poll._id} className="Poll-item">
          <Poll
            pollId={poll._id}
            question={poll.question}
            labels={poll.labels}
            logo={poll.logo || "/logo-poll.png"}
            logoFill={poll.logoFill}
            votes={poll.votes}
            hash={poll.hash}
            creatorUsername={username}
            isLoggedIn={!!loggedInUser}
            userId={profileUserId}
            voteAuthenticationMethod={poll.voteAuthenticationMethod}
            confidenceLabels={poll.confidenceLabels}
            imageAction={poll.imageAction}
            roundedCorners={poll.roundedCorners}
            startDate={poll.startDate}
            endDate={poll.endDate}
            scheduledPoll={poll.scheduledPoll}
            totalVotes={poll.totalVotes}
            isTemporary={poll.isTemporary}

          />
        </div>
      ))
  ) : filterTab === 'active' ? (
    <p>This user has no active polls.</p>
  ) : filterTab === 'scheduled' ? (
    <p>This user has no scheduled polls.</p>
  ) : (
    <p>No polls found in this category.</p>
  )}
</div>


    </div>
  );
};

export default PublicProfile;
