import React, { useState, useEffect,  useRef } from 'react';
import './Home.css';
import { ThumbsUp, BarChart2, Users } from 'lucide-react';
import ScrollingPolls from './ScrollingPolls';
import ScrollingPollsReverse from './ScrollingPollsReverse';
import SignUpModal from './SignUpPage';
import { useNavigate, Link } from 'react-router-dom';
import HomeImage from '../assets/HomeGraphic.png';
import bgImage from '../assets/bg.png';
import MobileBg from '../assets/mobile-bg.jpg';
import Image1 from '../assets/1.png';
import Image2 from '../assets/2.png';
import Image3 from '../assets/3.png';
import logger from './logger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter, faInstagram, faYoutube, faFacebook, faLinkedin, faPinterest } from '@fortawesome/free-brands-svg-icons'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'; // Import the arrow icon


import CanvaLogo from '../assets/canva.png';
import GoogleSheetsLogo from '../assets/google-sheets.png';
import OfficeLogo from '../assets/Office.png';
import Classroom from '../assets/classroom.png';
import Lucide from '../assets/Lucide.png';
import Instagram from '../assets/instagram.png';
import X from '../assets/X.png';
import Facebook from '../assets/facebook.png';
import Youtube from '../assets/youtube.png';
import TikTok from '../assets/TikTok.png';
import LinkedIn from '../assets/LinkedIN.png';
import Snapchat from '../assets/SnapChat.png';
import CreationWebm from '../assets/Creation.webm'; // Desktop video
import CreationMobileWebm from '../assets/CreationMobile.png'; // Mobile video

import ReportAnimation from '../assets/ReportAnimation.webm'; // Desktop video
import ReportAnimationMobile from '../assets/Report_Mobile.webm'; // Mobile video


const Home = () => {
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState(bgImage);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);
  const [isImage1Loaded, setIsImage1Loaded] = useState(false);
  const [isImage2Loaded, setIsImage2Loaded] = useState(false);
  const [isImage3Loaded, setIsImage3Loaded] = useState(false);
  const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);
  const [isColorElementsVisible, setIsColorElementsVisible] = useState(true);
  const [isStatsBackgroundVisible, setIsStatsBackgroundVisible] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State for toggling dropdown image
  const dropdownRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);


  const images = [
    require('../assets/MainMobile.png'),
    require('../assets/MainMobile2.png'),
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage === 0 ? 1 : 0));
    }, 2500); // Change image every 3 seconds

    return () => clearInterval(interval);
  }, []);

  



useEffect(() => {
  const handleResize = () => {
    const isMobileDevice = window.innerWidth <= 768;
    setIsMobile(isMobileDevice);
    console.log(`isMobile: ${isMobileDevice}`); // Debugging
  };

  handleResize();

  window.addEventListener('resize', handleResize);

  return () => window.removeEventListener('resize', handleResize);
}, []);




  const navigate = useNavigate();

 // Detect screen size on mount and update on resize
 useEffect(() => {
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768); // Set threshold for mobile (768px)
  };

  // Initialize the state based on the current window size
  handleResize();

  // Add event listener to handle resize
  window.addEventListener('resize', handleResize);

  // Clean up event listener on component unmount
  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);

  useEffect(() => {
    const backgroundVideo = document.getElementById('backgroundVideo');
    const backgroundStatsVideo = document.getElementById('backgroundStatsVideo');
    const colorWheelVideo = document.getElementById('colorWheelVideo');
    const textVideo = document.getElementById('textVideo');
    const colorBarVideo = document.getElementById('colorBarVideo');
    const redBarVideo = document.getElementById('redBarVideo');
    const greenBarVideo = document.getElementById('greenBarVideo');
    const blueBarVideo = document.getElementById('blueBarVideo');
    const graphVideo = document.getElementById('graphVideo');
  
    let originalVideosReady = 0;
    let newVideosReady = 0;
  
    // Play the original videos when they are ready
    const checkAndPlayOriginalVideos = () => {
      originalVideosReady++;
      if (originalVideosReady === 4 && isColorElementsVisible) {
        backgroundVideo.currentTime = 0;
        colorWheelVideo.currentTime = 0;
        colorBarVideo.currentTime = 0;
        textVideo.currentTime = 0;
  
        backgroundVideo.play();
        colorWheelVideo.play();
        colorBarVideo.play();
        textVideo.play();
      }
    };
  
    // Play the new background and new videos when they are ready and visible
    const playNewBackgroundAndVideos = () => {
      if (!isColorElementsVisible) {
        backgroundStatsVideo.currentTime = 0;
        backgroundStatsVideo.play();
        redBarVideo.currentTime = 0;
        greenBarVideo.currentTime = 0;
        blueBarVideo.currentTime = 0;
        graphVideo.currentTime = 0;
  
        redBarVideo.play();
        greenBarVideo.play();
        blueBarVideo.play();
        graphVideo.play();
      }
    };
  
    // Ensure the new videos start when they are visible
    const checkAndPlayNewVideos = () => {
      newVideosReady++;
      if (newVideosReady === 4 && !isColorElementsVisible) {
        playNewBackgroundAndVideos();
      }
    };
  
    // Add event listeners for the original set of videos
    backgroundVideo.addEventListener('canplay', checkAndPlayOriginalVideos);
    colorWheelVideo.addEventListener('canplay', checkAndPlayOriginalVideos);
    colorBarVideo.addEventListener('canplay', checkAndPlayOriginalVideos);
    textVideo.addEventListener('canplay', checkAndPlayOriginalVideos);
  
    // Add event listeners for the new set of videos
    redBarVideo.addEventListener('canplay', checkAndPlayNewVideos);
    greenBarVideo.addEventListener('canplay', checkAndPlayNewVideos);
    blueBarVideo.addEventListener('canplay', checkAndPlayNewVideos);
    graphVideo.addEventListener('canplay', checkAndPlayNewVideos);
  
    // Add event listener for the new background video
    backgroundStatsVideo.addEventListener('canplay', checkAndPlayNewVideos);
  
    // Play new videos directly if the dropdown is clicked and they are ready
    if (!isColorElementsVisible) {
      playNewBackgroundAndVideos();
    }
  
    // Cleanup event listeners on unmount
    return () => {
      backgroundVideo.removeEventListener('canplay', checkAndPlayOriginalVideos);
      colorWheelVideo.removeEventListener('canplay', checkAndPlayOriginalVideos);
      colorBarVideo.removeEventListener('canplay', checkAndPlayOriginalVideos);
      textVideo.removeEventListener('canplay', checkAndPlayOriginalVideos);
  
      redBarVideo.removeEventListener('canplay', checkAndPlayNewVideos);
      greenBarVideo.removeEventListener('canplay', checkAndPlayNewVideos);
      blueBarVideo.removeEventListener('canplay', checkAndPlayNewVideos);
      graphVideo.removeEventListener('canplay', checkAndPlayNewVideos);
      backgroundStatsVideo.removeEventListener('canplay', checkAndPlayNewVideos);
    };
  }, [isColorElementsVisible]);
  
  
  
  

  

  


  useEffect(() => {
    const handleMouseMove = (e) => {
      // Get the position of the mouse relative to the center of the screen
      const { clientX, clientY } = e;
      const centerX = window.innerWidth / 2;
      const centerY = window.innerHeight / 2;
  
      const xOffset = (clientX - centerX) /-100; // Adjust this divisor to control the translation intensity
      const yOffset = (clientY - centerY) / -100;
  
      setMouseX(xOffset);
      setMouseY(yOffset);
    };
  
    window.addEventListener('mousemove', handleMouseMove);
  
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);
  

  useEffect(() => {
    const updateLayout = () => {
      setIsDesktop(window.innerWidth >= 768);
      setBackgroundImage(window.innerWidth < 768 ? MobileBg : bgImage);
    };

    updateLayout();

    window.addEventListener('resize', updateLayout);

    return () => window.removeEventListener('resize', updateLayout);
  }, []);

  useEffect(() => {
    const hoverables = document.querySelectorAll('.hoverable');
    const descriptionBox = document.getElementById('description-box');
    const descriptionText = document.getElementById('description-text');
    // Remove descriptionButton reference
    // const descriptionButton = document.getElementById('description-button');
  
    let fadeTimeout;
  
    const startFadeTimeout = () => {
      clearTimeout(fadeTimeout);
      fadeTimeout = setTimeout(() => {
        descriptionBox.style.opacity = '0';
        setTimeout(() => {
          descriptionBox.style.display = 'none';
        }, 200);
      }, 500);
    };
  
    const cancelFadeTimeout = () => {
      clearTimeout(fadeTimeout);
    };
  
    hoverables.forEach((element) => {
      element.addEventListener('mouseenter', () => {
        cancelFadeTimeout();
        const description = element.getAttribute('data-description');
        // Removed button text logic
        // const buttonText = element.getAttribute('data-button-text');
        const transformValue = element.getAttribute('data-transform') || 'translate(-50%, -50%)';
  
        descriptionText.textContent = description;
        // Remove setting button text
        // descriptionButton.textContent = buttonText;
        descriptionBox.style.display = 'block';
        descriptionBox.style.opacity = '1';
  
        const rect = element.getBoundingClientRect();
        const parentRect = descriptionBox.parentNode.getBoundingClientRect();
  
        const left = rect.left - parentRect.left + rect.width / 2;
        const top = rect.top - parentRect.top + rect.height / 2;
  
        descriptionBox.style.left = `${left}px`;
        descriptionBox.style.top = `${top}px`;
        descriptionBox.style.transform = transformValue;
      });
  
      element.addEventListener('mouseleave', () => {
        startFadeTimeout();
      });
    });
  
    descriptionBox.addEventListener('mouseenter', cancelFadeTimeout);
    descriptionBox.addEventListener('mouseleave', startFadeTimeout);
  }, []);



  useEffect(() => {
    const dropdown = dropdownRef.current;
  
    const toggleElements = () => {
      setIsColorElementsVisible((prevState) => !prevState);
      setIsStatsBackgroundVisible((prevState) => !prevState);
      setIsDropdownOpen((prevState) => !prevState);
    };
  
    if (dropdown) {
      dropdown.addEventListener('click', toggleElements);
    }
  
    return () => {
      if (dropdown) {
        dropdown.removeEventListener('click', toggleElements);
      }
    };
  }, [dropdownRef, isColorElementsVisible]);
  
  
  
  
  
  
  
  
  
  

  const handleMouseEnter = (setIsImageLoaded) => {
    setIsImageLoaded(true); // Start animation on hover
  };

  const handleMouseLeave = (setIsImageLoaded) => {
    setIsImageLoaded(false); // Reset to static image on unhover
  };

  const openSignUpModal = () => {
    setIsSignUpModalOpen(true);
  };

  const closeSignUpModal = () => {
    setIsSignUpModalOpen(false);
  };

  const goToBuild = () => {
    navigate('/create-poll');
  };

  const goToFeed = () => {
    navigate('/feed');
  };

  return (
    
    <div className="opinari-home" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <main className="opinari-main">
        <div className="opinari-hero">
          <div className="opinari-hero-content">
            <h1 className="opinari-hero-title">
            Instantly Collect Opinions
            </h1>
            <p className="opinari-hero-description">
            OPINARI is the easiest way to track opinions from virtually anywhere. Attach one to your social, website or use the included QR Code for unlimited engagement and real-time feedback.        </p>
            <div className="opinari-hero-buttons">

              <button 
                className="opinari-btn opinari-btn-secondary"
                onClick={goToBuild}
              >
                <span>Create Free</span>
              </button>
              <button 
                className="opinari-btn opinari-btn-primary"
                onClick={goToFeed}
              >
                <span>Browse</span>
              </button>
            </div>
          </div>

          <div className="opinari-hero-image">
  {!isMobile ? (
    <div className="infographic-container" style={{ transform: `translate(${mouseX}px, ${mouseY}px)` }}>
      <div className="infographic">

        {/* Original Background Layer */}
        <div className="layer" id="background" style={{ display: isStatsBackgroundVisible ? 'none' : 'block' }}>
          <video id="backgroundVideo" loop muted playsInline style={{ width: '100%' }}>
            <source src={require('../assets/BackgroundAnimation.webm')} type="video/webm" />
            Your browser does not support the video tag.
          </video>
        </div>

        {/* Stats Background Layer */}
        <div className="layer" id="background-stats" style={{ display: isStatsBackgroundVisible ? 'block' : 'none' }}>
          <video id="backgroundStatsVideo" loop muted playsInline style={{ width: '100%' }}>
            <source src={require('../assets/BackgroundStats.webm')} type="video/webm" />
            Your browser does not support the video tag.
          </video>
        </div>

        {/* Report Icon */}
        <div className="layer hoverable" id="report" data-description="Poll Tools" data-button-text="Create Poll" data-transform="translate(-130%, -20%) skew(-10deg)">
          <img src={require('../assets/Report.png')} alt="Report" />
        </div>

        {/* Share Icon */}
        <div className="layer hoverable" id="share" data-description="Share Tools" data-button-text="Create Poll" data-transform="translate(20%, -20%) skew(-10deg)">
          <img src={require('../assets/Share.png')} alt="Share" />
        </div>

        {/* Logo Layer */}
        <div className="layer hoverable" id="logo" data-description="Your brand" data-button-text="Create Poll" data-transform="translate(130%, -20%) skew(-10deg)">
          <img src={require('../assets/Logo2.png')} alt="Logo" />
        </div>

        {/* Question Text */}
        <div className="layer hoverable" id="question" data-description="Your question" data-button-text="Create Poll" data-transform="translate(120%, -50%) skew(-10deg)">
          <img src={require('../assets/Question.png')} alt="Question" />
        </div>

        {/* Dynamic Text */}
        <div className="layer hoverable" id="dynamic-text" data-description="Current answer selection" data-button-text="Create Poll" data-transform="translate(60%, -50%) skew(-10deg)">
          <video id="textVideo" loop muted playsInline style={{ width: '100%', display: isDropdownOpen ? 'none' : 'block' }}>
            <source src={require('../assets/DynamicText.webm')} type="video/webm" />
            Your browser does not support the video tag.
          </video>
          <img src={require('../assets/StaticText.png')} alt="Static Text" style={{ width: '100%', display: isDropdownOpen ? 'block' : 'none' }} />
        </div>

        {/* Color Wheel */}
        <div className="layer hoverable" id="color-wheel" style={{ display: isColorElementsVisible ? 'block' : 'none' }} data-description="Fast & easy voting using our revolutionary color wheel" data-button-text="Create Poll" data-transform="translate(50%, -50%) skew(-10deg)">
          <video id="colorWheelVideo" loop muted playsInline style={{ width: '100%' }}>
            <source src={require('../assets/ColorWheelAnimation.webm')} type="video/webm" />
            Your browser does not support the video tag.
          </video>
        </div>

        {/* Color Bar */}
        <div className="layer hoverable" id="color-bar" style={{ display: isColorElementsVisible ? 'block' : 'none' }} data-description="Live stats bar" data-button-text="Create Poll" data-transform="translate(110%, -50%) skew(-10deg)">
          <video id="colorBarVideo" loop muted playsInline style={{ width: '100%' }}>
            <source src={require('../assets/ColorBar.webm')} type="video/webm" />
            Your browser does not support the video tag.
          </video>
        </div>

        {/* Red Bar */}
        <div className="layer hoverable" id="red-bar" style={{ display: !isColorElementsVisible ? 'block' : 'none' }} data-description="Detailed answer breakdowns" data-button-text="Create Poll" data-transform="translate(60%, -50%) skew(-10deg)">
          <video id="redBarVideo" loop muted playsInline style={{ width: '100%' }}>
            <source src={require('../assets/RedBar.webm')} type="video/webm" />
            Your browser does not support the video tag.
          </video>
        </div>

        {/* Green Bar */}
        <div className="layer hoverable" id="green-bar" style={{ display: !isColorElementsVisible ? 'block' : 'none' }} data-description="Detailed answer breakdowns" data-button-text="Create Poll" data-transform="translate(60%, -50%) skew(-10deg)">
          <video id="greenBarVideo" loop muted playsInline style={{ width: '100%' }}>
            <source src={require('../assets/GreenBar.webm')} type="video/webm" />
            Your browser does not support the video tag.
          </video>
        </div>

        {/* Blue Bar */}
        <div className="layer hoverable" id="blue-bar" style={{ display: !isColorElementsVisible ? 'block' : 'none' }} data-description="Detailed answer breakdowns" data-button-text="Create Poll" data-transform="translate(60%, -50%) skew(-10deg)">
          <video id="blueBarVideo" loop muted playsInline style={{ width: '100%' }}>
            <source src={require('../assets/BlueBar.webm')} type="video/webm" />
            Your browser does not support the video tag.
          </video>
        </div>

        {/* Graph */}
        <div className="layer hoverable" id="graph" style={{ display: !isColorElementsVisible ? 'block' : 'none' }} data-description="Opinion over time graph" data-button-text="Create Poll" data-transform="translate(60%, -50%) skew(-10deg)">
          <video id="graphVideo" loop muted playsInline style={{ width: '100%' }}>
            <source src={require('../assets/Graph.webm')} type="video/webm" />
            Your browser does not support the video tag.
          </video>
        </div>

        {/* Dropdown */}
        <div className="layer" id="dropdown" ref={dropdownRef} data-description="This is the dropdown." data-transform="translate(60%, -50%) skew(-10deg)">
          <img src={isDropdownOpen ? require('../assets/DropdownOpen.png') : require('../assets/Dropdown.png')} alt="Dropdown" />
        </div>

      </div>

      {/* Button */}
  <div className="layer" id="arrow-button" data-transform="translate(40%, -50%) skew(-10deg)">
    <button className="arrow-button" onClick={goToBuild}>
      <FontAwesomeIcon icon={faArrowRight} /> {/* Corrected icon rendering */}
    </button>
  </div>
  
    </div>
) : (
  <div className="mobile-animation-wrapper">
      {images.map((src, index) => (
        <img
          key={index}
          src={src}
          alt={`Mobile Animation ${index + 1}`}
          className={`mobile-animation-image ${currentImage === index ? 'show' : ''}`}
        />
      ))}
    </div>
)}

  

  {/* Description Box */}
  <div id="description-box">
    <p id="description-text"></p>
  </div>
</div>


<ScrollingPolls />
          

<div>
  {/* New Section */}
  <div className="report-section">
    <div className="report-content">
      {isMobile ? (
        <>
          <div className="report-text">
            <h2>Running in Seconds</h2>
          </div>
          <div className="report-image-wrapper">
            <img
              id="blueBarImage1"
              src={require('../assets/CreationMobile.png')}
              alt="Running in Seconds"
              style={{ maxWidth: '500px' }}
            />
          </div>
          <div className="report-text">
            <p>
            Quickly create 
engaging questions with 
relevant answers on 
any subject using
our intuitive GenAI tool. 
            </p>
            <button
              className="opinari-btn opinari-btn-primary"
              onClick={goToBuild}
            >
              <span>Create</span>
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="report-image-wrapper">
            <video
              id="blueBarVideo1"
              autoPlay
              loop
              muted
              playsInline
              src={CreationWebm}
            >
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="report-text">
            <h2>Running in Seconds</h2>
            <p>
            Quickly create 
engaging questions with 
relevant answers on 
any subject using
our intuitive GenAI tool. 
            </p>
            <button
              className="opinari-btn opinari-btn-primary"
              onClick={goToBuild}
            >
              <span>Create</span>
            </button>
          </div>
        </>
      )}
    </div>
  </div>

  {/* New Section */}
  <div className="report-section">
    <div className="reverse-content">
      {isMobile ? (
        <>
          <div className="report-text">
            <h2>Live Stats</h2>
          </div>
          <div className="report-image-wrapper">
            <img
              id="blueBarImage2"
              src={require('../assets/LiveAnimationMobile.jpg')}
              alt="Live Stats"
              style={{ maxWidth: '500px' }}
            />
          </div>
          <div className="report-text">
            <p>
            Monitor real-time responses to spot 
emerging trends and confidently 
act quickly with your audience.
            </p>
            <button
              className="opinari-btn opinari-btn-primary"
              onClick={goToFeed}
            >
              <span>Browse</span>
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="report-image-wrapper">
            <video
              id="blueBarVideo2"
              autoPlay
              loop
              muted
              playsInline
              style={{ maxWidth: '500px' }}
              src={require('../assets/LiveAnimation.webm')}
            >
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="report-text">
            <h2>Live Stats</h2>
            <p>
            Monitor real-time responses to spot 
emerging trends and confidently 
act quickly with your audience.
            </p>
            <button
              className="opinari-btn opinari-btn-primary"
              onClick={goToFeed}
            >
              <span>Browse</span>
            </button>
          </div>
        </>
      )}
    </div>
  </div>

  {/* New Section */}
  <div className="report-section">
    <div className="report-content">
      {isMobile ? (
        <>
          <div className="report-text">
            <h2>Detailed Reports</h2>
          </div>
          <div className="report-image-wrapper">
            <img
              id="blueBarImage3"
              src={require('../assets/ReportAnimationMobile.png')}
              alt="Detailed Reports"
              style={{ maxWidth: '500px' }}
            />
          </div>
          <div className="report-text">
            <p>
            Our embeddable reports integrate with 
any presentation app, offering detailed 
analysis of collected opinions by location, 
gender, confidence levels, and 
changes over time.
            </p>
            <button
              className="opinari-btn opinari-btn-primary"
              onClick={() => window.location.href = '/report-sample.pdf'}
            >
              <span>View</span>
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="report-image-wrapper">
            <video
              id="blueBarVideo3"
              autoPlay
              loop
              muted
              playsInline
              style={{ maxWidth: '500px' }}
              src={require('../assets/ReportAnimation.webm')}
            >
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="report-text">
            <h2>Detailed Reports</h2>
            <p>
            Our embeddable reports integrate with 
any presentation app, offering detailed 
analysis of collected opinions by location, 
gender, confidence levels, and 
changes over time.
            </p>
            <button
              className="opinari-btn opinari-btn-primary"
              onClick={() => window.location.href = '/report-sample.pdf'}
            >
              <span>View</span>
            </button>
          </div>
        </>
      )}
    </div>
  </div>

  {/* New Section */}
  <div className="report-section">
    <div className="reverse-content">
      {isMobile ? (
        <>
          <div className="report-text">
            <h2>Put Them Everywhere</h2>
          </div>
          <div className="report-image-wrapper">
            <img
              id="blueBarImage4"
              src={require('../assets/ShareAnimationMobile.jpg')}
              alt="Put Them Everywhere"
              style={{ maxWidth: '500px' }}
            />
          </div>
          <div className="report-text">
            <p>
            Capture audience opinions effortlessly 
with our powerful sharing tools—
easily distribute your link 
via social media, email, or QR codes.
            </p>
            <button
              className="opinari-btn opinari-btn-primary"
              onClick={goToBuild}
            >
              <span>Explore</span>
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="report-image-wrapper">
            <video
              id="blueBarVideo4"
              autoPlay
              loop
              muted
              playsInline
              style={{ maxWidth: '500px' }}
              src={require('../assets/ShareAnimation.webm')}
            >
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="report-text">
            <h2>Put Them Everywhere</h2>
            <p>
            Capture audience opinions effortlessly 
with our powerful sharing tools—
easily distribute your link 
via social media, email, or QR codes.
            </p>
            <button
              className="opinari-btn opinari-btn-primary"
              onClick={goToBuild}
            >
              <span>Explore</span>
            </button>
          </div>
        </>
      )}
    </div>
  </div>
</div>
</div>





        {/* Integration Section */}
        <div className="integration-section">
          <h2 className="integration-title">Seamlessly Integrates With</h2>
          <div className="integration-logos">
            <img src={CanvaLogo} alt="Canva" />
            <img src={GoogleSheetsLogo} alt="VistaCreate" />
            <img src={OfficeLogo} alt="Microsoft Office" />
            <img src={Classroom} alt="google classroom" />
            <img src={Lucide} alt="Lucide" />

          </div>
        </div>

       
        

        

       
  
        {/* <div className="opinari-stats">
          <div 
            className="opinari-stat-card"
            onMouseEnter={() => handleMouseEnter(setIsImage1Loaded)}
            onMouseLeave={() => handleMouseLeave(setIsImage1Loaded)}
          >
            <h3 className="opinari-card-title">Detailed Reports</h3>
            <p className="opinari-card-description">Get in-depth insights and data visualizations to understand trends and make better decisions.</p>
            <img
              src={isDesktop && isImage1Loaded ? require('../animations/report-animation.avif') : Image1}
              alt="Opinari Graphic"
              className="opinari-dashboard-image"
            />
            <button onClick={() => window.open("/report-sample.pdf", "_blank")} className="opinari-card-button">View Sample </button>
          </div>
          <div 
            className="opinari-stat-card"
            onMouseEnter={() => handleMouseEnter(setIsImage2Loaded)}
            onMouseLeave={() => handleMouseLeave(setIsImage2Loaded)}
          >
            <h3 className="opinari-card-title">Live Stats</h3>
            <p className="opinari-card-description">
              Watch votes arrive in real-time, providing instant insights into public opinion and emerging trends.
            </p>

            <img
              src={isDesktop && isImage2Loaded ? require('../animations/live-animation.avif') : Image2}
              alt="Opinari Graphic"
              className="opinari-dashboard-image"
            />
            <button onClick={goToBuild} className="opinari-card-button">Build Now</button>
            </div>
          <div 
            className="opinari-stat-card"
            onMouseEnter={() => handleMouseEnter(setIsImage3Loaded)}
            onMouseLeave={() => handleMouseLeave(setIsImage3Loaded)}
          >
            <h3 className="opinari-card-title">Power Share</h3>
            <p className="opinari-card-description">Maximize your polling reach with our powerful social, email, QR, and embed tools.</p>
            <img
              src={isDesktop && isImage3Loaded ? require('../animations/share-animation.avif') : Image3}
              alt="Opinari Graphic"
              className="opinari-dashboard-image"
            />
            <button onClick={goToBuild} className="opinari-card-button">Start Free</button>
          </div>
        </div> */}
      </main>
  
      <footer className="opinari-footer" >
  <div className="opinari-footer-content">
    <div className="opinari-footer-social">
      <a href="https://x.com/Opinari.io" target="_blank" rel="noopener noreferrer" aria-label="X">
        <FontAwesomeIcon icon={faXTwitter} />
      </a>
      <a href="https://www.instagram.com/opinari.io/" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
        <FontAwesomeIcon icon={faInstagram} />
      </a>
      <a href="https://www.youtube.com/@Opinaripolls" target="_blank" rel="noopener noreferrer" aria-label="YouTube">
        <FontAwesomeIcon icon={faYoutube} />
      </a>
      <a href="https://www.facebook.com/people/Opinari-Polls/61564592017081/" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
        <FontAwesomeIcon icon={faFacebook} />
      </a>
      <a href="https://www.linkedin.com/company/opinari-polling/" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
        <FontAwesomeIcon icon={faLinkedin} />
      </a>
      <a href="https://www.pinterest.com/opinaripolls/" target="_blank" rel="noopener noreferrer" aria-label="Pinterest">
        <FontAwesomeIcon icon={faPinterest} />
      </a>
    </div>
    <div className="opinari-footer-info">
      <p>Opinari Technologies Inc.</p>
      <p>
        <Link to="/privacy">Privacy Policy</Link> |{' '}
        <Link to="/terms">Terms and Services</Link>
      </p>
      <p>All rights reserved. Copyright ©2024</p>
      <p>Patent Pending</p>
    </div>
  </div>
</footer>
      {isSignUpModalOpen && <SignUpModal onClose={closeSignUpModal} />}
    </div>
  );
};

export default Home;
