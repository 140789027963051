import React from 'react';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer
} from 'recharts';
import './CustomTooltip.css';
import logger from './logger';

const PollAreaChart = ({ votes, labels }) => {
  const classifyColor = (hue) => {
    if (hue >= 60 && hue <= 180) return labels[0]; // Agree
    if (hue > 300 || hue <= 60) return labels[2];  // Disagree
    return labels[1]; // Neutral
  };

  // Determine the step based on the number of votes
  let step = 1;
  const totalVotes = votes.length;

  if (totalVotes > 1000000) {
    step = 1000;
  } else if (totalVotes > 100000) {
    step = 100;
  } else if (totalVotes > 10000) {
    step = 10;
  } else if (totalVotes > 1000) {
    step = 10;
  } else if (totalVotes > 400) {
    step = 4;
  } else if (totalVotes > 100) {
    step = 2;
  }

  const data = votes
    .filter((_, index) => index % step === 0)
    .map((vote, index, filteredVotes) => {
      if (!vote || !vote.createdAt) return null;
      const totalDisplayedVotes = filteredVotes.length;
      const agreeVotes = filteredVotes.slice(0, index + 1).filter(v => classifyColor(v.choice) === labels[0]).length;
      const disagreeVotes = filteredVotes.slice(0, index + 1).filter(v => classifyColor(v.choice) === labels[2]).length;
      const neutralVotes = filteredVotes.slice(0, index + 1).filter(v => classifyColor(v.choice) === labels[1]).length;

      return {
        syntheticTimestamp: (index / (totalDisplayedVotes - 1)) * 100, // Spread synthetic timestamps evenly across the chart
        agreePercentage: (agreeVotes / (index + 1)) * 100,
        disagreePercentage: (disagreeVotes / (index + 1)) * 100,
        neutralPercentage: (neutralVotes / (index + 1)) * 100,
      };
    })
    .filter(item => item !== null);

  // Ensure the graph fills correctly when there is only one vote
  if (data.length === 1) {
    data.push({
      syntheticTimestamp: 100,
      agreePercentage: data[0].agreePercentage,
      disagreePercentage: data[0].disagreePercentage,
      neutralPercentage: data[0].neutralPercentage,
    });
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const actualIndex = Math.round(label * (totalVotes / step - 1) / 100);
      if (votes[actualIndex]) {
        const originalTimestamp = votes[actualIndex].createdAt;
        const formattedTimestamp = new Date(originalTimestamp).toLocaleString('en-US', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: true
        });

        return (
          <div className="chart-tooltip-wrapper">
            <div className="chart-tooltip">
              <p className="chart-tooltip-timestamp">{`Time: ${formattedTimestamp}`}</p>
              <div className="chart-tooltip-stats">
                <p className="chart-tooltip-opinion">{`${labels[0]}: ${Math.round(payload[0].value)}%`}</p>
                <p className="chart-tooltip-opinion">{`${labels[1]}: ${Math.round(payload[1].value)}%`}</p>
                <p className="chart-tooltip-opinion">{`${labels[2]}: ${Math.round(payload[2].value)}%`}</p>
              </div>
            </div>
          </div>
        );
      }
    }
    return null;
  };

  return (
    <div style={{ marginTop: '15px' }}>
      <ResponsiveContainer width="95%" height={200} className="percent-area-chart">
        <AreaChart data={data} margin={{ top: 10, right: 10, left: 0, bottom: 20 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="syntheticTimestamp"
            name="Time"
            domain={[0, 100]}
            tickFormatter={(tick) => {
              const actualIndex = Math.round(tick * (totalVotes / step - 1) / 100);
              return votes[actualIndex] ? new Date(votes[actualIndex].createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : '';
            }}
            type="number"
            style={{ fontSize: '12px' }}
            tick={{ dy: 10, fill: '#999' }}
          />
          <YAxis
            domain={[0, 100]}
            tickFormatter={(tick) => `${Math.round(tick)}%`}
            style={{ fontSize: '12px' }}
            tick={{ fill: '#808080' }}
            tickCount={6}
          />
          <Tooltip content={<CustomTooltip />} />
          <Area type="monotone" dataKey="agreePercentage" stackId="1" stroke="#a9ff8e" fill="#00ff0d" name={labels[0]} fillOpacity={0.7} />
          <Area type="monotone" dataKey="neutralPercentage" stackId="1" stroke="#afc0ff" fill="#0004ff" name={labels[1]} fillOpacity={0.7} />
          <Area type="monotone" dataKey="disagreePercentage" stackId="1" stroke="#ffb8b8" fill="#ff0000" name={labels[2]} fillOpacity={0.7} />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PollAreaChart;
