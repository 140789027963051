import React, { useState, useEffect, useRef } from 'react';
import Poll from './Poll';
import AdCard from './AdCard';
import '../App.css';
import io from 'socket.io-client';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import CreatePollModal from './CreatePollModal';
import ProfileChicklet from './ProfileChicklet';
import logger from './logger';
import FilterTabs from './FilterTabs';
import './FilterTabs.css';


const Feed = ({ isLoggedIn, userId, username, isProfileView }) => {
  const [polls, setPolls] = useState([]);
  const [visiblePolls, setVisiblePolls] = useState({});
  const [showCreatePoll, setShowCreatePoll] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategories, setSelectedCategories] = useState(['All']);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [searchType, setSearchType] = useState('question');
  const [showCategories, setShowCategories] = useState(false);
  const observer = useRef(null);
  const debounceTimeout = useRef(null);
  const [profiles, setProfiles] = useState([]);
  const sortOptions = ["Trending", "Most Votes", "Latest"]; // "Trending" is now first
  const filterOptions = ["Trending", "Most Votes", "Latest"]; // "Trending" is now first
  const [sortOption, setSortOption] = useState(0); // Default to "Trending"
const [activeFilterIndex, setActiveFilterIndex] = useState(0); // Default to "Trending"
  
  const categories = [
    'Technology', 'Health', 'Education', 'Entertainment', 'Politics',
    'Sports', 'Lifestyle', 'Environment', 'Fashion', 'Services',
    'Products', 'Travel', 'Food', 'Business'
  ];


  const handleFilterChange = (selectedOption) => {
    const index = filterOptions.indexOf(selectedOption);
    if (index >= 0) {
      setActiveFilterIndex(index);
      setSortOption(index);
      setPolls([]);
      setPage(0);
      fetchPolls(0, selectedOption);
    }
  };


  useEffect(() => {
    setSearchQuery(''); // Clear the search bar when searchType changes
  }, [searchType]);

  const handleSearchTypeChange = (type) => {
    setSearchType(type);
    setSearchQuery(''); // Clear the search query when switching types
  };
  

  const fetchPolls = (page = 0, filterText) => {
  setIsLoading(true);
  const limit = 5;
  const skip = page * limit;
  const categoryFilter = selectedCategories.includes('All') ? '' : selectedCategories.join(',');
  const queryType = searchType === 'username' ? 'username' : 'question';
  const query = searchQuery ? `query=${searchQuery}&type=${queryType}&` : '';
  const categoryQuery = categoryFilter && searchType !== 'username' ? `category=${categoryFilter}&` : '';
  const includePrivate = isProfileView ? `includePrivate=true&` : '';

  let sortQuery = '';
  switch (filterText) {
    case 'Most Votes':
      sortQuery = 'sort=votesDesc';
      break;
    case 'Latest':
      sortQuery = 'sort=createdAtDesc';
      break;
    case 'Trending':
      sortQuery = 'sort=trending';
      break;
    default:
      sortQuery = 'sort=votesDesc';
  }

  const url = `${process.env.REACT_APP_API_URL}/polls?${query}${categoryQuery}${includePrivate}limit=${limit}&skip=${skip}&${sortQuery}`;
  logger.log('Fetching polls from:', url);

  fetch(url)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      logger.log('Fetched polls data:', data);

      const currentDate = new Date();
      const processedPolls = data.polls.map(poll => {
        if (poll.scheduledPoll) {
          const isPollNotStarted = poll.startDate && new Date(poll.startDate) > currentDate;
          const isPollEnded = poll.endDate && new Date(poll.endDate) < currentDate;

          if (isPollEnded) {
            poll.pollActive = false;
          } else if (!isPollNotStarted) {
            poll.pollActive = true;
          }
        }
        return poll;
      });

      const activePolls = processedPolls.filter(poll => poll.pollActive);

      if (page === 0) {
        setPolls(activePolls);
      } else {
        setPolls(prevPolls => [...prevPolls, ...activePolls]);
      }

      setHasMore(data.polls.length === limit);
      setIsLoading(false);
    })
    .catch(error => {
      logger.error('Error fetching polls:', error);
      setIsLoading(false);
    });
};

  const fetchProfiles = (page = 0, limit = 25, searchQuery = '') => {
    setIsLoading(true);
    const skip = page * limit;
    const query = searchQuery ? `query=${searchQuery}&` : '';
    const url = `${process.env.REACT_APP_API_URL}/users?${query}limit=${limit}&skip=${skip}`;
    logger.log('Fetching profiles from:', url);

    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        logger.log('Fetched profiles data:', data);
        setProfiles(prevProfiles => {
          const uniqueProfiles = data.users.filter(newProfile => 
            !prevProfiles.some(profile => profile._id === newProfile._id)
          );
          return [...prevProfiles, ...uniqueProfiles];
        });
        setHasMore(data.users.length === limit);
        setIsLoading(false);
      })
      .catch(error => {
        logger.error('Error fetching profiles:', error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (searchType === 'username') {
      fetchProfiles(0, 25, searchQuery);
    } else {
      fetchPolls(0, filterOptions[activeFilterIndex]); // Uses "Trending" by default
    }
  
    const socket = io();
    socket.on('connect', () => {
      logger.log('Connected to WebSocket server');
    });
  
    socket.on('vote', (data) => {
      setPolls((prevPolls) => {
        const updatedPolls = prevPolls.map(poll => {
          if (poll.id === data.pollId) {
            return { ...poll, votes: [...poll.votes, data.vote] };
          }
          return poll;
        });
        return updatedPolls;
      });
    });
  
    return () => socket.disconnect();
  }, [searchType, searchQuery, activeFilterIndex]);

  useEffect(() => {
    if (searchType === 'username') {
      fetchProfiles(0, 5, searchQuery);
    } else {
      setPolls([]);
      setPage(0);
      fetchPolls(0, sortOptions[sortOption]);
    }
  }, [selectedCategories, searchQuery, searchType, sortOption]);

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        if (debounceTimeout.current) {
          clearTimeout(debounceTimeout.current);
        }

        debounceTimeout.current = setTimeout(() => {
          entries.forEach(entry => {
            setVisiblePolls(prev => ({
              ...prev,
              [entry.target.dataset.id]: entry.isIntersecting,
            }));
          });
        }, 100);
      },
      {
        rootMargin: '0px 0px 200px 0px',
        threshold: 0.1,
      }
    );

    const currentObserver = observer.current;

    return () => currentObserver.disconnect();
  }, []);

  const openCreatePollModal = () => {
    if (!isLoggedIn) {
      alert('Please sign up or log in to create a poll.');
      return;
    }
    setShowCreatePoll(true);
    document.body.style.overflow = 'hidden';
  };

  const closeCreatePollModal = () => {
    setShowCreatePoll(false);
    document.body.style.overflow = 'auto';
  };

  const handlePollCreate = (newPoll) => {
    setPolls([newPoll, ...polls]);
  };

  const toggleCategory = (category) => {
    if (category === 'All') {
      setSelectedCategories(['All']);
      setPolls([]);
      setPage(0);
      fetchPolls(0);
    } else {
      setSelectedCategories(prev => {
        const newSelectedCategories = prev.includes(category)
          ? prev.filter(cat => cat !== category)
          : [...prev, category];

        if (newSelectedCategories.length === 0) {
          newSelectedCategories.push('All');
        }

        return newSelectedCategories.filter(cat => cat !== 'All');
      });
    }
  };

 

  const handleFilterIconClick = () => {
    setShowCategories(prevShowCategories => {
      const newShowCategories = !prevShowCategories;
      if (!newShowCategories) {
        setSelectedCategories(['All']);
        setPolls([]);
        setPage(0);
        fetchPolls(0);
      }
      return newShowCategories;
    });
  };

  const handleSearch = () => {
    if (searchType === 'username') {
      setProfiles([]);
      setPage(0);
      fetchProfiles(0, 5, searchQuery);
    } else {
      setPolls([]);
      setPage(0);
      fetchPolls(0, sortOptions[sortOption]);
    }
  };

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
      e.target.blur();
    }
  };

  const loadMoreItems = () => {
    if (searchType === 'username') {
      fetchProfiles(page + 1, 25, searchQuery);
      setPage(prevPage => prevPage + 1);
    } else {
      fetchPolls(page + 1, sortOptions[sortOption]);
      setPage(prevPage => prevPage + 1);
    }
  };

  const insertAdsIntoPolls = (polls) => {
    const items = [];
    const adFrequency = 3;

    polls.filter(poll => poll.pollActive).forEach((poll, index) => {
      items.push(
        <div key={poll._id} className="Poll-item poll-user-fade-in">
          <Poll
            pollId={poll._id}
            question={poll.question}
            labels={poll.labels}
            logo={poll.logo || "/logo-poll.png"}
            votes={poll.votes}
            hash={poll.hash}
            isLoggedIn={isLoggedIn}
            userId={userId}
            creatorUsername={poll.creator.username}
            username={username}
            isProfileView={isProfileView}
            isVisible={visiblePolls[poll._id]}
            voteAuthenticationMethod={poll.voteAuthenticationMethod}
            confidenceLabels={poll.confidenceLabels}
            imageAction={poll.imageAction}
            logoFill={poll.logoFill}
            roundedCorners={poll.roundedCorners}
            startDate={poll.startDate}
            endDate={poll.endDate}
            scheduledPoll={poll.scheduledPoll}
            pollActive={poll.pollActive}
            totalVotes={poll.totalVotes}
            isTemporary={poll.isTemporary}
          />
        </div>
      );
    });

    return items;
  };

  return (
    <div className="Feed">
      <div className="Feed-header">
      <FilterTabs options={filterOptions} onSelect={handleFilterChange} />
      </div>
      <div className="Search-container">
        <input 
          type="text" 
          placeholder={searchType === 'question' ? "Search Polls..." : "Search Users..."}
          value={searchQuery} 
          onChange={handleInputChange} 
          onKeyPress={handleKeyPress} 
          onBlur={handleSearch}
          className="Search-input"
        />
        <i className="fa-regular fa-sliders Filter-icon" onClick={handleFilterIconClick}></i>
      </div>
      <div className="Search-type-toggle">
  <label>
    <input 
      type="radio" 
      value="question" 
      checked={searchType === 'question'} 
      onChange={() => handleSearchTypeChange('question')} 
    />
    <span>Search Polls</span>
  </label>
  <label>
    <input 
      type="radio" 
      value="username" 
      checked={searchType === 'username'} 
      onChange={() => handleSearchTypeChange('username')} 
    />
    <span>Search Users</span>
  </label>
</div>
      {showCategories && searchType === 'question' && (
        <div className="Category-buttons">
          <button 
            className={`Category-button ${selectedCategories.includes('All') ? 'selected' : ''}`}
            onClick={() => toggleCategory('All')}
          >
            All
          </button>
          {categories.map((category, index) => (
            <button 
              key={index} 
              className={`Category-button ${selectedCategories.includes(category) ? 'selected' : ''}`}
              onClick={() => toggleCategory(category)}
            >
              {category}
            </button>
          ))}
        </div>
      )}
    
      {showCreatePoll && (
        <CreatePollModal
          isLoggedIn={isLoggedIn}
          userId={userId}
          username={username}
          onClose={closeCreatePollModal}
          onCreate={handlePollCreate}
        />
      )}
    
      {searchType === 'question' && (
        <InfiniteScroll
          dataLength={polls.length}
          next={loadMoreItems}
          hasMore={hasMore}
          loader={<h4>Loading...</h4>}
          endMessage={<p style={{ textAlign: 'center' }}><b>No more polls to display</b></p>}
          style={{ overflowX: 'hidden' }}
        >
          <div className="Polls-list" style={{ overflowX: 'hidden', width: '100%' }}>
            {insertAdsIntoPolls(polls)}
          </div>
        </InfiniteScroll>
  )}

{searchType === 'username' && (
  <InfiniteScroll
    dataLength={profiles.length}
    next={loadMoreItems}
    hasMore={hasMore}
    loader={<h4>Loading...</h4>}
    endMessage={<p style={{ textAlign: 'center' }}><b>No more profiles to display</b></p>}
    style={{ overflowX: 'hidden' }} // Specific styles for profiles
  >
    <div className="Profiles-list">
  {profiles.map(profile => (
    <div key={profile._id} className="Profile-item poll-user-fade-in"> {/* Apply the new class */}
      <ProfileChicklet
        username={profile.username}
        firstName={profile.firstName}
        profileImageUrl={profile.profileImageUrl}
        bio={profile.bio}
        pollCount={profile.pollCount}
        loggedInUsername={username}
      />
    </div>
  ))}
</div>
  </InfiniteScroll>
)}



    </div>
  );
};

export default Feed;